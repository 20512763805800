import { atom } from "jotai";
import dayjs from "dayjs";
import {
  AppSettingData,
  AppSettingInitial,
} from "../../../stores/appSettingStore";
import {
  MimosysDataAtom,
  MimosysDataInitial,
  ResultChartData,
} from "../../../stores/mimosysStore";
import { weekDataExtractor } from "../../../utils/mimosysDataExtracter";
import { allScoreAverage, sdCalc } from "../../../utils/mimosysDataStatistics";
import { Trend } from "../../../types";

/**
 * ホーム画面のロジックと状態
 */

export const recordDialogOpenAtom = atom<boolean>(false);
export const showSessionTimeoutAtom = atom(false);
export const homeSplashDisplayAtom = atom(true);

export const RecordCountAtom = atom({ count: 0 });

export const homeWeekDataAtom = atom<ResultChartData[]>((get) => {
  const now = dayjs();
  return weekDataExtractor(now, get(MimosysDataAtom), 7);
});

export const scoreAverageAtom = atom((get) => {
  return allScoreAverage(get(MimosysDataAtom)).map((value) => {
    return value;
  });
});

const scoreSD = atom((get) => {
  const [vitalityAve, mentalActivityAve] = get(scoreAverageAtom);
  const mimosysData = get(MimosysDataAtom);
  return sdCalc(mimosysData, vitalityAve * 100, mentalActivityAve * 100);
});

export const vitalitySdSetAtom = atom((get) => {
  const [vitalitySd] = get(scoreSD);
  const sdChartValue = get(AppSettingData).sd_chart;
  const [vitalityAve] = get(scoreAverageAtom);
  const vitalitySdTop = Math.min(
    100,
    vitalityAve * 100 + sdChartValue * vitalitySd
  );
  const vitalitySdBottom = Math.max(
    0,
    vitalityAve * 100 - sdChartValue * vitalitySd
  );
  return [vitalitySdTop, vitalitySdBottom];
});

export const mentalActivitySdSetAtom = atom((get) => {
  const [, mentalActivitySd] = get(scoreSD);
  const sdChartValue = get(AppSettingData).sd_chart;
  const [, mentalActivityAve] = get(scoreAverageAtom);
  const mentalActivitySdTop = Math.min(
    100,
    mentalActivityAve * 100 + sdChartValue * mentalActivitySd
  );
  const mentalActivitySdBottom = Math.max(
    0,
    mentalActivityAve * 100 - sdChartValue * mentalActivitySd
  );
  return [mentalActivitySdTop, mentalActivitySdBottom];
});

export const mi1NormalRange = [8, 3];

export const latestVitalityTrendAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  if (mimosysData.length > 1) {
    const latestVitality = mimosysData[mimosysData.length - 1].vitality * 100;
    const [vitalitySdTop, vitalitySdBottom] = get(vitalitySdSetAtom);
    if (latestVitality > vitalitySdTop) {
      return Trend.Up;
    } else if (latestVitality < vitalitySdBottom) {
      return Trend.Down;
    } else {
      return Trend.Normal;
    }
  } else {
    return Trend.Normal;
  }
});

export const latestMentalActivityTrendAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  if (mimosysData.length > 1) {
    const latestMentalActivity =
      mimosysData[mimosysData.length - 1].mental_activity * 100;
    const [mentalActivitySdTop, mentalActivitySdBottom] = get(
      mentalActivitySdSetAtom
    );
    if (latestMentalActivity > mentalActivitySdTop) {
      return Trend.Up;
    } else if (latestMentalActivity < mentalActivitySdBottom) {
      return Trend.Down;
    } else {
      return Trend.Normal;
    }
  } else {
    return Trend.Normal;
  }
});

export const recordingLastDatesAtom = atom((get) => {
  let lastCount = 0;
  const checkDate = dayjs();
  const mimosysData = JSON.parse(JSON.stringify(get(MimosysDataAtom)));
  mimosysData.reverse();
  for (const data of mimosysData) {
    if (checkDate.isSame(dayjs(data.recorded_at), "d")) {
      lastCount += 1;
      checkDate.add(-1, "d");
    } else if (
      checkDate.clone().add(1, "d").isSame(dayjs(data.recorded_at), "day")
    ) {
      console.log("skip");
    } else {
      break;
    }
  }
  return lastCount;
});

export const logoutAtom = atom(null, (get, set) => {
  // set(employeeInfoAtom, employeeInfoInitial);
  set(MimosysDataAtom, MimosysDataInitial);
  set(AppSettingData, AppSettingInitial);
  // set(CompanySettingData, CompanySettingInitial);
  set(homeSplashDisplayAtom, true);
  set(showSessionTimeoutAtom, false);
  // set(settingScreenTypeAtom, SettingScreenType.main);
  // set(AnalysisScreenTypeAtom, AnalysisScreenType.recording);
  set(recordDialogOpenAtom, false);
  // set(closeAnalysisAtom, null);
  // set(ScreenState, ScreenType.beforeLoginScreen);
  // set(IconPathData, IconPathInitial);
  // set(resultTabSelectAtom, 0);
  // set(resultBaseTimeAtom, dayjs());
  // set(LocalParamData, LocalParamInitial);
  // set(accessTokenAtom, "");
});
