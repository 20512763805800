import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./components/molecules/ThemeProvider";
import "./i18n/index";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ErrorFallback";
import "@fontsource/noto-sans-jp/700.css";
import "@fontsource/noto-sans-jp/500.css";
import "@fontsource/noto-sans-jp/300.css";
import "@fontsource/oswald/700.css";
import "@fontsource/oswald/500.css";
import "@fontsource/oswald/300.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { APP_NAME } from "./environments";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const container = document.getElementById("root");
if (container === null)
  throw new Error("Internal Error: Not found root element!");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => (location.href = "/")}
          >
            <Helmet>
              <title>{APP_NAME}</title>
            </Helmet>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ErrorBoundary>
        </ThemeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);
