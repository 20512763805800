import Dexie, { IndexableType, PromiseExtended, Table } from "dexie";
import crypto from "crypto-js";
import { SelectionLoginUser } from "../types";
import { useCallback } from "react";
import { useToastMessage } from "../hooks/useToastMessage";
import CryptoJS from "crypto-js/core";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";

const AES_KEY_VER3 = "Eto7Iywy9)jdQ(8tRa*7El!K";
const AES_KEY = "Pnt44oFab/apIFWZVrJ3jkWnBcwhfOmD";

function decryptVer3(encryptedText: string): string {
  return crypto.AES.decrypt(encryptedText, AES_KEY_VER3).toString(
    crypto.enc.Utf8
  );
}

function encrypt(text: string): string {
  return crypto.AES.encrypt(text, AES_KEY).toString();
}

function decrypt(encryptedText: string): string {
  return crypto.AES.decrypt(encryptedText, AES_KEY).toString(crypto.enc.Utf8);
}

function weakEncrypt(text: string): string {
  const hash = CryptoJS.SHA256(AES_KEY);
  return crypto.AES.encrypt(text, hash, {
    mode: CryptoJS.mode.ECB,
  }).toString();
}

function weakDecrypt(encryptedText: string): string {
  const hash = CryptoJS.SHA256(AES_KEY);
  return crypto.AES.decrypt(encryptedText, hash, {
    mode: CryptoJS.mode.ECB,
  }).toString(crypto.enc.Utf8);
}

type DbUser = {
  id?: number;
  wfeEmail: string;
  fePassword: string;
  nickname: string;
  color: string;
};

function fromAppUser(user: SelectionLoginUser): DbUser {
  const wfeEmail = weakEncrypt(user.email);
  const fePassword = encrypt(user.password);
  return {
    nickname: user.nickname,
    color: user.color,
    wfeEmail,
    fePassword,
  };
}

function intoAppUser(dbUser: DbUser): SelectionLoginUser {
  const email = weakDecrypt(dbUser.wfeEmail);
  const password = decrypt(dbUser.fePassword);
  return {
    id: dbUser.id,
    nickname: dbUser.nickname,
    color: dbUser.color,
    email,
    password,
  };
}

class ClientDb extends Dexie {
  user!: Table<DbUser>;

  constructor() {
    super("su");
    this.version(3).stores({
      user: `++id, color, nickname, feEmail`,
      constrains: `++id, autoGainControl, echoCancellation, latency, noiseSuppression`,
    });
    this.version(4)
      .stores({
        user: "++id, nickname, &wfeEmail",
      })
      .upgrade((tx) => {
        return tx
          .table("user")
          .toCollection()
          .modify((user) => {
            user.wfeEmail = weakEncrypt(decryptVer3(user.feEmail));
            user.fePassword = encrypt(decryptVer3(user.fePassword));
            user.color = "#" + user.color;
            delete user.feEmail;
            delete user.uri;
          });
      });
  }
}

export class DB {
  private static _db = new ClientDb();

  static addUser(user: SelectionLoginUser): PromiseExtended<IndexableType> {
    const dbUser = fromAppUser(user);
    return this._db.user.add(dbUser);
  }

  static updateUser(
    id: number,
    user: SelectionLoginUser
  ): PromiseExtended<number> {
    const dbUser = fromAppUser(user);
    return this._db.user.update(id, dbUser);
  }

  static deleteUser(id: number): PromiseExtended<number> {
    return this._db.user.where({ id: id }).delete();
  }

  static loadAllUsers(): PromiseExtended<SelectionLoginUser[]> {
    return this._db.user
      .toArray()
      .then((user) => user.map((user) => intoAppUser(user)));
  }

  static loadUserByEmail(
    email: string
  ): PromiseExtended<SelectionLoginUser | undefined> {
    const wfeEmail = weakEncrypt(email);
    return this._db.user
      .where({ wfeEmail })
      .first()
      .then((dbUser) => (dbUser ? intoAppUser(dbUser) : undefined));
  }
}

type AddUserToDB = (user: SelectionLoginUser) => Promise<boolean>;
export function useAddUserToDb(): AddUserToDB {
  const toast = useToastMessage();
  const t = useModifiedTranslation();

  return useCallback(
    async (user) => {
      const savedUser = await DB.loadUserByEmail(user.email);
      if (savedUser) {
        toast(t("Waring.db.userIsAlreadyExist"), "warning");
        return false;
      } else {
        await DB.addUser(user);
        return true;
      }
    },
    [t, toast]
  );
}

// パスワード変更時に選択ユーザーのパスワードも変更する
export async function ChangeSelectionUserPassword(
  email: string,
  newPassword: string
): Promise<void> {
  const savedUser = await DB.loadUserByEmail(email);
  if (savedUser) {
    savedUser.password = newPassword;
    if (savedUser.id) {
      DB.updateUser(savedUser.id, savedUser);
    }
  }
}
