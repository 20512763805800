import { Button, Center, Heading, Image, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Layout } from "../components/atoms/Layout";
import { APP_NAME, BANNER_IMAGE_URL } from "../environments";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";

export function AccountUnlocked(): ReactElement {
  const t = useModifiedTranslation();
  const [searchParams] = useSearchParams();
  const failed = searchParams.get("result") === "failed";

  return (
    <Layout>
      <Image src={BANNER_IMAGE_URL} alt={APP_NAME} pb={10} mx="auto" />
      <Center position="relative" mb={110}>
        <Heading
          as="h1"
          color="text.main_text_lv1"
          size="md"
          textAlign="center"
        >
          {t("TemporaryUnlock.title")}
        </Heading>
      </Center>
      {failed ? (
        <Text align="center" color="red" mb={20}>
          {t("TemporaryUnlock.mainMessage.failed")}
        </Text>
      ) : (
        <>
          <Text align="center" mb={10}>
            {t("TemporaryUnlock.mainMessage")}
          </Text>
          <Text fontSize="12px" align="center" mb={20}>
            {t("TemporaryUnlock.subMessage")}
          </Text>
        </>
      )}
      <Button
        as={Link}
        to="../login"
        variant="btn_primary"
        display="flex"
        m="0 10%"
      >
        {t("TemporaryUnlock.login")}
      </Button>
    </Layout>
  );
}
