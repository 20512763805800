import { theme_light } from "./common_light";

export const theme_brown_light = {
  ...theme_light,
  primary: {
    theme_lv1: "#B29D9C", // テーマカラー1
    theme_lv2: "#D6CDBF", // テーマカラー2
    theme_lv3: "#E7E1D5", // テーマカラー3
    accent: "#988282", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#F9F6F2", // 背景色1
  },
};
