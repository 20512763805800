import { theme_dark } from "./common_dark";

export const theme_green_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#9CC813", // テーマカラー1
    theme_lv2: "#C8DE81", // テーマカラー2
    theme_lv3: "#EEF4D9", // テーマカラー3
    accent: "#2AA738", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#113700", // 背景色1
  },
};
