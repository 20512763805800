import { atom } from "jotai";
import { floorToString } from "../utils/mimosysDataExtracter";
import { Trend } from "../types";
import dayjs, { Dayjs } from "dayjs";

export type ResultChartData = {
  date: number;
  vitality100?: number;
  mental_activity100?: number;
  mental_activity_reliability?: boolean;
  mi1_result?: number;
  mi1_result_reliability?: boolean;
  datingType?: string;
};

export type ChartInfoType = {
  data: ResultChartData[];
  latestVitalityTrend: Trend;
  vitalityAve: number;
  vitalitySdTop: number;
  vitalitySdBottom: number;

  latestMentalActivityTrend: Trend;
  mentalActivityAve: number;
  mentalActivitySdTop: number;
  mentalActivitySdBottom: number;

  mi1Ave: number;
  mi1NormalRangeTop: number;
  mi1NormalRangeBottom: number;

  recordingLastDates: number;
  outputStenScore: boolean;
};

export interface MimosysDateType {
  recorded_at: string;
  analyzed_at: string;
  mental_activity: number;
  mental_activity_reliability?: boolean;
  vitality: number;
  mi1_result?: number;
  mi1_result_reliability?: boolean;
  voice_profiles?: VoiceProfilesDateType;
  file_id: string;
}
export interface VoiceProfilesDateType {
  recorded_at: string;
}

export interface MimosysResultDateType {
  recorded_at: string;
  mental_activity: number;
  mental_activity_reliability?: boolean;
  vitality: number;
  mi1_result?: number;
  mi1_result_reliability?: boolean;
}

export const analysisStartedDateAtom = atom<Dayjs | null>((get) => {
  const mimosysData = get(MimosysDataAtom);
  if (mimosysData.length > 0) {
    return dayjs(mimosysData[0].recorded_at);
  } else {
    return null;
  }
});

export const latestDateAtom = atom<Dayjs | null>((get) => {
  const mimosysData = get(MimosysDataAtom);
  if (mimosysData.length > 0) {
    return dayjs(mimosysData[mimosysData.length - 1].recorded_at);
  } else {
    return null;
  }
});

export const latestStenScoreAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  return mimosysData.length &&
    mimosysData[mimosysData.length - 1].mi1_result !== undefined
    ? (mimosysData[mimosysData.length - 1].mi1_result as number)
    : null;
});

export const latestVitalityAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  return mimosysData.length > 0
    ? mimosysData[mimosysData.length - 1].vitality
    : 0;
});
export const latestVitalityStringAtom = atom((get) => {
  return floorToString(get(latestVitalityAtom));
});

export const latestMentalActivityAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  return mimosysData.length > 0
    ? mimosysData[mimosysData.length - 1].mental_activity
    : 0;
});

export const latestMentalActivityStringAtom = atom((get) => {
  return floorToString(get(latestMentalActivityAtom));
});

export const latestMi1ResultReliabilityAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  return mimosysData.length > 0
    ? mimosysData[mimosysData.length - 1].mi1_result_reliability
    : undefined;
});

export const latestMentalActivityReliabilityAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  return mimosysData.length > 0
    ? mimosysData[mimosysData.length - 1].mental_activity_reliability
    : undefined;
});

export const MimosysDataInitial: MimosysDateType[] = [];
export const MimosysDataAtom = atom(MimosysDataInitial);

export const isGetMimosysDataAtom = atom(false);

export type EmotionType = {
  ang: number;
  joy: number;
  clm: number;
  srw: number;
  exc: number;
};

export const latestEmotionAtom = atom<EmotionType>({
  ang: 0,
  joy: 0,
  clm: 0,
  srw: 0,
  exc: 0,
});
