import { theme_light } from "./common_light";

export const theme_yellow_green_light = {
  ...theme_light,
  primary: {
    theme_lv1: "#DADF00", // テーマカラー1
    theme_lv2: "#EAEC7F", // テーマカラー2
    theme_lv3: "#F7F9DA", // テーマカラー3
    accent: "#9CC813", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#FDFDF6", // 背景色1
  },
};
