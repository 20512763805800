import { ReactElement } from "react";
import { Box, Center, Flex, Text, VStack } from "@chakra-ui/react";
import { ResultDashboardData } from "../../types";
import { useModifiedTranslation } from "../../hooks/useModifiedTranslation";

export function DashboardBox(props: {
  result: ResultDashboardData;
}): ReactElement {
  const t = useModifiedTranslation();
  return (
    <Flex
      color="black"
      bg="primary.bg_lv1"
      minHeight="120px"
      fontSize="16px"
      marginTop="5%"
    >
      <Center width="50%">
        <Box
          bg="white"
          width="85%"
          maxWidth="120px"
          border={props.result.mainParam.border}
          borderRadius="10px"
          textAlign="center"
        >
          <Text fontSize="18px" fontWeight="bold" marginTop="0.2em">
            {t(props.result.mainParam.title)}
          </Text>
          <Text fontSize="28px" marginTop="-0.1em">
            {props.result.mainParam.value !== undefined &&
            props.result.mainParam.value !== -100
              ? props.result.mainParam.value
              : "Error"}
          </Text>
        </Box>
      </Center>
      <VStack spacing={2} align="stretch" width="50%" marginTop="1em">
        {props.result.subParams.map((subParam, subParamIndex) => {
          return (
            <Flex marginTop="0%" key={subParamIndex}>
              <Box
                fontSize="14px"
                bg="login.login_button_bg"
                marginLeft="0%"
                width="50%"
                minWidth="80px"
                borderRadius="5px"
                textAlign="center"
              >
                {t(subParam.title)}
              </Box>
              <Box marginLeft="5%" width="50%" fontSize="14px" textAlign="left">
                {subParam.value !== undefined ? subParam.value : "Error"}
              </Box>
            </Flex>
          );
        })}
      </VStack>
    </Flex>
  );
}
