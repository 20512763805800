import {
  AnalysisEngineType,
  AnalysisErrorReason,
  AnalysisState,
  RecordedData,
} from "../types";
import {
  detectVolumeAtom,
  employeeInfoAtom,
  isQueryModeAtom,
  QuestionnaireAnswers,
  recTypeAtom,
} from "../store";
import { MutableRefObject, useCallback } from "react";
import { useGetAnalysisEngines } from "../utils/selectAnalysisEngine";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { appAxios } from "../utils/appAxios";
import {
  APP_TYPE,
  SKIP_QUESTIONNAIRES,
  USE_ANALYSIS_ERROR_PAGE,
} from "../environments";
import { useToastMessage } from "./useToastMessage";
import axios, { AxiosResponse } from "axios";
import { generateVoiceFileData } from "../utils/generateVoiceFileData";
import {
  MimosysDataAtom,
  MimosysDateType,
  isGetMimosysDataAtom,
  latestEmotionAtom,
} from "../stores/mimosysStore";
import { sentryLog } from "../../sentry";
import {
  AnalysisResult as MimosysResultType,
  getEmotionAverage,
} from "../engines/mimosysEngine";

type HandleApiError = (err: unknown) => void;

function useHandleApiError(): HandleApiError {
  const navigate = useNavigate();
  const toastMessage = useToastMessage();
  return useCallback(
    (err: unknown): void => {
      if (
        USE_ANALYSIS_ERROR_PAGE &&
        axios.isAxiosError(err) &&
        err.code &&
        err.message
      ) {
        const state: AnalysisErrorReason = {
          code: err.code,
          message: err.message,
        };
        navigate(`../analysis-error`, { state });
      } else {
        toastMessage(err);
      }
    },
    [navigate, toastMessage]
  );
}

function randomStr(): string {
  return Math.random().toString(32).substring(2);
}

type AnalyzeVoice = (
  recordedData: RecordedData[],
  answers: QuestionnaireAnswers,
  requestIdRef: MutableRefObject<null | string>,
  setAnalysisStatus: (state: AnalysisState) => void
) => Promise<void>;

export function useAnalyzeVoice(engineType: AnalysisEngineType): AnalyzeVoice {
  const handleApiError = useHandleApiError();
  const analysisEngines = useGetAnalysisEngines(engineType);
  const [detectVolume] = useAtom(detectVolumeAtom);
  const navigate = useNavigate();

  // 一般ユーザー情報のグローバル状態管理用
  const [employeeInfo] = useAtom(employeeInfoAtom);
  // rec_type（録音種別の選択情報）のグローバル状態管理用
  const [recType] = useAtom(recTypeAtom);
  // クエリログインのグローバル状態管理用
  const [isQueryMode] = useAtom(isQueryModeAtom);
  // クエリログインで録音完了後に初期化の為のグローバル状態管理用
  const [, setMimosysData] = useAtom(MimosysDataAtom);
  const [, setMimosysEmotions] = useAtom(latestEmotionAtom);
  const [, setIsGetMimosysData] = useAtom(isGetMimosysDataAtom);

  return useCallback(
    async (recordedData, answers, requestIdRef, setAnalysisStatus) => {
      setAnalysisStatus("UPLOADING");
      const requestId = randomStr();
      requestIdRef.current = requestId;

      /*
       * ファイルアップロード
       */
      try {
        // 契約者アカウントによるクエリログインを考慮し、クエリストリングに「一般ユーザーID」（employee_id）を付与
        const formData = generateVoiceFileData(
          recordedData,
          recType,
          employeeInfo
        );
        const uploadingResponse = await appAxios.post("/voice/file", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        const analysisFileId = uploadingResponse.data.file_id;

        /*
         * アンケート送信
         */
        // 契約者アカウントによるクエリログインを考慮し、クエリストリングに「一般ユーザーID」（employee_id）を付与
        if (!SKIP_QUESTIONNAIRES) {
          await appAxios.post("/questionnaire", {
            file_id: analysisFileId,
            employee_id: employeeInfo.employee_id,
            questionnaire_name: answers.name,
            questionnaire_content: answers.answers,
          });
        }

        /*
         * 音声解析
         */
        // 契約者アカウントによるクエリログインを考慮し、クエリストリングに「一般ユーザーID」（employee_id）を付与
        const requests = analysisEngines.map((engine) =>
          engine.analyze(analysisFileId, detectVolume, employeeInfo.employee_id)
        );

        setAnalysisStatus("ANALYZING");
        const analysisResponse = await Promise.allSettled(requests);

        if (!isQueryMode) {
          /*
           * 一般ユーザーによる通常ログインの場合
           */
          // 解析結果確認
          const isAllFailed = analysisResponse.every(
            (result) => result.status === "rejected"
          );
          if (isAllFailed) {
            handleApiError(
              (analysisResponse[0] as PromiseRejectedResult).reason
            );
            setAnalysisStatus("IDLE");
          } else if (requestIdRef.current === requestId) {
            setIsGetMimosysData(false);
            // 解析結果の画面に遷移
            let apiCalls;
            const analysisMi1List = appAxios.get(
              "/analysis/mi1/list?detail=false"
            );
            // PoCアプリの場合は感情の解析結果が必要
            if (APP_TYPE === "poc") {
              const analysisMi1 = appAxios.get(
                `/analysis/mi1?file_id=${analysisFileId}`
              );
              apiCalls = Promise.all([analysisMi1List, analysisMi1]);
            } else {
              apiCalls = Promise.all([analysisMi1List]);
            }

            apiCalls
              .then((responses) => {
                const responsesTyped = responses as [
                  AxiosResponse,
                  AxiosResponse?
                ];
                const inputData = Object.values(
                  responsesTyped[0].data
                ) as MimosysDateType[];
                setMimosysData(inputData);
                setIsGetMimosysData(true);
                if (APP_TYPE === "poc" && responsesTyped[1]) {
                  const latestMi1Result = responsesTyped[1]
                    .data as MimosysResultType;
                  const averageEmotions = getEmotionAverage(
                    latestMi1Result.mimosys_sub_parameters.emotions
                  );
                  setMimosysEmotions(averageEmotions);
                }
                navigate(`../results/${analysisFileId}`);
              })
              .catch((err) => {
                handleApiError(err);
                sentryLog(err);
              })
              .finally(() => {
                setAnalysisStatus("IDLE");
              });
          }
        } else {
          /*
           * 契約者アカウントによるクエリログインの場合
           */
          // クエリログイン時の音声録音解析完了画面に遷移
          navigate("../../../query-login-result");
        }
      } catch (err) {
        handleApiError(err);
        sentryLog(err);
      } finally {
        if (isQueryMode) {
          setAnalysisStatus("IDLE");
        }
      }
    },
    [
      analysisEngines,
      detectVolume,
      handleApiError,
      navigate,
      recType,
      employeeInfo,
      isQueryMode,
      setIsGetMimosysData,
      setMimosysData,
      setMimosysEmotions,
    ]
  );
}
