import React, { createContext, ReactNode, useContext, useState } from "react";
import {
  AlertProps,
  extendTheme,
  ChakraProvider,
  ChakraProviderProps,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { themeColorAtom } from "../../store";
import { themeType as themeColorType } from "../../types";
import { theme_common } from "../../assets/colors/common";

import { theme_blue_light } from "../../assets/colors/blue_light";
import { theme_blue_dark } from "../../assets/colors/blue_dark";
import { theme_blue_gray_light } from "../../assets/colors/blue_gray_light";
import { theme_blue_gray_dark } from "../../assets/colors/blue_gray_dark";
import { theme_bluesky_light } from "../../assets/colors/bluesky_light";
import { theme_bluesky_dark } from "../../assets/colors/bluesky_dark";
import { theme_brown_light } from "../../assets/colors/brown_light";
import { theme_brown_dark } from "../../assets/colors/brown_dark";
import { theme_green_light } from "../../assets/colors/green_light";
import { theme_green_dark } from "../../assets/colors/green_dark";
import { theme_pink_light } from "../../assets/colors/pink_light";
import { theme_pink_dark } from "../../assets/colors/pink_dark";
import { theme_sakura_light } from "../../assets/colors/sakura_light";
import { theme_sakura_dark } from "../../assets/colors/sakura_dark";
import { theme_yellow_light } from "../../assets/colors/yellow_light";
import { theme_yellow_dark } from "../../assets/colors/yellow_dark";
import { theme_yellow_green_light } from "../../assets/colors/yellow_green_light";
import { theme_yellow_green_dark } from "../../assets/colors/yellow_green_dark";
import { theme_yellow_orange_light } from "../../assets/colors/yellow_orange_light";
import { theme_yellow_orange_dark } from "../../assets/colors/yellow_orange_dark";

type ThemeType = ChakraProviderProps["theme"];
type SetThemeType = (themeName: themeColorType) => void;

const ThemeContext = createContext<[ThemeType, SetThemeType] | undefined>(
  undefined
);

const activeButtonStyle = {
  _active: {
    color: "primary.theme_lv1",
    bg: "common.base",
    borderColor: "primary.theme_lv1",
  },
};

const disableButtonStyle = {
  _disabled: {
    color: "common_text.light",
    bgColor: "primary.theme_lv2",
    borderColor: "transparent",
  },
};

const btn_commonStyle = {
  color: "common_text.light",
  bgColor: "primary.theme_lv1",
  borderColor: "primary.theme_lv1",
  borderWidth: "2px",
  rounded: "full",
  "@media (hover: hover) and (pointer: fine)": {
    _hover: {
      color: "common_text.light",
      bgColor: "primary.theme_lv1",
      opacity: 0.8,
      ...disableButtonStyle,
    },
    ...activeButtonStyle,
    ...disableButtonStyle,
  },
  "@media (hover: none)": {
    _hover: {
      // タッチデバイスではホバー時のスタイルを無視
      ...disableButtonStyle,
    },
    ...activeButtonStyle,
    ...disableButtonStyle,
  },
};

const commonStyle = extendTheme({
  colors: theme_common,
  styles: {
    global: {
      body: {
        color: "text.main_text_lv1",
      },
    },
  },
  components: {
    Button: {
      variants: {
        btn_primary: () => {
          return {
            minHeight: "64px",
            ...btn_commonStyle,
          };
        },
        btn_secondary: () => {
          return {
            ...btn_commonStyle,
          };
        },
        btn_primary_non_active: () => ({
          color: "common_text.light",
          bgColor: "gray.300",
          rounded: "full",
          borderWidth: "2px",
          borderColor: "gray.300",
          _hover: {
            background: "gray.300",
            opacity: 1,
            _disabled: {
              background: "gray.300",
              opacity: 1,
            },
          },
          _active: {
            background: "gray.300",
            opacity: 1,
          },
          _disabled: {
            background: "gray.300",
            opacity: 1,
          },
        }),
        btn_debug: () => ({
          minHeight: "64px",
          color: "common_text.light",
          bgColor: "gray.500",
          rounded: "full",
          _hover: {
            background: "gray.300",
            opacity: 0.8,
            _disabled: {
              backgroundColor: "gray.300",
              opacity: 0.4,
            },
          },
          _active: {
            background: "gray.300",
            opacity: 0.5,
          },
        }),
        image_btn: () => ({
          _hover: {
            background: "transparent",
            opacity: 0.8,
          },
        }),
      },
    },
    Alert: {
      baseStyle: (props: AlertProps) => {
        const { status } = props;
        const infoBase = status === "warning" && {
          container: {
            color: "common.base",
            background: "unset",
            backgroundColor: "#FFC107",
          },
        };
        return {
          ...infoBase,
        };
      },
    },
  },
  fonts: {
    heading: `Noto Sans JP, Oswald`,
    body: `Noto Sans JP, Oswald`,
  },
});

const themeBlueLight = extendTheme(commonStyle, {
  colors: theme_blue_light,
});

const themeBlueDark = extendTheme(commonStyle, {
  colors: theme_blue_dark,
});

const themeBlueGrayLight = extendTheme(commonStyle, {
  colors: theme_blue_gray_light,
});

const themeBlueGrayDark = extendTheme(commonStyle, {
  colors: theme_blue_gray_dark,
});

const themeBlueSkyLight = extendTheme(commonStyle, {
  colors: theme_bluesky_light,
});

const themeBlueSkyDark = extendTheme(commonStyle, {
  colors: theme_bluesky_dark,
});

const themeBrownLight = extendTheme(commonStyle, {
  colors: theme_brown_light,
});

const themeBrownDark = extendTheme(commonStyle, {
  colors: theme_brown_dark,
});

const themeGreenLight = extendTheme(commonStyle, {
  colors: theme_green_light,
});

const themeGreenDark = extendTheme(commonStyle, {
  colors: theme_green_dark,
});

const themePinkLight = extendTheme(commonStyle, {
  colors: theme_pink_light,
});

const themePinkDark = extendTheme(commonStyle, {
  colors: theme_pink_dark,
});

const themeSakuraLight = extendTheme(commonStyle, {
  colors: theme_sakura_light,
});

const themeSakuraDark = extendTheme(commonStyle, {
  colors: theme_sakura_dark,
});

const themeYellowLight = extendTheme(commonStyle, {
  colors: theme_yellow_light,
});

const themeYellowDark = extendTheme(commonStyle, {
  colors: theme_yellow_dark,
});

const themeYellowGreenLight = extendTheme(commonStyle, {
  colors: theme_yellow_green_light,
});

const themeYellowGreenDark = extendTheme(commonStyle, {
  colors: theme_yellow_green_dark,
});

const themeYellowOrangeLight = extendTheme(commonStyle, {
  colors: theme_yellow_orange_light,
});

const themeYellowOrangeDark = extendTheme(commonStyle, {
  colors: theme_yellow_orange_dark,
});

export const theme_list = {
  blue_light: themeBlueLight,
  blue_dark: themeBlueDark,
  blue_gray_light: themeBlueGrayLight,
  blue_gray_dark: themeBlueGrayDark,
  bluesky_light: themeBlueSkyLight,
  bluesky_dark: themeBlueSkyDark,
  brown_light: themeBrownLight,
  brown_dark: themeBrownDark,
  green_light: themeGreenLight,
  green_dark: themeGreenDark,
  pink_light: themePinkLight,
  pink_dark: themePinkDark,
  sakura_light: themeSakuraLight,
  sakura_dark: themeSakuraDark,
  yellow_light: themeYellowLight,
  yellow_dark: themeYellowDark,
  yellow_green_light: themeYellowGreenLight,
  yellow_green_dark: themeYellowGreenDark,
  yellow_orange_light: themeYellowOrangeLight,
  yellow_orange_dark: themeYellowOrangeDark,
};

interface ThemeProviderProps {
  children: ReactNode;
}

function getTheme(themeName: themeColorType): ThemeType {
  const theme = theme_list[themeName];
  return theme || theme_list["sakura_light"];
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [themeColor] = useAtom(themeColorAtom);
  const currentTheme = getTheme(themeColor);
  const [theme, _setTheme] = useState<ThemeType>(currentTheme);

  const setTheme = (themeName: themeColorType): void => {
    _setTheme(getTheme(themeName));
  };

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </ThemeContext.Provider>
  );
};

export function useTheme(): [ThemeType, SetThemeType] {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error(`useTheme must be used within a ThemeProvider`);
  }
  return context;
}
