import { theme_light } from "./common_light";

export const theme_blue_gray_light = {
  ...theme_light,
  primary: {
    theme_lv1: "#90939F", // テーマカラー1
    theme_lv2: "#BDBDC3", // テーマカラー2
    theme_lv3: "#E9E9EB", // テーマカラー3
    accent: "#1A1A1A", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#FAFAFA", // 背景色1
  },
};
