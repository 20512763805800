import { Switch, Box } from "@chakra-ui/react";
import { ReactElement, useState } from "react";

/*
 * レイアウトパーツ:トグル表示
 */
export const IOSSwitch = (props: {
  id: string;
  activeColor: string;
  backgroundColor: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
}): ReactElement => {
  const [, setIsChecked] = useState(props.checked);
  const isChecked = props.checked;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(e.target.checked);
    props.onChange(e, e.target.checked);
  };

  return (
    <>
      <Box
        sx={{
          ".analysis_data_switch .chakra-switch__track": {
            backgroundColor: props.backgroundColor,
            // opacity: 0.3,
          },
          ".analysis_data_switch[data-checked] .chakra-switch__track": {
            backgroundColor: props.activeColor,
            // opacity: 1,
          },
          ".analysis_data_switch span span": {
            backgroundColor: "white",
          },
        }}
      >
        <Switch
          id={props.id}
          colorScheme={props.activeColor}
          isChecked={isChecked}
          onChange={handleChange}
          className="analysis_data_switch"
          style={{
            borderRadius: "20px",
            width: "34px",
            height: "20px",
          }}
        />
      </Box>
    </>
  );
};
