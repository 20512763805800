import { theme_dark } from "./common_dark";

export const theme_yellow_orange_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#FCC800", // テーマカラー1
    theme_lv2: "#FEDD78", // テーマカラー2
    theme_lv3: "#FEF4D6", // テーマカラー3
    accent: "#ED6D00", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#2E2200", // 背景色1
  },
};
