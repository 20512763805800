import { theme_dark } from "./common_dark";

export const theme_blue_gray_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#90939F", // テーマカラー1
    theme_lv2: "#BDBDC3", // テーマカラー2
    theme_lv3: "#E9E9EB", // テーマカラー3
    accent: "#1A1A1A", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#23282F", // 背景色1
  },
};
