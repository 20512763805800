import { MimosysDateType, MimosysResultDateType } from "../stores/mimosysStore";

export function allScoreAverage(
  mimosysData: MimosysDateType[]
): [number, number, number] {
  if (mimosysData.length === 0) {
    return [0, 0, 0];
  }

  const allData: MimosysResultDateType[] = [];
  for (const data of mimosysData) {
    allData.push({
      recorded_at: data.recorded_at,
      vitality: data.vitality,
      mental_activity: data.mental_activity,
      mi1_result: data.mi1_result,
    });
  }
  return averageCalc(allData);
}

function averageCalc(
  allData: MimosysResultDateType[]
): [number, number, number] {
  const vitalityAve =
    allData.reduce((total, next) => total + next.vitality, 0) / allData.length;
  const mentalActivityAve =
    allData.reduce((total, next) => total + next.mental_activity, 0) /
    allData.length;

  const mi1AveData = allData.reduce(
    (p, n) => {
      if (n.mi1_result !== undefined) {
        return { count: ++p.count, total: p.total + n.mi1_result };
      } else {
        return p;
      }
    },
    { count: 0, total: 0 }
  );
  const mi1Ave = mi1AveData.total / mi1AveData.count;

  return [vitalityAve, mentalActivityAve, mi1Ave];
}

export function sdCalc(
  data: MimosysDateType[],
  vitalityAve: number,
  mentalActivityAve: number
): [number, number] {
  const squaredDifferenceVitality = data.map((current) => {
    const difference = current.vitality * 100 - vitalityAve;
    return difference ** 2;
  });

  const squaredDifferenceMentalActivity = data.map((current) => {
    const difference = current.mental_activity * 100 - mentalActivityAve;
    return difference ** 2;
  });

  const sdVitality = Math.sqrt(
    calcSum(squaredDifferenceVitality) / squaredDifferenceVitality.length
  );
  const sdMentalActivity = Math.sqrt(
    calcSum(squaredDifferenceMentalActivity) /
      squaredDifferenceMentalActivity.length
  );

  return [sdVitality, sdMentalActivity];
}

function calcSum(data: number[]): number {
  if (data.length === 0) {
    return 0;
  }
  return data.reduce((total, next) => total + next);
}
