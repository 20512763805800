import { forwardRef, Switch, SwitchProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

export const PrimarySwitch = forwardRef<SwitchProps, "input">((props, ref) => {
  return (
    <>
      <Box
        sx={{
          ".primary_data_switch .chakra-switch__track": {
            backgroundColor: "primary.theme_lv3",
          },
          ".primary_data_switch[data-checked] .chakra-switch__track": {
            backgroundColor: "primary.theme_lv1",
          },
        }}
      >
        <Switch
          sx={{
            ".chakra-switch__track[data-checked]": {
              backgroundColor: "primary.theme_lv1",
            },
          }}
          ref={ref}
          className="primary_data_switch"
          {...props}
        />
      </Box>
    </>
  );
});
