import { Flex } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginErrorModal } from "../../components/molecules/LoginErrorModal";
import { employeeInfoAtom } from "../../store";
import { LoginErrorCode, LoginMethod } from "../../types";
import { appAxios } from "../../utils/appAxios";
import { checkEngineEnabled } from "../../utils/checkEnableEngine";
import { useSetSetting } from "../../utils/setting";
import { useGetAccessToken, useSaveTokenToStorage } from "../../utils/token";

export function TwooCaCallback(): JSX.Element {
  const [employeeInfo] = useAtom(employeeInfoAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  // コールバック時に一度だけ実行するためにsetter系の処理をrefで取得
  const saveToken = useRef(useSaveTokenToStorage());
  const getAccessToken = useRef(useGetAccessToken());
  const setSettingInfo = useRef(useSetSetting());

  const [hasLoginError, setHasLoginError] = useState(false);
  const [errorCode, setErrorCode] = useState<LoginErrorCode | null>(null);
  const onInvalidUserInfo = (errorCode: LoginErrorCode): void => {
    setErrorCode(errorCode);
    setHasLoginError(true);
  };
  const closeErrorModal = (): void => {
    setHasLoginError(false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get("code") ?? "";

    getAccessToken
      .current(LoginMethod.TwoocaAuth, onInvalidUserInfo, {
        code: authCode,
      })
      .then(async (response) => {
        if (!response) {
          // APIエラー時はトップ画面に飛ばす
          navigate("/", { replace: true });
          return;
        }

        if (!checkEngineEnabled(response.access_token)) {
          onInvalidUserInfo("ENGINE_DISABLED");
          return;
        }
        saveToken.current(response);
        const employee_info = await appAxios.get("/employee/me");
        if (employee_info) {
          setSettingInfo.current(employee_info.data.employee_id);
        }
      })
      .catch((err) => {
        // 想定外のエラー
        console.error(err);
        onInvalidUserInfo("INVALID_TWOOCA_TOKEN");
        navigate("/404", { replace: true });
      });
  }, [getAccessToken, setSettingInfo, saveToken, location.search, navigate]);

  useEffect(() => {
    if (employeeInfo.id != "") {
      // 認証コードを履歴に残さないようURLをreplaceする
      navigate(`/${i18n.resolvedLanguage}/home`, { replace: true });
    }
  }, [navigate, employeeInfo, i18n.resolvedLanguage]);

  return (
    <Flex>
      <LoginErrorModal
        isOpen={hasLoginError}
        errorCode={errorCode}
        onClose={closeErrorModal}
      />
    </Flex>
  );
}
