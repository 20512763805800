import { PropsWithChildren, ReactElement } from "react";
import { Box, Center, Stack } from "@chakra-ui/react";

type Props = {
  title: string;
};

export function AnalysisResultCardLayout(
  props: PropsWithChildren<Props>
): ReactElement {
  return (
    <Box marginBottom="25px">
      <Center
        h="40px"
        bg="primary.bg_lv1"
        color="black"
        borderRadius="30px"
        fontWeight="bold"
        fontSize="16px"
      >
        {props.title}
      </Center>

      <Stack spacing={0} margin="0% 4%">
        {props.children}
      </Stack>
    </Box>
  );
}
