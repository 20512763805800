import { ReactElement } from "react";
import { Box } from "@chakra-ui/react";
import { useModifiedTranslation } from "../../../hooks/useModifiedTranslation";

/*
 * ヘッダー表示（◯さん）
 */
export function HeaderUserName(props: { employee_name: string }): ReactElement {
  const t = useModifiedTranslation();
  return (
    <Box
      sx={{
        ".header_whole": {
          paddingTop: "2vh",
          width: "100%",
        },
        ".header_title": {
          fontSize: "16px",
          fontWeight: "500",
        },
        ".header_titleSub": {
          paddingLeft: "2px",
          fontSize: "11px",
          fontWeight: "500",
        },
        ".header_title_old": {
          fontSize: "2vw",
        },
      }}
    >
      <Box
        textAlign="center"
        mb={5}
        className="header_whole"
        fontSize="20px"
        color="text.main_text_lv1"
        marginBottom={2}
      >
        <span>
          {props.employee_name}
          {t("Graph.common.personTitle")}
        </span>
      </Box>
    </Box>
  );
}
