import { useToastMessage } from "./useToastMessage";
import { useCallback } from "react";
import { AudioRecorder } from "../utils/audioRecorder/audioRecorder";
import { CheckVolumeResult } from "../types";
import { detectedVolumeByPhraseVisualizerAtom } from "../store";
import { useAtom } from "jotai";
import { convertInt16ToFloat32, resampleAudio } from "../utils/soxr";
import { MI1_RESAMPLING_RATE } from "../constants";
import { useModifiedTranslation } from "./useModifiedTranslation";
import { volumeToMimosysVolume } from "../utils/calcVolume";

type CheckVolumeHook = (
  audioData: Float32Array,
  samplingRate: number,
  detectVolume: number
) => Promise<CheckVolumeResult>;

export function useCheckVolume(): CheckVolumeHook {
  const toastMessage = useToastMessage();
  const toastWarning = useToastMessage();
  const t = useModifiedTranslation();
  const [isDetectedVolumeByPhraseVisualizer] = useAtom(
    detectedVolumeByPhraseVisualizerAtom
  );

  return useCallback<CheckVolumeHook>(
    async (audioData, samplingRate, detectVolume) => {
      const isAllDataSilent = audioData.every((datum) => datum === 0);
      if (isAllDataSilent) {
        return "Error.allDataIsSilent";
      }

      if (!isDetectedVolumeByPhraseVisualizer) {
        toastMessage(t("Error.belowVolumeThreshold"));
        return "Error.belowVolumeThreshold";
      }

      const resampledAudioData = await resampleAudio(
        audioData,
        samplingRate,
        MI1_RESAMPLING_RATE
      );
      const resampledAudioFloat32 = convertInt16ToFloat32(resampledAudioData);
      const [isDetectedVoice] = AudioRecorder.detectVoiceV2(
        resampledAudioFloat32,
        MI1_RESAMPLING_RATE,
        volumeToMimosysVolume(detectVolume)
      );
      if (!isDetectedVoice) {
        toastMessage(t("Error.cannotDetectVoice"));
        return "Error.cannotDetectVoice";
      }

      const maxVolume = audioData.reduce(function (a, b) {
        return Math.max(a, b);
      }, -Infinity);
      if (maxVolume === 1) {
        toastWarning(t("Error.speakTooLoud"), "warning");
      }

      return "OK";
    },
    [isDetectedVolumeByPhraseVisualizer, t, toastMessage, toastWarning]
  );
}
