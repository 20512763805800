import dayjs from "dayjs";
import { ResultChartData, MimosysDateType } from "../stores/mimosysStore";

/**
 * mimosysの結果を週、月、年単位で抽出する処理一覧
 * @param date
 * @param mimosysData
 * @param weekDay
 */

export function weekDataExtractor(
  date: dayjs.Dayjs,
  mimosysData: MimosysDateType[],
  weekDay = 14
): ResultChartData[] {
  const output: ResultChartData[] = [];
  for (
    let target = dayjs(date).add(-weekDay, "d");
    target <= date;
    target = dayjs(target).add(1, "d")
  ) {
    const dayData = mimosysData.filter((value) =>
      dayjs(value.recorded_at).isSame(target, "day")
    );
    const dayRepresentativeValue = createAllDataArrayForChart(target, dayData);
    output.push(...dayRepresentativeValue);
  }
  return output;
}

export function monthDataExtractor(
  date: dayjs.Dayjs,
  mimosysData: MimosysDateType[]
): ResultChartData[] {
  const output: ResultChartData[] = [];
  for (
    let target = dayjs(date).startOf("month");
    target < dayjs(date).endOf("month");
    target = dayjs(target).add(1, "d")
  ) {
    const dayData = mimosysData.filter((value) =>
      dayjs(value.recorded_at).isSame(target, "day")
    );
    const dayRepresentativeValue = createAllDataArrayForChart(
      target,
      dayData,
      "month"
    );
    output.push(...dayRepresentativeValue);
  }
  return output;
}

export function yearDataExtractor(
  date: dayjs.Dayjs,
  mimosysData: MimosysDateType[]
): ResultChartData[] {
  const output: ResultChartData[] = [];

  for (
    let target = dayjs(date).startOf("year");
    target < dayjs(date).endOf("year");
    target = target.add(1, "month")
  ) {
    const monthData = mimosysData.filter((value) =>
      dayjs(value.recorded_at).isSame(target, "month")
    );
    const monthRepresentativeValue = createArrayForChart(
      target,
      monthData,
      "year"
    );
    output.push(...monthRepresentativeValue);
  }
  return output;
}

function createArrayForChart(
  date: dayjs.Dayjs,
  data: MimosysDateType[],
  datingType: string
): ResultChartData[] {
  const output: ResultChartData[] = [];
  if (data.length > 0) {
    const vitalityAve =
      data.reduce((total, next) => total + next.vitality, 0) / data.length;
    const mentalActivityAve =
      data.reduce((total, next) => total + next.mental_activity, 0) /
      data.length;

    const mi1_result_list = data
      .filter((n) => n.mi1_result !== undefined)
      .map((n) => n.mi1_result) as number[];
    const mi1_result =
      mi1_result_list.reduce((total, next) => total + next, 0) /
      mi1_result_list.length;

    output.push({
      date: date.unix() * 1000,
      vitality100: vitalityAve * 100,
      mental_activity100: mentalActivityAve * 100,
      mi1_result: mi1_result,
      datingType: datingType,
    });
  } else {
    output.push({ date: date.unix() * 1000 });
  }
  return output;
}

function createAllDataArrayForChart(
  date: dayjs.Dayjs,
  data: MimosysDateType[],
  datingType?: string
): ResultChartData[] {
  const output: ResultChartData[] = [];
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const d: ResultChartData = {
        date: dayjs(data[i].recorded_at).unix() * 1000,
        vitality100: data[i].vitality * 100,
        mi1_result: data[i].mi1_result,
        mi1_result_reliability: data[i].mi1_result_reliability,
      };
      //活量値は一日の最も遅い時間の値のみをプロットする
      if (i === data.length - 1) {
        d["mental_activity100"] = data[i].mental_activity * 100;
        d["mental_activity_reliability"] = data[i].mental_activity_reliability;
      }
      if (datingType) d["datingType"] = datingType;

      output.push(d);
    }
  } else {
    output.push({ date: date.unix() * 1000 });
  }
  return output;
}

export function floorToString(score: number): string {
  if (score === 0) {
    return "--";
  } else {
    return Math.floor(score * 100).toString();
  }
}
