import { IconButton, IconButtonProps, Image } from "@chakra-ui/react";

type Props = {
  imageSrc: string;
} & Omit<IconButtonProps, "icon">;

export function ImageButton({
  "aria-label": label,
  imageSrc,
  ...props
}: Props): JSX.Element {
  return (
    <IconButton
      aria-label={label}
      variant="image_btn"
      icon={<Image src={imageSrc} width="inherit" height="inherit" />}
      {...props}
    />
  );
}
