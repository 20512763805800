import { atom } from "jotai";
import {
  analysisStartedDateAtom,
  MimosysDataAtom,
} from "../../../stores/mimosysStore";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import {
  monthDataExtractor,
  weekDataExtractor,
  yearDataExtractor,
} from "../../../utils/mimosysDataExtracter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const now = dayjs();
export const resultBaseTimeAtom = atom(now);

export const resultTabSelectAtom = atom(0);

export const selectResultTabAtom = atom(null, (get, set, arg: number) => {
  set(resultTabSelectAtom, arg);
  const baseDateTime = get(resultBaseTimeAtom);
  const startedDate = get(analysisStartedDateAtom);
  if (arg === 0) {
    if (
      startedDate === null ||
      dayjs(baseDateTime).startOf("weeks").isAfter(dayjs(now).endOf("weeks"))
    ) {
      set(resultBaseTimeAtom, now);
    } else if (dayjs(baseDateTime).endOf("weeks").isBefore(startedDate)) {
      set(resultBaseTimeAtom, dayjs(startedDate).add(2, "weeks"));
    }
  }
});

export const handleTapIncrementAtom = atom(null, (get, set) => {
  switch (get(resultTabSelectAtom)) {
    case 0:
      set(modifyWeekAtom, true);
      break;
    case 1:
      set(modifyMonthAtom, true);
      break;
    case 2:
      set(modifyYearAtom, true);
      break;
    default:
  }
});

export const handleTapDecrementAtom = atom(null, (get, set) => {
  switch (get(resultTabSelectAtom)) {
    case 0:
      set(modifyWeekAtom, false);
      break;
    case 1:
      set(modifyMonthAtom, false);
      break;
    case 2:
      set(modifyYearAtom, false);
      break;
    default:
  }
});

export const displayStringDateAtom = atom((get) => {
  const startDay = dayjs(get(resultBaseTimeAtom)).add(-14, "days");
  const endDay = get(resultBaseTimeAtom);

  switch (get(resultTabSelectAtom)) {
    case 0:
      return [
        startDay.format("YYYY/M/D"),
        startDay.format("(ddd)"),
        endDay.format("M/D"),
        endDay.format("(ddd)"),
      ];
    case 1:
      return [endDay.format("YYYY/M")];
    case 2:
      return [endDay.format("YYYY")];
    default:
      return [];
  }
});

export const displayStringDateSmallAtom = atom((get) => {
  const startDay = dayjs(get(resultBaseTimeAtom)).add(-14, "days");
  const endDay = get(resultBaseTimeAtom);

  switch (get(resultTabSelectAtom)) {
    case 0:
      return [
        startDay.format("M/D"),
        startDay.format("(ddd)"),
        endDay.format("M/D"),
        endDay.format("(ddd)"),
      ];
    case 1:
      return [endDay.format("YYYY/M")];
    case 2:
      return [endDay.format("YYYY")];
    default:
      return [];
  }
});

// export const weekDataExistAtom = atom((get) => {});

export const weekDataAtom = atom((get) => {
  return weekDataExtractor(get(resultBaseTimeAtom), get(MimosysDataAtom));
});

export const monthDataAtom = atom((get) => {
  return monthDataExtractor(get(resultBaseTimeAtom), get(MimosysDataAtom));
});
export const yearDataAtom = atom((get) => {
  return yearDataExtractor(get(resultBaseTimeAtom), get(MimosysDataAtom));
});

const modifyWeekAtom = atom(null, (get, set, isIncrement: boolean) => {
  const baseDateTime = get(resultBaseTimeAtom);
  const movedDate = dayjs(baseDateTime).add(isIncrement ? 2 : -2, "weeks");
  const startedDate = get(analysisStartedDateAtom);
  const nowModifyDateDiff = dayjs(now).diff(baseDateTime, "d");
  const startMonthModifyDateDiff = dayjs(movedDate).diff(startedDate, "d");

  if (
    dayjs(movedDate).startOf("weeks").isBefore(now.clone().endOf("weeks")) &&
    dayjs(movedDate).endOf("weeks").isAfter(startedDate)
  ) {
    set(resultBaseTimeAtom, movedDate);
  } else if (
    startedDate === null ||
    (nowModifyDateDiff <= 14 &&
      nowModifyDateDiff > 0 &&
      movedDate.clone().endOf("weeks").isAfter(startedDate))
  ) {
    set(resultBaseTimeAtom, now);
  } else if (
    movedDate.clone().startOf("weeks").isBefore(now.clone().endOf("weeks")) &&
    startMonthModifyDateDiff <= 14 &&
    startMonthModifyDateDiff > 0
  ) {
    set(resultBaseTimeAtom, startedDate.clone().add(2, "weeks"));
  }
});

const modifyMonthAtom = atom(null, (get, set, isIncrement: boolean) => {
  const movedDate = get(resultBaseTimeAtom)
    .clone()
    .add(isIncrement ? 1 : -1, "month")
    .date(15);
  if (
    movedDate.isBefore(now.clone().endOf("month")) &&
    movedDate.isAfter(get(analysisStartedDateAtom)?.clone().startOf("month"))
  ) {
    set(resultBaseTimeAtom, movedDate);
  }
});

const modifyYearAtom = atom(null, (get, set, isIncrement: boolean) => {
  let movedDate = get(resultBaseTimeAtom)
    .clone()
    .add(isIncrement ? 1 : -1, "year")
    .date(15);
  const analysisStartedDate = get(analysisStartedDateAtom);
  if (
    analysisStartedDate !== null &&
    movedDate.isSameOrBefore(now.clone().endOf("year")) &&
    movedDate.isSameOrAfter(analysisStartedDate.clone().startOf("year"))
  ) {
    if (movedDate.isAfter(now.clone())) {
      movedDate = now.clone();
    } else if (movedDate.isBefore(analysisStartedDate)) {
      movedDate = analysisStartedDate.clone();
    }
    set(resultBaseTimeAtom, movedDate);
  }
});
