import { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Text,
  VStack,
  HStack,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { HeaderUserName } from "../components/atoms/CommonLayout/HeaderUserName";
import { Footer } from "../components/atoms/CommonLayout/Footer";
import dayjs from "dayjs";
import {
  employeeInfoAtom,
  outputStenScoreAtom,
  hasMi1ResultAtom,
  hasVitalityAtom,
  hasMentalActivityAtom,
  footerIndexAtom,
  detectVolumeRawAtom,
} from "../store";
import {
  ChartInfoType,
  ResultChartData,
  latestMentalActivityAtom,
  latestMentalActivityStringAtom,
  latestStenScoreAtom,
  latestVitalityAtom,
  latestVitalityStringAtom,
  latestMi1ResultReliabilityAtom,
  latestMentalActivityReliabilityAtom,
} from "../stores/mimosysStore";
import { useAtom } from "jotai";
import { LargeRadialChart } from "../components/atoms/ResultCommon/RadialChart";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  homeSplashDisplayAtom,
  homeWeekDataAtom,
  latestMentalActivityTrendAtom,
  latestVitalityTrendAtom,
  mentalActivitySdSetAtom,
  mi1NormalRange,
  recordingLastDatesAtom,
  scoreAverageAtom,
  vitalitySdSetAtom,
} from "../components/atoms/ResultHome/HomeAtoms";
import {
  cellSizeAtom,
  windowSizeAtom,
  useWindowSize,
} from "../hooks/useWindowSize";

import { Trend } from "../types";
import { HomeChartBody } from "../components/atoms/ResultHome/HomerChart";
import { DownTrendIcon, UpTrendIcon, NormalTrendIcon } from "../assets/Icons";

import { BsQuestionCircleFill } from "react-icons/bs";

import { KOKORO_IMAGE_WITH_SCORE_URL } from "../environments";
import {
  MentalActivityReferenceMarker,
  StenReferenceMarker,
} from "../components/atoms/ResultCommon/StenReferenceMarker";
import { Splash } from "../components/atoms/InnerPage/Splash";
import { Calibration } from "./calibration";
// import { CalibrationV2 } from "./calibrationV2";
import { setSVGFillColor } from "../utils/svgHelper";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";
import { AnalyzedDate } from "../components/atoms/AnalyzedDate";

/*
 * ログイン後 ホーム画面（＋解析結果） 過去１週分のグラフ 日付
 */
function displayDate(): string[] {
  const now = dayjs();
  const startDay = dayjs(now).add(-7, "days");
  const endDay = dayjs(now);
  return [
    startDay.format("M/D"),
    startDay.format("(ddd)"),
    endDay.format("M/D"),
    endDay.format("(ddd)"),
  ];
}

/*
 * レイアウトパーツ：「いい調子」「普段通り」「疲れ気味」
 */
function TrendIcon(props: { height: number; trend: Trend }): ReactElement {
  const size = props.height / 1.8;
  if (props.trend === Trend.Down) {
    // 「疲れ気味」の矢印
    return <DownTrendIcon height={size} width={size} />;
  } else if (props.trend === Trend.Normal) {
    // 「普段通り」の矢印
    return <NormalTrendIcon height={size} width={size} />;
  } else {
    // 「いい調子」の矢印
    return <UpTrendIcon height={size} width={size} />;
  }
}

/*
 * ホーム画面（＋解析結果） メーター表示の種別（こころ指数／心の活量値）判定
 */
function ScoreMain(props: {
  outputStenScore: boolean;
  cellSize: number;
}): ReactElement {
  if (props.outputStenScore) {
    return <Mi1Chart cellSize={props.cellSize} />;
  } else {
    return <MimosysChart cellSize={props.cellSize} />;
  }
}

/*
 * ホーム画面（＋解析結果） メーター表示（心の活量値）
 */
function MimosysChart(props: { cellSize: number }): ReactElement {
  const [latestVitality] = useAtom(latestVitalityAtom);
  const [latestVitalityString] = useAtom(latestVitalityStringAtom);
  const [latestMentalActivity] = useAtom(latestMentalActivityAtom);
  const [latestMentalActivityString] = useAtom(latestMentalActivityStringAtom);
  const [latestMentalActivityReliability] = useAtom(
    latestMentalActivityReliabilityAtom
  );
  const height = Math.min(240, props.cellSize * 5);

  const mentalActivityReliability = latestMentalActivityReliability !== false;

  const t = useModifiedTranslation();
  const theme = useTheme();
  const mentalActivityColor = theme.colors.mentalActivity["primary"];
  const vitalityColor = theme.colors.vitality["primary"];

  return (
    <>
      <Box
        sx={{
          ".carousel .control-dots .dot": {
            backgroundColor: "primary.theme_lv1",
            boxShadow: "none",
            width: "12px",
            height: "12px",
          },

          ".carousel-slider": {
            paddingTop: "5px",
            paddingBottom: "45px",
          },
        }}
      >
        <Carousel
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          selectedItem={1}
        >
          {/* 元気圧 */}
          <Box bg="primary.bg_lv1" h="full">
            <LargeRadialChart
              progress={latestVitality}
              barColor={vitalityColor}
              score={latestVitalityString}
              title={t("Graph.common.vitality")}
              height={height * 1.2}
            />
          </Box>
          {/* 心の活量値 */}
          <Box bg="primary.bg_lv1" h="full">
            <Box>
              {mentalActivityReliability || (
                <Box>
                  <MentalActivityReferenceMarker />
                </Box>
              )}
            </Box>
            <LargeRadialChart
              progress={latestMentalActivity}
              barColor={mentalActivityColor}
              score={latestMentalActivityString}
              title={t("Graph.common.mentalActivity")}
              height={height * 1.2}
            />
          </Box>
        </Carousel>
      </Box>
    </>
  );
}

/*
 * ホーム画面（＋解析結果） メーター表示（こころ指数）
 */
function Mi1Chart(props: { cellSize: number }): ReactElement {
  const [latestStenScore] = useAtom(latestStenScoreAtom);
  const [latestMi1ResultReliability] = useAtom(latestMi1ResultReliabilityAtom);

  const stenReliability = latestMi1ResultReliability !== false;

  // FIXME: 画像のサイズを正しく修正
  const width = Math.min(240 * 2, props.cellSize * 10);

  const theme = useTheme();

  return (
    <>
      <Box>
        {stenReliability || (
          <Box>
            <StenReferenceMarker />
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        width="100%"
        marginTop={stenReliability ? 6 : 2}
      >
        <ReactSVG
          src={
            KOKORO_IMAGE_WITH_SCORE_URL[
              latestStenScore === null ? 0 : latestStenScore
            ]
          }
          beforeInjection={(svg) => {
            const title = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "title"
            );
            title.textContent = "kokoro";
            svg.prepend(title);
            svg.setAttribute("width", `${width}`);
            setSVGFillColor(
              svg,
              ".cls-theme1",
              theme.colors.primary["theme_lv1"]
            );
            setSVGFillColor(
              svg,
              ".cls-theme2",
              theme.colors.primary["theme_lv2"]
            );
            setSVGFillColor(
              svg,
              ".cls-theme3",
              theme.colors.primary["theme_lv3"]
            );
            setSVGFillColor(
              svg,
              ".cls-text1",
              theme.colors.result["meter_text_lv1"]
            );
            setSVGFillColor(
              svg,
              ".cls-text2",
              theme.colors.result["meter_text_lv2"]
            );
            setSVGFillColor(
              svg,
              ".cls-needle",
              theme.colors.result["meter_needle"]
            );
            setSVGFillColor(
              svg,
              ".cls-mi1Result",
              theme.colors.text["main_text_lv1"]
            );
          }}
        />
      </Box>
    </>
  );
}

/*
 * ホーム画面（＋解析結果） 過去１週分のグラフ表示 項目
 */
function HomeChartResultBody(props: {
  chartInfo: ChartInfoType;
  mtSize: number;
}): ReactElement {
  const [hasMi1Result] = useAtom(hasMi1ResultAtom);
  const [hasMentalActivity] = useAtom(hasMentalActivityAtom);
  const [hasVitality] = useAtom(hasVitalityAtom);
  const t = useModifiedTranslation();

  const theme = useTheme();
  const mi1ResultColor = theme.colors.mi1Result["primary"];
  const mentalActivityColor = theme.colors.mentalActivity["primary"];
  const vitalityColor = theme.colors.vitality["primary"];

  return (
    <>
      {/* こころ指数 */}
      {props.chartInfo.outputStenScore ? (
        <ChartResultBody
          title={t("Graph.common.mi1Result")}
          score={props.chartInfo.mi1Ave}
          data={props.chartInfo.data}
          average={props.chartInfo.mi1Ave}
          sdTop={props.chartInfo.mi1NormalRangeTop}
          sdBottom={props.chartInfo.mi1NormalRangeBottom}
          dataKey="mi1_result"
          hasData={hasMi1Result}
          referenceAreaColor={mi1ResultColor}
          tickArea={[1, 10]}
          outputStenScore={props.chartInfo.outputStenScore}
          helpTitleText={t("Graph.tooltipBody.mi1Result")}
          helpChartText={t("Graph.tooltipBody.graph.section")}
        />
      ) : (
        <Box>
          {/* 元気圧 */}
          <ChartResultBody
            title={t("Graph.common.vitality")}
            score={props.chartInfo.vitalityAve}
            data={props.chartInfo.data}
            average={props.chartInfo.vitalityAve}
            sdTop={props.chartInfo.vitalitySdTop}
            sdBottom={props.chartInfo.vitalitySdBottom}
            dataKey="vitality100"
            hasData={hasMentalActivity}
            referenceAreaColor={vitalityColor}
            tickArea={[0, 100]}
            outputStenScore={props.chartInfo.outputStenScore}
            trend={props.chartInfo.latestVitalityTrend}
            helpTitleText={t("Graph.tooltipBody.vitality")}
            helpChartText={t("Graph.tooltipBody.graph.section")}
          />
          <Box m={3} />
          {/* 心の活量値 */}
          <ChartResultBody
            title={t("Graph.common.mentalActivity")}
            score={props.chartInfo.mentalActivityAve}
            data={props.chartInfo.data}
            average={props.chartInfo.mentalActivityAve}
            sdTop={props.chartInfo.mentalActivitySdTop}
            sdBottom={props.chartInfo.mentalActivitySdBottom}
            dataKey="mental_activity100"
            hasData={hasVitality}
            referenceAreaColor={mentalActivityColor}
            tickArea={[0, 100]}
            outputStenScore={props.chartInfo.outputStenScore}
            trend={props.chartInfo.latestMentalActivityTrend}
            helpTitleText={t("Graph.tooltipBody.mentalActivity")}
            helpChartText={t("Graph.tooltipBody.graph.day")}
          />
        </Box>
      )}
    </>
  );
}

/*
 * ホーム画面（＋解析結果） 過去１週分のグラフ表示 詳細 / 「？」押下時のツールチップ表示
 */
function ChartResultBody(props: {
  data: ResultChartData[];
  title: string;
  score: number;
  average: number;
  sdTop: number;
  sdBottom: number;
  dataKey: string;
  hasData: boolean;
  referenceAreaColor: string;
  tickArea: number[];
  outputStenScore: boolean;
  helpTitleText?: string;
  helpChartText?: string;
  trend?: Trend;
}): ReactElement {
  const [windowSize] = useAtom(windowSizeAtom);
  const maxHeight = windowSize.width > 568 ? 220 : 150;
  const maxWidth = 568;
  const displayDays = displayDate();
  const itemHeight = Math.min(windowSize.height * 0.18, maxHeight * 0.6);

  // 左側の「？」押下時のツールチップ表示 動作定義
  const {
    isOpen: isLeftOpen,
    onOpen: onLeftOpen,
    onClose: onLeftClose,
  } = useDisclosure();
  // 右側の「？」押下時のツールチップ表示 動作定義
  const {
    isOpen: isRightOpen,
    onOpen: onRightOpen,
    onClose: onRightClose,
  } = useDisclosure();

  const t = useModifiedTranslation();
  const [chartHeight, setChartHeight] = useState(100);
  useEffect(() => {
    const calcChartHeight = Math.min(windowSize.height * 0.18, maxHeight * 0.6);
    if (calcChartHeight <= 100) {
      setChartHeight(100);
    } else {
      setChartHeight(calcChartHeight);
    }
  }, [maxHeight, windowSize.height]);

  return (
    <>
      <Flex alignItems="center" justifyContent="center">
        <Box
          borderRadius={16}
          margin={0}
          boxShadow="md"
          rounded="xl"
          height={`${windowSize.height * 0.3}px`}
          minHeight="180px"
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          backgroundColor="common.base"
          padding="10px 10px 10px 10px"
          marginBottom="14px"
        >
          <Modal onClose={onLeftClose} isOpen={isLeftOpen} isCentered>
            {/* 左側の「？」押下時のツールチップ表示  */}
            <ModalOverlay />
            <ModalContent whiteSpace="unset" backgroundColor="common.base">
              <ModalCloseButton />
              <ModalHeader color="text.main_text_lv1">
                {props.title}
              </ModalHeader>
              <ModalBody padding="0px 25px 35px 25px">
                <Text
                  whiteSpace="pre-wrap"
                  fontWeight="100"
                  color="text.main_text_lv1"
                >
                  {props.helpTitleText}
                </Text>
              </ModalBody>
            </ModalContent>
          </Modal>

          <Modal onClose={onRightClose} isOpen={isRightOpen} isCentered>
            {/* 右側の「？」押下時のツールチップ表示  */}
            <ModalOverlay />
            <ModalContent whiteSpace="unset" backgroundColor="common.base">
              <ModalCloseButton />
              <ModalHeader color="text.main_text_lv1">
                {t("Graph.tooltipTitle.graph")}
              </ModalHeader>
              <ModalBody padding="0px 25px 35px 25px">
                <Text
                  whiteSpace="pre-wrap"
                  fontWeight="200"
                  color="text.main_text_lv1"
                >
                  {props.helpChartText}
                </Text>
              </ModalBody>
            </ModalContent>
          </Modal>

          <Box m={1.5} minHeight="150px">
            <Grid templateColumns="1fr">
              <Box m={1}>
                <Grid templateColumns="1fr auto" alignItems="center" gap={1}>
                  {/* 過去１週分のグラフ表示 上部【項目名、ツールチップ用のアイコン、日程の表示】 */}
                  <HStack>
                    <Box>
                      <Text fontWeight="600" fontSize="20px">
                        {props.title}
                      </Text>
                    </Box>
                    <Box onClick={onLeftOpen} fontSize="xs" paddingTop="4px">
                      <BsQuestionCircleFill color="text.main_text_lv1" />
                    </Box>
                  </HStack>
                  <HStack>
                    <Box display="flex" alignItems="center" fontWeight="500">
                      <Text
                        fontFamily="Oswald"
                        fontSize="lg"
                        letterSpacing={0.5}
                        mr={1}
                      >
                        {displayDays[0]}
                      </Text>
                      <Box display="flex" alignItems="flex-end">
                        <Text fontFamily="Oswald" fontSize="sm" marginTop="3px">
                          {displayDays[1]}
                        </Text>
                      </Box>
                      <Text fontFamily="Oswald" fontSize="sm" mx={1}>
                        -
                      </Text>
                      <Text
                        fontFamily="Oswald"
                        fontSize="lg"
                        letterSpacing={0.5}
                      >
                        {displayDays[2]}
                      </Text>
                      <Box display="flex" alignItems="flex-end">
                        <Text fontFamily="Oswald" fontSize="sm" marginTop="3px">
                          {displayDays[3]}
                        </Text>
                      </Box>
                    </Box>
                    <Box onClick={onRightOpen} fontSize="xs" paddingTop="4px">
                      <BsQuestionCircleFill color="text.main_text_lv1" />
                    </Box>
                  </HStack>
                </Grid>
              </Box>
              <Grid
                templateColumns={
                  !props.outputStenScore && props.trend !== undefined
                    ? "auto 1fr"
                    : "1fr"
                }
                gap={1}
                alignItems="center"
                height="70%"
                minHeight="120px"
                maxHeight={maxHeight * 0.7}
              >
                {/* 「いい調子」「普段通り」「疲れ気味」 */}
                {!props.outputStenScore && props.trend !== undefined && (
                  <Box
                    gridColumn="1 / span 1"
                    height={`${itemHeight}px`}
                    minHeight="100px"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <TrendIcon height={maxHeight * 0.9} trend={props.trend} />
                  </Box>
                )}
                <Box
                  gridColumn={
                    !props.outputStenScore && props.trend !== undefined
                      ? "2 / span 1"
                      : "1 / span 1"
                  }
                >
                  {/* 過去１週分のグラフ表示 */}
                  <HomeChartBody
                    data={props.data}
                    height={chartHeight}
                    width={windowSize.width}
                    reference={{
                      lineY: props.average,
                      areaTop: props.sdTop,
                      areaBottom: props.sdBottom,
                      areaColor: props.referenceAreaColor,
                    }}
                    tickArea={props.tickArea}
                    name={props.title}
                    dataKey={props.dataKey}
                    hasData={props.hasData}
                    outputStenScore={props.outputStenScore}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

/*
 * ホーム画面（＋解析結果） 全体表示
 */
function HomeBody(): ReactElement {
  const [_outputStenScore] = useAtom(outputStenScoreAtom);
  const [latestVitalityTrend] = useAtom(latestVitalityTrendAtom);
  const [latestMentalActivityTrend] = useAtom(latestMentalActivityTrendAtom);
  const [data] = useAtom(homeWeekDataAtom);
  const [[vitalityAve, mentalActivityAve, mi1Ave]] = useAtom(scoreAverageAtom);
  const [[vitalitySdTop, vitalitySdBottom]] = useAtom(vitalitySdSetAtom);
  const [[mentalActivitySdTop, mentalActivitySdBottom]] = useAtom(
    mentalActivitySdSetAtom
  );
  const [mi1NormalRangeTop, mi1NormalRangeBottom] = mi1NormalRange;
  const [recordingLastDates] = useAtom(recordingLastDatesAtom);
  const outputStenScore = _outputStenScore ? _outputStenScore : false;
  const [cellSize] = useAtom(cellSizeAtom);
  const chartInfo: ChartInfoType = {
    data: data,

    latestVitalityTrend: latestVitalityTrend,
    vitalityAve: vitalityAve,
    vitalitySdTop: vitalitySdTop,
    vitalitySdBottom: vitalitySdBottom,

    latestMentalActivityTrend: latestMentalActivityTrend,
    mentalActivityAve: mentalActivityAve,
    mentalActivitySdTop: mentalActivitySdTop,
    mentalActivitySdBottom: mentalActivitySdBottom,

    mi1Ave: mi1Ave,
    mi1NormalRangeTop: mi1NormalRangeTop,
    mi1NormalRangeBottom: mi1NormalRangeBottom,

    recordingLastDates: recordingLastDates,
    outputStenScore: outputStenScore,
  };

  return <VerticalHomeBody chartInfo={chartInfo} cellSize={cellSize} />;
}

/*
 * ホーム画面（＋解析結果） 全体表示
 */
export function Home(): ReactElement {
  useWindowSize();
  const [employeeInfo] = useAtom(employeeInfoAtom);
  const [, setFooterIndex] = useAtom(footerIndexAtom);
  const [homeSplashDisplay] = useAtom(homeSplashDisplayAtom);
  const [detectVolumeRaw] = useAtom(detectVolumeRawAtom);

  useEffect(() => {
    setFooterIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  // NOTE: こころ指数画像のプリロード、もっと良い方法があるかも
  // FIXME: スプラッシュ画面は、ホームに遷移してから表示すべき
  const [outputStenScore] = useAtom(outputStenScoreAtom);
  const [loaded, setLoaded] = useState(!outputStenScore);
  const [latestStenScore] = useAtom(latestStenScoreAtom);

  useEffect(() => {
    if (!homeSplashDisplay && !loaded) {
      const kokoroImageUrl =
        KOKORO_IMAGE_WITH_SCORE_URL[
          latestStenScore === null ? 0 : latestStenScore
        ];

      const img = new Image();
      img.src = kokoroImageUrl;
      img.onload = () => {
        setLoaded(true);
      };
    }
  }, [homeSplashDisplay, loaded, latestStenScore]);

  console.log("detectVolumeRaw", detectVolumeRaw);
  if (homeSplashDisplay || !loaded) {
    return <Splash />;
  } else {
    if (isNaN(detectVolumeRaw)) {
      return <Calibration backButton={false} initialSetup />;
      // return <CalibrationV2 backButton={false} initialSetup />;
    } else {
      return (
        <>
          <Box>
            <HeaderUserName employee_name={employeeInfo.employee_name} />
            <HomeBody />
            <Box height="150px">&nbsp;</Box>
          </Box>
          <Footer />
        </>
      );
    }
  }
}

/*
 * ホーム画面（＋解析結果） 全体表示 縦長版
 */
function VerticalHomeBody(props: {
  chartInfo: ChartInfoType;
  cellSize: number;
}): ReactElement {
  return (
    <>
      <Center margin="10px">
        <VStack>
          <Box alignItems="center" justifyContent="center" width="100%">
            <AnalyzedDate heightTodayWeekDay="65%" />
            <Box>
              <ScoreMain
                outputStenScore={props.chartInfo.outputStenScore}
                cellSize={props.cellSize}
              />
            </Box>
          </Box>

          <Box alignItems="center" justifyContent="center" width="100%">
            <HomeChartResultBody chartInfo={props.chartInfo} mtSize={1} />
          </Box>
        </VStack>
      </Center>
    </>
  );
}
