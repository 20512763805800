import { theme_dark } from "./common_dark";

export const theme_pink_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#F16087", // テーマカラー1
    theme_lv2: "#F1A9BC", // テーマカラー2
    theme_lv3: "#F1D6DE", // テーマカラー3
    accent: "#EB4B33", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#211300", // 背景色1
  },
};
