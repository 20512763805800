import axios from "axios";
import { TOKEN_INFO_SESSION_STORAGE_KEY } from "../constants";
import { BASE_URL } from "../environments";
import { checkWafResponse } from "./checkWafResponse";

export const appAxios = axios.create({ baseURL: BASE_URL });

appAxios.interceptors.request.use(async (config) => {
  if (config.url) {
    const status = await checkWafResponse(config.url);
    if (status === 503) {
      window.location.reload();
      return Promise.reject("WAFによりアクセスが制限されました。");
    }
  }
  const tokenInfoStr = sessionStorage.getItem(TOKEN_INFO_SESSION_STORAGE_KEY);
  const tokenInfo = JSON.parse(tokenInfoStr || "null");
  if (tokenInfo) {
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${tokenInfo.access_token}`,
        ...config.headers,
      },
    };
  } else {
    return config;
  }
});
