import { useAtom } from "jotai";
import { ReactElement } from "react";
import { latestDateAtom } from "../../stores/mimosysStore";
import { Box, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";

function displayTodayWeekDay(): string {
  const now = dayjs();
  return `${now.format("(ddd)")}`;
}

export function AnalyzedDate(props: {
  heightTodayWeekDay: string;
}): ReactElement {
  const [latestDate] = useAtom(latestDateAtom);
  if (latestDate) {
    return (
      <Flex
        fontSize="32px"
        justifyContent="center"
        alignItems="end"
        fontFamily="Oswald"
        fontWeight="500"
        marginBottom={6}
      >
        <Text textAlign="end" fontSize="32px">
          {latestDate.format("YYYY/M/D")}&nbsp;
        </Text>
        <Text textAlign="end" fontSize="24px" marginBottom="3px">
          {latestDate.format("HH:mm:ss")}
        </Text>
      </Flex>
    );
  } else {
    return (
      <Box>
        <Flex align="center" justifyContent="center" alignItems="center">
          <Text
            as="h4"
            textAlign="center"
            fontFamily="Oswald"
            letterSpacing={2}
            fontSize="32px"
            fontWeight="400"
          >
            {dayjs().format("YYYY/M/D")}
          </Text>
          <Box mr={1} />
          <Box
            fontFamily="Oswald"
            fontSize="20px"
            fontWeight="400"
            letterSpacing={2}
            height={props.heightTodayWeekDay}
            display="flex"
            marginTop="8px"
            alignItems="flex-end"
          >
            <Text>{displayTodayWeekDay()}</Text>
          </Box>
        </Flex>
      </Box>
    );
  }
}
