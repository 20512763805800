import { theme_dark } from "./common_dark";

export const theme_brown_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#B29D9C", // テーマカラー1
    theme_lv2: "#D6CDBF", // テーマカラー2
    theme_lv3: "#E7E1D5", // テーマカラー3
    accent: "#988282", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#2B2625", // 背景色1
  },
};
