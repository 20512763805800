import { ReactElement, useEffect } from "react";
import { decodeJwt, decodeJwtHeader } from "../utils/decodeJwt";
import { Splash } from "../components/atoms/InnerPage/Splash";
import { useAtom } from "jotai";
import {
  accessTokenAtom,
  accessTokenInfoAtom,
  companyAccessTokenAtom,
  queryLoginDataParamAtom,
  recTypeAtom,
  showLoginAlertDialogAtom,
} from "../store";
import { useSetSetting } from "../utils/setting";
import { useSaveTokenToStorage } from "../utils/token";

/*
 * クエリログイン
 */
export function QueryLogin(): ReactElement {
  const [company_token] = useAtom(companyAccessTokenAtom);
  // console.log("inQueryLoginRoot");
  const [, setLoginData] = useAtom(queryLoginDataParamAtom);
  const [, setAccessToken] = useAtom(accessTokenAtom);
  const [, setRecType] = useAtom(recTypeAtom);
  const [showDialog] = useAtom(showLoginAlertDialogAtom);
  const [accessTokenInfo] = useAtom(accessTokenInfoAtom);
  const setSettingInfo = useSetSetting();
  const saveToken = useSaveTokenToStorage();

  useEffect(() => {
    if (company_token) {
      let companyId, employeeId, recType;
      let objCompanyAccessTokenInfo;
      let companyAccessToken;
      let companyExpiresIn;
      let decodedHeader;

      try {
        objCompanyAccessTokenInfo = JSON.parse(company_token);
        companyAccessToken = objCompanyAccessTokenInfo.access_token;
        companyExpiresIn = objCompanyAccessTokenInfo.expires_in;
        decodedHeader = decodeJwtHeader(companyAccessToken);
      } catch (err) {
        console.log("decode error.");
      }

      if (decodedHeader && decodedHeader.typ === "JWT") {
        const decoded = decodeJwt(companyAccessToken);
        // console.log(decoded);
        if (decoded) {
          companyId = decoded.companyEmail;
          employeeId = decoded.employeeEmail;
          recType = decoded.recType;
        }
      }
      if (!companyId || !employeeId) {
        console.error("company_tokenが異常な形式です");
      }

      const queryLoginUserData = {
        companyId,
        companyAccessToken,
        employeeId,
        recType,
      };

      const accessToken = {
        access_token: companyAccessToken,
        expires_in: companyExpiresIn,
      };
      // ここからログイン処理実装
      if (queryLoginUserData) {
        setLoginData(queryLoginUserData);
        if (queryLoginUserData?.recType) {
          setRecType(queryLoginUserData.recType);
        }
        if (`companyAccessToken` in queryLoginUserData) {
          setAccessToken(queryLoginUserData.companyAccessToken);
          saveToken(accessToken);
          if (!showDialog && queryLoginUserData?.employeeId) {
            setSettingInfo(queryLoginUserData.employeeId);
          }
        }
      }
    }
  }, [
    saveToken,
    setSettingInfo,
    company_token,
    setAccessToken,
    showDialog,
    setLoginData,
    setRecType,
  ]);

  return <>{accessTokenInfo && <Splash />}</>;
}
