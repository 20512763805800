import { theme_dark } from "./common_dark";

export const theme_yellow_green_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#DADF00", // テーマカラー1
    theme_lv2: "#EAEC7F", // テーマカラー2
    theme_lv3: "#F7F9DA", // テーマカラー3
    accent: "#9CC813", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#3C4941", // 背景色1
  },
};
