import { ReactElement } from "react";
import {
  Box,
  Text,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { BsQuestionCircleFill } from "react-icons/bs";
import { useModifiedTranslation } from "../../../hooks/useModifiedTranslation";

export function StenReferenceMarker(): ReactElement {
  return ReferenceMarker("Graph.tooltipBody.reference.sten");
}

export function MentalActivityReferenceMarker(): ReactElement {
  return ReferenceMarker("Graph.tooltipBody.reference.mentalActivity");
}

/*
 * 「参考値」のツールチップ表示
 */
function ReferenceMarker(tooltipBody: string): ReactElement {
  // 左側の「？」押下時のツールチップ表示 動作定義
  const {
    isOpen: isLeftOpen,
    onOpen: onLeftOpen,
    onClose: onLeftClose,
  } = useDisclosure();

  const t = useModifiedTranslation();
  const theme = useTheme();
  const primaryColor = theme.colors.primary["theme_lv1"];

  return (
    <>
      <Modal onClose={onLeftClose} isOpen={isLeftOpen} isCentered>
        {/* 左側の「？」押下時のツールチップ表示  */}
        <ModalOverlay />
        <ModalContent whiteSpace="unset" backgroundColor="common.base">
          <ModalCloseButton />
          <ModalHeader color="text.main_text_lv1">
            {t("Graph.tooltipTitle.reference")}
          </ModalHeader>
          <ModalBody padding="0px 25px 35px 25px">
            <Text
              whiteSpace="pre-wrap"
              fontWeight="100"
              color="text.main_text_lv1"
            >
              {t(tooltipBody)}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Text variant="body2">
        <HStack>
          <Box
            marginTop="0px"
            width="10%"
            marginLeft="0px"
            fontSize="9px"
            fontWeight="bold"
            color="white"
            minWidth="50px"
            borderRadius="10px"
            border={`3px solid ${primaryColor}`}
            bg={primaryColor}
            textAlign="center"
          >
            {t("Graph.common.reference")}
          </Box>
          <Box onClick={onLeftOpen} fontSize="xs" paddingTop="4px">
            <BsQuestionCircleFill color="text.main_text_lv1" />
          </Box>
        </HStack>
      </Text>
    </>
  );
}
