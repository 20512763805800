import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { audioRecorder, detectVolumeAtom, detectVolumeRawAtom } from "../store";
import { useToastMessage } from "../hooks/useToastMessage";
import { Layout } from "../components/atoms/Layout";
import { ErrorDialog } from "../components/molecules/ErrorDialog";
import { RecordingButton } from "../components/molecules/RecordingButton";
import { PhraseBarSoundVisualizer } from "../components/molecules/PhraseBarSoundVisualizer";
import {
  RecordingState,
  InitializingState,
  ReadyState,
  SoundVisualizerRef,
  RecordedData,
} from "../types";
import { convertInt16ToFloat32, resampleAudio } from "../utils/soxr";
import { AudioVolumeGraph } from "../components/molecules/AudioVolumeGraph";
import { AudioVolumeGraphForDevelop } from "../components/molecules/AudioVolumeGraphForDevelop";
import { AudioRecorder } from "../utils/audioRecorder/audioRecorder";
import { AudioPlayer } from "../components/molecules/AudioPlayer";
import i18n from "../i18n";
import { useNavigate } from "react-router-dom";
import {
  CALIBRATION_MIN_LOG_POWER,
  DEFAULT_DETECT_VOLUME,
  MI1_RESAMPLING_RATE,
} from "../constants";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";
import { BsQuestionCircleFill } from "react-icons/bs";
import OK_SAMPLE_IMG from "../assets/images/calibration/OK.png";
import NG_SAMPLE_IMG from "../assets/images/calibration/NG.png";
import { VerticalControlBar } from "../components/atoms/VerticalControlBar";
import {
  decibelToVolume,
  mimosysVolumeToVolume,
  volumeToDecibel,
  volumeToMimosysVolume,
} from "../utils/calcVolume";
import { DebugAudioPlayer } from "../components/atoms/DebugAudioPlayer";
import { DEBUG_AUDIO_PLAYER } from "../environments";
import { RecordingFileButton } from "../components/molecules/RecordingFileButton";
import { autoVad } from "../utils/autoVad";

type StopRecordingFunc = () => Promise<Int16Array | null>;

export const isDebug: boolean = import.meta.env.DEV || DEBUG_AUDIO_PLAYER;

function ShowSettingExample(): ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useModifiedTranslation();
  function SettingExampleBox({
    title,
    imgUrl,
  }: {
    title: string;
    imgUrl: string;
  }): ReactElement {
    return (
      <Flex direction="column">
        <Box
          borderColor="primary.theme_lv1"
          borderWidth={2}
          bg="primary.theme_lv3"
        >
          <Box
            borderColor="primary.theme_lv1"
            borderBottomWidth={2}
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="primary.theme_lv1" paddingY="2">
              {title}
            </Text>
          </Box>
          <Box>
            <Image src={imgUrl} />
          </Box>
        </Box>
      </Flex>
    );
  }
  return (
    <>
      <Box onClick={onOpen} fontSize="xs" paddingTop="4px">
        <BsQuestionCircleFill color="text.main_text_lv1" />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent whiteSpace="unset" backgroundColor="common.base">
          <ModalCloseButton />
          <ModalHeader />
          <ModalBody padding="20px 25px 35px 20px">
            <VStack>
              <SettingExampleBox
                title={t("Calibration2.settingExample.Good")}
                imgUrl={OK_SAMPLE_IMG}
              />
              <SettingExampleBox
                title={t("Calibration2.settingExample.Bad")}
                imgUrl={NG_SAMPLE_IMG}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export function CalibrationV2(props: {
  backButton: boolean;
  initialSetup?: boolean;
}): ReactElement {
  const t = useModifiedTranslation();
  const toastMessage = useToastMessage();
  const navigate = useNavigate();
  const [isRecorded, setIsRecorded] = useState(false);
  const [initializingState, setInitializingState] =
    useState<InitializingState>("UNINITIALIZED");
  const [detectVolumeForMimosys] = useAtom(detectVolumeAtom);
  const [detectVolume] = useState(
    mimosysVolumeToVolume(detectVolumeForMimosys)
  );
  const [, setDetectVolume] = useAtom(detectVolumeRawAtom);
  const [logDetectVolume, setLogDetectVolume] = useState(
    volumeToDecibel(detectVolume)
  );
  const [tempDetectVolumeForMimosys, setTempDetectVolumeForMimosys] = useState(
    detectVolumeForMimosys
  );
  useEffect(() => {
    const detectVolume = decibelToVolume(logDetectVolume);
    setTempDetectVolumeForMimosys(volumeToMimosysVolume(detectVolume));
    if (isDebug) {
      console.log("detectVolume", volumeToMimosysVolume(detectVolume));
    }
  }, [logDetectVolume]);
  const [readyState, setReadyState] = useState<ReadyState>("UNINITIALIZED");
  const [stopRecording, setStopRecording] = useState<
    null | "PREPARING" | "STOPPING" | StopRecordingFunc
  >(null);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");

  const visualizerRef = useRef<SoundVisualizerRef>(null);

  const [audioData, setAudioData] = useState<Int16Array>(new Int16Array(0));
  const [recordedData, setRecordedData] = useState<RecordedData[]>([]);
  const [maxVolumeListState, setMaxVolumeList] = useState<number[]>([]);
  const [voiceDetectionSegmentListState, setVoiceDetectionSegmentList] =
    useState<number[][]>([]);

  const [maxVolumeListWithVadResult, setMaxVolumeListWithVadResult] = useState<
    { value: number; class: number }[]
  >([]);
  const [voiceDetectionSegmentByAutoVad, setVoiceDetectionSegmentByAutoVad] =
    useState<number[][]>([]);
  const startRecording = useCallback(async () => {
    if (!audioRecorder) return;
    setStopRecording("PREPARING");
    const updatingCallback = (
      averagePower: number,
      maxPower: number,
      detectVolumeFlag: boolean
    ): void => {
      if (visualizerRef.current) {
        visualizerRef.current.draw(
          averagePower,
          1 === maxPower,
          detectVolumeFlag
        );
      }
    };
    await audioRecorder.startRecording(
      updatingCallback,
      false,
      tempDetectVolumeForMimosys
    );
    const stop: StopRecordingFunc = async () => {
      setStopRecording("STOPPING");
      const samplingRate = audioRecorder.samplingRate;
      const audioData = await audioRecorder.stopRecording();
      let resampledAudioData = new Int16Array(0);
      if (samplingRate && audioData) {
        resampledAudioData = await resampleAudio(
          audioData,
          samplingRate,
          MI1_RESAMPLING_RATE
        );
        setAudioData(resampledAudioData);
        if (isDebug) {
          const recordedData: RecordedData = {
            data: audioData,
            samplingRate: samplingRate,
            phrase: "calibration",
            isSkipped: false,
          };
          setRecordedData([recordedData]);
        }
      }
      setStopRecording(null);
      setIsRecorded(true);
      return resampledAudioData;
    };
    setStopRecording(() => stop);
  }, [tempDetectVolumeForMimosys]);

  useEffect(() => {
    const audioFloat32 = convertInt16ToFloat32(audioData);
    const [, maxVolumeList, voiceDetectionSegmentList] =
      AudioRecorder.detectVoiceV2(
        audioFloat32,
        MI1_RESAMPLING_RATE,
        tempDetectVolumeForMimosys,
        undefined,
        undefined,
        true
      );
    setMaxVolumeList(maxVolumeList);
    for (let i = 0; i < voiceDetectionSegmentList.length; i++) {
      // NOTE: 戻り値はフレームなので、10msec/フレームを掛ける
      const startTime = voiceDetectionSegmentList[i][0] * 10;
      const endTime = voiceDetectionSegmentList[i][1] * 10;
      const voiceDetectTime = endTime - startTime;
      if (isDebug) {
        console.log(
          `VoiceDetectedTime${
            i + 1
          }: ${voiceDetectTime}msec(${startTime} - ${endTime})`
        );
      }
    }
    setVoiceDetectionSegmentList(voiceDetectionSegmentList);
    if (maxVolumeList.length > 0) {
      audioRecorder.destroy().then();
    }
  }, [audioData, tempDetectVolumeForMimosys]);

  const errorDialogNavigatePath = props.initialSetup
    ? "../login"
    : "../setting";

  // 初期化処理
  useEffect(() => {
    if (readyState === "UNINITIALIZED") {
      setInitializingState("INITIALIZING");
      audioRecorder
        .init()
        .then(() => setInitializingState("INITIALIZED"))
        .catch((e) => {
          const messageKey =
            e.name === "NotAllowedError"
              ? "Error.notAllowToUseMicrophone"
              : "Error.toUseMicrophone";
          setErrorDialogMessage(messageKey);
        });
    }
  }, [readyState, t, toastMessage]);

  // 録音タイムアウト設定
  useEffect(() => {
    let timeoutId: number | null = null;
    if (typeof stopRecording === "function") {
      timeoutId = window.setTimeout(async () => {
        const res = await stopRecording();
        if (res !== null) {
          return null;
        }
      }, 5 * 1000);
    }
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [stopRecording]);

  let recordingState: RecordingState;
  if (initializingState !== "INITIALIZED") {
    recordingState = "INITIALIZING";
  } else if (stopRecording !== null) {
    if (stopRecording === "PREPARING") {
      recordingState = "PREPARING";
    } else if (stopRecording === "STOPPING") {
      recordingState = "STOPPING";
    } else {
      recordingState = "RECORDING";
    }
  } else {
    recordingState = "IDLE";
  }

  // クリーンアップ処理
  useEffect(() => {
    return () => {
      setReadyState("FINISHED");
      audioRecorder.destroy().then();
    };
  }, []);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  const [showBackButton, setShowBackButton] = useState(false);
  useEffect(() => {
    if (stopRecording === null) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  }, [stopRecording]);

  const resetSliderValue = useCallback(() => {
    const defaultDetectVolume = mimosysVolumeToVolume(DEFAULT_DETECT_VOLUME);
    const defaultLogDetectVolume = volumeToDecibel(defaultDetectVolume);
    setLogDetectVolume(defaultLogDetectVolume);
  }, []);

  const autoSetThreshold = useCallback(() => {
    const {
      thresholdByAutoVad,
      maxVolumeListWithVadResult,
      voiceDetectionSegmentByAutoVad,
    } = autoVad(
      recordedData[0].data,
      recordedData[0].samplingRate,
      maxVolumeListState
    );
    // mimosysの値に変換する際に2**15倍されるが、実際使用したいのは12800倍した値
    const thresholdForMimosys = volumeToDecibel(
      (thresholdByAutoVad * 12800) / 2 ** 15
    );
    const threshold =
      thresholdForMimosys > CALIBRATION_MIN_LOG_POWER
        ? thresholdForMimosys
        : CALIBRATION_MIN_LOG_POWER;
    setLogDetectVolume(threshold);
    setMaxVolumeListWithVadResult(maxVolumeListWithVadResult);
    setVoiceDetectionSegmentByAutoVad([voiceDetectionSegmentByAutoVad]);
  }, [recordedData, maxVolumeListState]);

  const [playingSegment, setPlayingSegment] = useState(false);
  const handlePlayingSegment = (isSegment: boolean): void => {
    setPlayingSegment(isSegment);
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [activePlayer, setActivePlayer] = useState<null | string>(null);
  const handlePlayStateChange = (playing: boolean, mode: string): void => {
    setIsPlaying(playing);
    if (playing) {
      setActivePlayer(mode);
    } else if (activePlayer === mode) {
      setActivePlayer(null);
    }
  };
  const [currentPlayTime, setCurrentPlayTime] = useState(0);
  const [playbackPosition, setPlaybackPosition] = useState(0);
  useEffect(() => {
    let adjustedTime = currentPlayTime;
    if (playingSegment) {
      let totalSegmentTime = 0;
      for (let i = 0; i < voiceDetectionSegmentListState.length; i++) {
        const segment = voiceDetectionSegmentListState[i];
        // NOTE: 戻り値はフレームなので、10msec/フレームを掛ける
        const segmentStart = (segment[0] * 10) / 1000;
        const segmentEnd = (segment[1] * 10) / 1000;
        const segmentTime = segmentEnd - segmentStart;
        if (currentPlayTime <= totalSegmentTime + segmentTime) {
          if (i === 0) {
            adjustedTime = currentPlayTime + segmentStart;
          } else {
            adjustedTime = currentPlayTime - totalSegmentTime + segmentStart;
          }
          break;
        }
        // 1秒間の無音区間
        totalSegmentTime = totalSegmentTime + segmentTime + 1;
        if (currentPlayTime <= totalSegmentTime) {
          adjustedTime = segmentEnd;
          break;
        }
      }
    }
    if (!isPlaying) {
      adjustedTime = 0;
    }
    const calculatedPosition = (adjustedTime / 10) * 1000;
    setPlaybackPosition(calculatedPosition);
  }, [
    currentPlayTime,
    isPlaying,
    playingSegment,
    voiceDetectionSegmentListState,
  ]);

  const handleRecordingFileData = async (
    recordedData: RecordedData
  ): Promise<void> => {
    setRecordedData([recordedData]);
    const resampledAudioData = await resampleAudio(
      recordedData.data,
      recordedData.samplingRate,
      MI1_RESAMPLING_RATE
    );
    setAudioData(resampledAudioData);
    setIsRecorded(true);
  };

  return (
    <>
      {/* マイクNGダイアログ */}
      <ErrorDialog
        messageKey={errorDialogMessage}
        navigatePath={errorDialogNavigatePath}
      />
      <Layout
        height="100%"
        paddingY={{ base: 5, md: 12 }}
        display="flex"
        flexDirection="column"
      >
        <Layout.Title showBackButton={showBackButton} fontSize="24px">
          {t("Calibration2.title")}
        </Layout.Title>

        {!isRecorded && (
          <Flex direction="column">
            <Text whiteSpace="pre-wrap">{t("Calibration2.description")}</Text>

            <PhraseBarSoundVisualizer
              ref={visualizerRef}
              currentPhrase={{
                title: "",
                phrase: t("Calibration2.phrase"),
              }}
              showThreshold={false}
              isRecording={recordingState === "RECORDING"}
            />

            <RecordingButton
              recordingState={recordingState}
              onClick={
                typeof stopRecording === "function"
                  ? () => {
                      stopRecording().then((result) => {
                        if (result) {
                          return;
                        } else {
                          setErrorDialogMessage("Error.allDataIsSilent");
                        }
                      });
                    }
                  : startRecording
              }
            />

            {/* [開発環境のみ]ファイル選択機能 */}
            {import.meta.env.DEV && (
              <Flex mt={5} w="full">
                <RecordingFileButton onClick={handleRecordingFileData} />
              </Flex>
            )}
          </Flex>
        )}
        {isRecorded && (
          <>
            <Flex direction="column" gap={6}>
              <VStack spacing={2}>
                <Flex alignItems="center" w="full">
                  <Text mr={2}>{t("Calibration2.graphDescription")}</Text>
                  <ShowSettingExample />
                </Flex>
                <Flex justifyContent="space-between" w="full">
                  <Button
                    size="md"
                    px={4}
                    onClick={resetSliderValue}
                    variant={
                      isPlaying ? "btn_primary_non_active" : "btn_secondary"
                    }
                    rounded={8}
                    isDisabled={isPlaying}
                  >
                    {t("Calibration2.initialize")}
                  </Button>
                  <Button
                    size="md"
                    px={4}
                    onClick={autoSetThreshold}
                    variant={
                      isPlaying ? "btn_primary_non_active" : "btn_secondary"
                    }
                    rounded={8}
                    isDisabled={isPlaying}
                  >
                    {t("Calibration2.auto_setting")}
                  </Button>
                </Flex>
                <Box
                  w="full"
                  borderColor="primary.theme_lv1"
                  borderWidth={2}
                  rounded="xl"
                  padding={2}
                  bg="common.base"
                >
                  <AudioVolumeGraph
                    maxValue={12800}
                    valueList={maxVolumeListState.map((value) => value * 12800)}
                    thresholdValue={tempDetectVolumeForMimosys}
                    voiceDetectionSegmentList={voiceDetectionSegmentListState}
                    currentPlayBackPosition={playbackPosition}
                  />
                </Box>
                {/* FIXME: デバッグ用の為ベータでも不要 */}
                {isDebug && (
                  <Box
                    w="full"
                    borderColor="primary.theme_lv1"
                    borderWidth={2}
                    rounded="xl"
                    padding={2}
                    bg="common.base"
                  >
                    <AudioVolumeGraphForDevelop
                      maxVolumeListWithVadResult={maxVolumeListWithVadResult}
                      voiceDetectionSegment={voiceDetectionSegmentByAutoVad}
                    />
                  </Box>
                )}
              </VStack>
              <Box
                w="full"
                borderWidth={2}
                rounded="xl"
                borderColor="primary.theme_lv1"
                padding="10px 10px 30px 10px"
                bg="common.base"
              >
                <Stack spacing={2}>
                  <Box textAlign="center">
                    <Text fontSize="lg">
                      {t("Calibration2.ThresholdSlider.Description")}
                    </Text>
                  </Box>
                  <Flex alignContent="center" justifyContent="center">
                    <Flex
                      direction="column"
                      alignContent="center"
                      justifyContent="center"
                      mr={{ base: 10, md: 20 }}
                    >
                      <VStack spacing={10}>
                        <AudioPlayer
                          audioData={audioData}
                          segmentList={voiceDetectionSegmentListState}
                          sampleRate={MI1_RESAMPLING_RATE}
                          mode="full"
                          buttonLabel={t("Calibration2.playSoundAll")}
                          isPlaying={isPlaying}
                          onButtonClick={() => handlePlayingSegment(false)}
                          onPlayStateChange={(playing) =>
                            handlePlayStateChange(playing, "full")
                          }
                          onTimeUpdate={(currentTime) => {
                            if (activePlayer === "full") {
                              setCurrentPlayTime(currentTime);
                            }
                          }}
                        />
                        <AudioPlayer
                          audioData={audioData}
                          segmentList={voiceDetectionSegmentListState}
                          sampleRate={MI1_RESAMPLING_RATE}
                          mode="segments"
                          buttonLabel={t("Calibration2.playSoundSegments")}
                          isPlaying={isPlaying}
                          onButtonClick={() => handlePlayingSegment(true)}
                          onPlayStateChange={(playing) =>
                            handlePlayStateChange(playing, "segments")
                          }
                          onTimeUpdate={(currentTime) => {
                            if (activePlayer === "segments") {
                              setCurrentPlayTime(currentTime);
                            }
                          }}
                        />
                      </VStack>
                    </Flex>
                    <VerticalControlBar
                      initialValue={logDetectVolume}
                      isActive={!isPlaying}
                      onHeightChange={(val) => setLogDetectVolume(val)}
                    />
                  </Flex>
                </Stack>
              </Box>
              <Flex columnGap={2} mt={4}>
                <Button
                  variant="btn_primary"
                  width="full"
                  size="lg"
                  onClick={() => {
                    navigate(`/${i18n.resolvedLanguage}/` + "setting");
                  }}
                >
                  {t("Calibration.cancel")}
                </Button>
                <Button
                  variant="btn_primary"
                  width="full"
                  size="lg"
                  onClick={() => {
                    const rawDetectVolume = decibelToVolume(logDetectVolume);
                    const rawDetectVolumeForMimosys =
                      volumeToMimosysVolume(rawDetectVolume);
                    setDetectVolume(rawDetectVolumeForMimosys);
                    navigate(`/${i18n.resolvedLanguage}/` + "setting");
                  }}
                >
                  {t("Calibration.set")}
                </Button>
              </Flex>
            </Flex>
            {isDebug && (
              <DebugAudioPlayer
                title={audioRecorder.audioWrapperType}
                allRecordedData={recordedData}
              />
            )}
          </>
        )}
      </Layout>
    </>
  );
}
