import { ReactElement, useEffect, useRef, useState } from "react";
import Measure from "react-measure";

import {
  DownTrendIcon,
  NormalTrendIcon,
  UpTrendIcon,
} from "../../../assets/Icons";
import { Trend } from "../../../types";
import { useTheme } from "@chakra-ui/react";

/**
 * 大ゲージ
 * ホーム画面などで使う
 * @param props
 * @constructor
 */
export function LargeRadialChart(props: {
  progress: number;
  barColor: string;
  height: string | number;
  width?: string | number;
  title: string;
  score: string;
  trend?: Trend;
}): ReactElement {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [[width, height], setComponentSize] = useState([0, 0]);
  // const [theme] = useAtom(themeAtom);
  const getContext = (): CanvasRenderingContext2D | null => {
    return canvasRef.current?.getContext("2d") || null;
  };

  const theme = useTheme();
  function clearCanvas(): void {
    const ctx = getContext();
    if (!ctx) return;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  function drawBar(): void {
    const ctx = getContext();
    if (!ctx) return;
    ctx.lineCap = "round";
    ctx.canvas.style.width = width + "px";
    ctx.canvas.style.height = height + "px";

    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    clearCanvas();
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight * 0.9;

    const radius =
      canvasHeight <= canvasWidth ? canvasHeight * 0.8 : canvasWidth * 0.4;
    const barWidth =
      canvasHeight <= canvasWidth ? canvasHeight / 12 : canvasWidth / 24;
    const fillColor = theme.colors.text["main_text_lv1"];
    drawGauge(
      ctx,
      centerX,
      centerY,
      radius,
      barWidth,
      props.barColor,
      props.progress
    );

    ctx.canvas.style.letterSpacing = `0px`;
    // メーター表示内の文字色はChakraのextendThemeが反映されないため、直指定
    ctx.fillStyle = fillColor;

    ctx.font = `500 ${canvasHeight / 12}pt noto sans jp`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    // 「心の活量値」「元気圧」のタイトル表示
    ctx.fillText(props.title, centerX, centerY - radius / 1.4);

    const moveLeft = props.trend != null ? radius / 5 : 0;
    ctx.font = `500 ${canvasHeight / 2.8}pt Oswald`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    // 「心の活量値」「元気圧」のスコア表示
    ctx.fillText(
      props.score.toString(),
      centerX - moveLeft,
      centerY - radius / 4
    );
    ctx.lineCap = "round";
  }

  useEffect(() => {
    drawBar();
  });

  /*
   * レイアウトパーツ：「いい調子」「普段通り」「疲れ気味」
   */
  function TrendIcon(): ReactElement {
    const size = height / 3.5;
    if (props.trend === Trend.Down) {
      // 「疲れ気味」の矢印
      return <DownTrendIcon height={size} width={size} />;
    } else if (props.trend === Trend.Normal) {
      // 「普段通り」の矢印
      return <NormalTrendIcon height={size} width={size} />;
    } else {
      // 「いい調子」の矢印
      return <UpTrendIcon height={size} width={size} />;
    }
  }

  return (
    <>
      <Measure
        bounds
        onResize={(contentRect) => {
          setComponentSize([
            contentRect?.bounds?.width ?? 0,
            contentRect?.bounds?.height ?? 0,
          ]);
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef} style={{ height: props.height, width: "100%" }}>
            <div
              style={{
                fontFamily: "Oswald",
                height: height,
                width: width,
                position: "absolute",
              }}
            >
              <canvas ref={canvasRef} height={height * 2} width={width * 2} />
            </div>
            {props.score !== "--" && props.trend != null && (
              <div
                style={{ height: height, width: width, position: "absolute" }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: height * 0.05,
                    marginLeft: width / 2 + height / 4.5, // サイズは基本縦で決めているため
                  }}
                >
                  <TrendIcon />
                </div>
              </div>
            )}
          </div>
        )}
      </Measure>
    </>
  );
}

function drawGauge(
  ctx: CanvasRenderingContext2D,
  centerX: number,
  centerY: number,
  radius: number,
  barWidth: number,
  color: string,
  progress: number
): void {
  const counterclockwise = false;

  const capCircleX = centerX + Math.cos(Math.PI * (1 + progress)) * radius;
  const capCircleY = centerY + Math.sin(Math.PI * (1 + progress)) * radius;

  ctx.moveTo(centerX - radius, centerY);
  ctx.lineWidth = barWidth;
  ctx.strokeStyle = `${color}99`;
  ctx.beginPath();
  ctx.arc(centerX, centerY, radius, Math.PI, 0, counterclockwise);
  ctx.stroke();
  ctx.moveTo(centerX + radius, centerY);
  ctx.strokeStyle = `${color}`;
  ctx.beginPath();
  ctx.arc(
    centerX,
    centerY,
    radius,
    Math.PI,
    Math.PI * (1 + progress),
    counterclockwise
  );
  ctx.stroke();

  ctx.fillStyle = "common.base";
  ctx.beginPath();
  ctx.arc(capCircleX, capCircleY, barWidth / 4, 0, Math.PI * 2, true);
  ctx.fill();
}
