export const setSVGFillColor = (
  svg: SVGSVGElement,
  selector: string,
  color: string
): void => {
  const elements = svg.querySelectorAll(selector) as NodeListOf<
    SVGStyleElement | SVGPathElement
  >;
  elements.forEach((element) => {
    element.style.fill = color;
  });
};

export const setSVGStrokeColor = (
  svg: SVGSVGElement,
  selector: string,
  color: string
): void => {
  const elements = svg.querySelectorAll(
    selector
  ) as NodeListOf<SVGStyleElement>;
  elements.forEach((element) => {
    element.style.stroke = color;
  });
};
