import { theme_light } from "./common_light";

export const theme_yellow_orange_light = {
  ...theme_light,
  primary: {
    theme_lv1: "#FCC800", // テーマカラー1
    theme_lv2: "#FEDD78", // テーマカラー2
    theme_lv3: "#FEF4D6", // テーマカラー3
    accent: "#ED6D00", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#FFFCF5", // 背景色1
  },
};
