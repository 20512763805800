import { theme_dark } from "./common_dark";

export const theme_yellow_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#C6B831", // テーマカラー1
    theme_lv2: "#F8EC6E", // テーマカラー2
    theme_lv3: "#F8F5D0", // テーマカラー3
    accent: "#A39208", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#2A3000", // 背景色1
  },
};
