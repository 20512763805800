import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useModifiedTranslation } from "../../hooks/useModifiedTranslation";
import { LoginErrorCode } from "../../types";

interface LoginErrorModalProps {
  isOpen: boolean;
  errorCode: LoginErrorCode | null;
  onClose: () => void;
}

export const LoginErrorModal = ({
  isOpen,
  errorCode,
  onClose,
}: LoginErrorModalProps): JSX.Element => {
  const t = useModifiedTranslation();
  let errorMessage = "";
  switch (errorCode) {
    case "INVALID_TOKEN":
      errorMessage = "Error.loginError.description";
      break;
    case "INVALID_GOOGLE_TOKEN":
      errorMessage = "Error.loginError.google_sso";
      break;
    case "ENGINE_DISABLED":
      errorMessage = "Error.loginError.engine_disabled";
      break;
    case "INVALID_TWOOCA_TOKEN":
      errorMessage = "Error.loginError.twooca_sso";
      break;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx="auto">
        <ModalHeader>{t("Error.loginError.title")}</ModalHeader>
        <ModalBody fontSize="lg" fontWeight="normal">
          {t(errorMessage)}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            {t("Modal.close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
