import { ReactElement } from "react";
import { Button, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Layout } from "../../components/atoms/Layout";
import { ENABLED_ENGINES } from "../../environments";
import { useAtom } from "jotai";
import { accessTokenInfoAtom } from "../../store";
import { decodeJwt } from "../../utils/decodeJwt";
import { useModifiedTranslation } from "../../hooks/useModifiedTranslation";

export function getSelectableEngineList(accessToken: string): string[] {
  const decodedAccessToken = decodeJwt(accessToken);
  const engine_types = decodedAccessToken?.engineTypes as object;
  const mi1_enable =
    ENABLED_ENGINES.includes("Mi1") &&
    ("all" in engine_types || "mi1" in engine_types);

  const ret = [] as string[];
  if (mi1_enable) ret.push("Mi1");

  return ret;
}

export function Engines(): ReactElement {
  const t = useModifiedTranslation();

  const [tokenInfo] = useAtom(accessTokenInfoAtom);
  if (tokenInfo === null) throw new Error("no token");

  const url_list = getSelectableEngineList(tokenInfo.access_token);

  return (
    <Layout h="full">
      <VStack justify="center" h="full" gap={12}>
        {url_list.includes("Mi1") && (
          <Button
            as={Link}
            to="../Mi1/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.Mi1")}
          </Button>
        )}
      </VStack>
    </Layout>
  );
}
