import { theme_dark } from "./common_dark";

export const theme_sakura_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#F3B3A6", // テーマカラー1
    theme_lv2: "#F9D9D2", // テーマカラー2
    theme_lv3: "#FCECE9", // テーマカラー3
    accent: "#F34824", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#23282F", // 背景色1
  },
};
