import { ReactElement, useEffect } from "react";
import { Heading, Box, useTheme } from "@chakra-ui/react";
import { Footer } from "../components/atoms/CommonLayout/Footer";
import { ResultBody } from "../components/atoms/ResultAnalysis/ResultBody";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
import { useAtom } from "jotai";
import { footerIndexAtom } from "../store";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";

/*
 * ログイン後 解析結果グラフ画面
 */
export function AnalysisResult(): ReactElement {
  const t = useModifiedTranslation();

  const [, setFooterIndex] = useAtom(footerIndexAtom);

  useEffect(() => {
    setFooterIndex(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <>
      <Box>
        <Heading
          as="h1"
          textAlign="center"
          mb={10}
          paddingTop="3vh"
          size="md"
          color="text.main_text_lv1"
        >
          {t("Graph.common.analysisResult")}
        </Heading>
        <ResultBody />
      </Box>
      <Footer />
    </>
  );
}
