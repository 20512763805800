import { theme_dark } from "./common_dark";

export const theme_blue_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#59ABC9", // テーマカラー1
    theme_lv2: "#8CCCE3", // テーマカラー2
    theme_lv3: "#B9E4F3", // テーマカラー3
    accent: "#2AC4B7", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#22395A", // 背景色1
  },
};
