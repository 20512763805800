import { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";

// 画面のサイズを取得してレスポンシブに利用

export interface LayoutSize {
  height: number;
  width: number;
}

export const layoutSizeAtom = atom<LayoutSize>({ height: 0, width: 0 });
export const windowSizeAtom = atom<LayoutSize>({ height: 0, width: 0 });
export const cellSizeAtom = atom<number>((get) => {
  const size = get(windowSizeAtom);
  return Math.min(size.width, 960) / 12;
});

export function useWindowSize(): number[] {
  const [size, setSize] = useState([0, 0]);
  const [, setWindowSize] = useAtom(windowSizeAtom);
  useEffect(() => {
    function updateSize(): void {
      setSize([window.innerWidth, window.innerHeight]);
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return size;
}
