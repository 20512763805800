import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { PhraseText, SoundVisualizerRef } from "../../types";
import { useAtom } from "jotai";
import {
  detectedVolumeByPhraseVisualizerAtom,
  detectVolumeAtom,
} from "../../store";
import { Box } from "@chakra-ui/react";
import { mimosysVolumeToVolume, volumeToDecibel } from "../../utils/calcVolume";
import { AudioVolumeGraph } from "../molecules/AudioVolumeGraph";

type Props = {
  maxValue: number;
  underThreshold: number;
  upperThreshold: number;
  height?: string;
  width?: string;
  maxWidth?: string;
  showThreshold?: boolean;
  isRecording?: boolean;
  currentPhrase?: PhraseText;
};

export const WaveSoundVisualizer = forwardRef(
  (props: Props, ref: Ref<SoundVisualizerRef | null>) => {
    const [volumeList, setVolumeList] = useState<number[]>(
      Array.from({ length: 100 }, () => 0)
    );
    const [isDetectedVolumeAtom, setIsDetectedVolume] = useAtom(
      detectedVolumeByPhraseVisualizerAtom
    );

    // ボリュームスレッショルド閾値
    const [detectVolumeForMimosys] = useAtom(detectVolumeAtom);
    const underlimit = volumeToDecibel(props.underThreshold);

    const updateVolume = useCallback(
      (rawData: number, isTooloud: boolean, isDetectedVolume: boolean) => {
        if (props.isRecording) {
          let volume = volumeToDecibel(rawData) - underlimit;
          volume = volume > 0 ? volume : 0;
          volumeList.push(volume);
          volumeList.shift();
          setVolumeList(volumeList.slice());
        }
        if (!isDetectedVolumeAtom) {
          setIsDetectedVolume(isDetectedVolume);
        }
      },
      [
        props.isRecording,
        isDetectedVolumeAtom,
        underlimit,
        volumeList,
        setIsDetectedVolume,
      ]
    );

    useImperativeHandle(ref, () => ({
      draw: (
        rawData: number,
        isTooLoud: boolean,
        isDetectedVolume: boolean
      ) => {
        updateVolume(rawData, isTooLoud, isDetectedVolume);
      },
    }));

    useEffect(() => {
      setVolumeList(Array.from({ length: 100 }, () => 0));
    }, [props.isRecording, props.currentPhrase]);

    const maxValue = volumeToDecibel(mimosysVolumeToVolume(12800)) - underlimit;
    const thresholdValue =
      volumeToDecibel(mimosysVolumeToVolume(detectVolumeForMimosys)) -
      underlimit;

    return (
      <Box
        height={props.height}
        width={props.width}
        borderColor="primary.theme_lv1"
        borderWidth={2}
        px={2}
        py={1}
        bg="common.base"
      >
        <AudioVolumeGraph
          maxValue={maxValue}
          valueList={volumeList}
          thresholdValue={thresholdValue}
          isRecording
        />
      </Box>
    );
  }
);
WaveSoundVisualizer.displayName = "WaveSoundVisualizer";
