import invariant from "tiny-invariant";
import baseBannerUrl from "/src/assets/images/base/vAnalysis_favicon01_512.png";

import kokoroNoDataUrl from "/src/assets/images/kokoro/scoreless/nodata.svg";
import kokoro01Url from "/src/assets/images/kokoro/scoreless/01.svg";
import kokoro02Url from "/src/assets/images/kokoro/scoreless/02.svg";
import kokoro03Url from "/src/assets/images/kokoro/scoreless/03.svg";
import kokoro04Url from "/src/assets/images/kokoro/scoreless/04.svg";
import kokoro05Url from "/src/assets/images/kokoro/scoreless/05.svg";
import kokoro06Url from "/src/assets/images/kokoro/scoreless/06.svg";
import kokoro07Url from "/src/assets/images/kokoro/scoreless/07.svg";
import kokoro08Url from "/src/assets/images/kokoro/scoreless/08.svg";
import kokoro09Url from "/src/assets/images/kokoro/scoreless/09.svg";
import kokoro10Url from "/src/assets/images/kokoro/scoreless/10.svg";

import kokoroScoreNoDataUrl from "/src/assets/images/kokoro/score/nodata.svg";
import kokoroScore01Url from "/src/assets/images/kokoro/score/001.svg";
import kokoroScore02Url from "/src/assets/images/kokoro/score/002.svg";
import kokoroScore03Url from "/src/assets/images/kokoro/score/003.svg";
import kokoroScore04Url from "/src/assets/images/kokoro/score/004.svg";
import kokoroScore05Url from "/src/assets/images/kokoro/score/005.svg";
import kokoroScore06Url from "/src/assets/images/kokoro/score/006.svg";
import kokoroScore07Url from "/src/assets/images/kokoro/score/007.svg";
import kokoroScore08Url from "/src/assets/images/kokoro/score/008.svg";
import kokoroScore09Url from "/src/assets/images/kokoro/score/009.svg";
import kokoroScore10Url from "/src/assets/images/kokoro/score/010.svg";

import {
  AnalysisEngineType,
  isValidAnalysisEngineType,
  TokenEngineType,
} from "./types";
function message(name: string, value: unknown): string {
  return `Environment Variable Error: VITE_${name}(${value}) is invalid!`;
}

const baseUrl = import.meta.env.VITE_BASE_API_URL;
invariant(typeof baseUrl === "string", message("BASE_API_URL", baseUrl));
export const BASE_URL = baseUrl;

const appName = import.meta.env.VITE_APP_NAME;
invariant(typeof appName === "string", message("APP_NAME", appName));
export const APP_NAME = appName;

const appType = import.meta.env.VITE_APP_TYPE;
invariant(typeof appType === "string", message("APP_TYPE", appType));
export const APP_TYPE = appType;

export const BANNER_IMAGE_URL = baseBannerUrl;

export const HIDE_LANGUAGE_SELECTION =
  import.meta.env.VITE_HIDE_LANGUAGE_SELECTION === "true";

const defaultLanguage = import.meta.env.VITE_DEFAULT_LANGUAGE;
export const DEFAULT_LANGUAGE =
  typeof defaultLanguage !== "string" || defaultLanguage === "unset"
    ? undefined
    : defaultLanguage;

export const KOKORO_IMAGE_URL = [
  kokoroNoDataUrl,
  kokoro01Url,
  kokoro02Url,
  kokoro03Url,
  kokoro04Url,
  kokoro05Url,
  kokoro06Url,
  kokoro07Url,
  kokoro08Url,
  kokoro09Url,
  kokoro10Url,
];

export const KOKORO_IMAGE_WITH_SCORE_URL = [
  kokoroScoreNoDataUrl,
  kokoroScore01Url,
  kokoroScore02Url,
  kokoroScore03Url,
  kokoroScore04Url,
  kokoroScore05Url,
  kokoroScore06Url,
  kokoroScore07Url,
  kokoroScore08Url,
  kokoroScore09Url,
  kokoroScore10Url,
];

export const SKIP_QUESTIONNAIRES =
  import.meta.env.VITE_SKIP_QUESTIONNAIRES === "true";

const analysisEngine = import.meta.env.VITE_ANALYSIS_ENGINE;
export const ANALYSIS_ENGINE =
  typeof analysisEngine !== "string" || analysisEngine === "selection"
    ? undefined
    : analysisEngine;

export const USE_ANALYSIS_ERROR_PAGE =
  import.meta.env.VITE_USE_ANALYSIS_ERROR_PAGE === "true";

const enabledEngines = import.meta.env.VITE_ENABLED_ENGINES;
invariant(
  typeof enabledEngines === "string",
  message("ENABLED_ENGINES", enabledEngines)
);
export const ENABLED_ENGINES: AnalysisEngineType[] = enabledEngines
  .split("&")
  .flatMap((engine) => (isValidAnalysisEngineType(engine) ? engine : []));

export const ENABLED_TOKEN_ENGINE_TYPES: TokenEngineType[] =
  ENABLED_ENGINES.flatMap((engine) => {
    switch (engine) {
      case "Mi1":
        return ["mi1"];
    }
  });

export const DEBUG_AUDIO_PLAYER =
  import.meta.env.VITE_DEBUG_AUDIO_PLAYER === "true";
