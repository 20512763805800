import { useToast, AlertStatus } from "@chakra-ui/react";
import { useCallback } from "react";
import axios from "axios";
import { AppApiError, isObject, isValidAppApiError } from "../types";
import { useModifiedTranslation } from "./useModifiedTranslation";

function getApiErrorMessage(
  error: AppApiError,
  fallbackMessage: string,
  t: (key: string) => string
): string {
  let message: string;
  if (isObject(error.detail)) {
    const tKey = `ApiError.${error.detail.type}`;
    message = t(tKey);
    if (message === tKey) {
      message = error.detail.msg || fallbackMessage;
    }
  } else if (Array.isArray(error.detail)) {
    const firstDetail = error.detail[0];
    if (firstDetail && isObject(firstDetail)) {
      message = firstDetail.msg || fallbackMessage;
    } else if (typeof firstDetail === "string") {
      message = firstDetail;
    } else {
      message = fallbackMessage;
    }
  } else {
    message = error.detail;
  }
  return message;
}

type ErrorToast = (e: unknown, toastStatus?: AlertStatus) => void;

export function useToastMessage(): ErrorToast {
  const toast = useToast();
  const t = useModifiedTranslation();

  return useCallback(
    (err: unknown, toastStatus = "error") => {
      let title: string;
      let message: string | undefined;
      if (axios.isAxiosError(err)) {
        // title
        const status = err.response?.status;
        if (status !== undefined) {
          const tKey = `ApiError.${status}`;
          const statusText = t(tKey);
          title = `${status}: ${statusText}`;
        } else {
          title = `${err.code}`;
        }

        // message
        const responseData = err.response?.data;
        if (isValidAppApiError(responseData)) {
          /*
           * パスワード変更（/account/password"）時はレスポンスのメッセージを明示的に返却
           */
          if (err.config.url === "/account/password") {
            switch (status) {
              case 400:
                message = t("ApiError.account_password.400");
                break;
              case 401:
                message = t("ApiError.account_password.401");
                break;
              case 403:
                message = t("ApiError.account_password.403");
                break;
              case 404:
                message = t("ApiError.account_password.404");
                break;
              case 422:
                message = t("ApiError.account_password.422");
                break;
              case 500:
                message = t("ApiError.account_password.500");
                break;
              case 504:
                message = t("ApiError.account_password.504");
                break;
              default:
                message = getApiErrorMessage(responseData, err.message, t);
                break;
            }
          } else {
            message = getApiErrorMessage(responseData, err.message, t);
          }
        } else {
          message = err.message;
        }
      } else if (err instanceof Error) {
        title = err.name;
        message = err.message;
      } else if (typeof err === "string") {
        title = err;
      } else {
        title = "Internal Error";
      }

      toast({
        status: toastStatus,
        containerStyle: { whiteSpace: "pre-wrap" },
        isClosable: true,
        duration: 1500,
        title: title,
        description: message,
      });
    },
    [toast, t]
  );
}
