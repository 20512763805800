import { decodeJwt } from "./decodeJwt";

function getEngineTypes(accessToken: string): object | null {
  const decodedAccessToken = decodeJwt(accessToken);
  if (decodedAccessToken) {
    return decodedAccessToken.engineTypes as object;
  } else {
    return null;
  }
}

// エンジン解析可否確認
export function checkEngineEnabled(accessToken: string): boolean {
  const engine_types = getEngineTypes(accessToken);
  if (engine_types) {
    return "all" in engine_types || "mi1" in engine_types;
  } else {
    return false;
  }
}
