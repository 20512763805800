import { RecordedData } from "../../types";
import { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import { resampleAudio } from "../../utils/soxr";
import { encodeWav } from "../../utils/wav";
import { MI1_RESAMPLING_RATE } from "../../constants";

export function DebugAudioPlayer(props: {
  allRecordedData: RecordedData[];
  title: string | undefined;
}): ReactElement {
  const [audioBlobs, setAudioBlobs] = useState<Blob[] | null>(null);
  const [shouldResample, setShouldResample] = useState(false);

  useEffect(() => {
    const fetchResampledAudio = async (): Promise<void> => {
      const blobs = await Promise.all(
        props.allRecordedData.map(async (RecordedData) => {
          const RecordedDataResampled = await resampleAudio(
            RecordedData.data,
            RecordedData.samplingRate,
            MI1_RESAMPLING_RATE
          );
          return encodeWav(RecordedDataResampled, MI1_RESAMPLING_RATE) as Blob;
        })
      );
      setAudioBlobs(blobs);
    };
    if (shouldResample) {
      fetchResampledAudio();
    } else {
      setAudioBlobs(null);
    }
  }, [props.allRecordedData, shouldResample]);

  return (
    <Popover placement="top-end">
      <PopoverTrigger>
        <Button position="fixed" bottom={5} right={5} color="common_text.dark">
          {props.title || "Debug"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Debug Information</PopoverHeader>
        <PopoverBody>
          <Button onClick={() => setShouldResample(true)}>Resample</Button>
          <VStack spacing={5} align="start" maxHeight="80vh" overflow="auto">
            {audioBlobs === null
              ? props.allRecordedData.map((data, dataIndex) => {
                  const wav = encodeWav(data.data, data.samplingRate) as Blob;
                  const phraseType =
                    data.co1VoiceType || data.dysphagiaVoiceTypes;
                  const typeText = phraseType ? `, ${phraseType}` : "";
                  const metaInfo = `${data.samplingRate}${typeText}`;
                  return (
                    <div key={dataIndex}>
                      <Text>
                        {dataIndex}: ({metaInfo})
                      </Text>
                      <Text whiteSpace="pre-wrap">{data.phrase}</Text>
                      <audio src={URL.createObjectURL(wav)} controls />
                    </div>
                  );
                })
              : audioBlobs.map((blob, dataIndex) => {
                  const data = props.allRecordedData[dataIndex];
                  const phraseType =
                    data.co1VoiceType || data.dysphagiaVoiceTypes;
                  const typeText = phraseType ? `, ${phraseType}` : "";
                  const metaInfo = `${data.samplingRate}${typeText}`;
                  return (
                    <div key={dataIndex}>
                      <Text>
                        {dataIndex}: ({metaInfo})
                      </Text>
                      <Text whiteSpace="pre-wrap">{data.phrase}</Text>
                      <audio src={URL.createObjectURL(blob)} controls />
                    </div>
                  );
                })}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
