import { ReactElement } from "react";
import { Heading, Image, VStack, Flex, Box } from "@chakra-ui/react";
import queryLoginResultUrl from "/src/assets/images/query_login_result/query_login_result_exit.png";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";

/*
 * クエリログイン時の音声録音解析完了画面
 */
export function QueryLoginResult(): ReactElement {
  const t = useModifiedTranslation();

  return (
    <>
      <Flex
        backgroundColor="primary.bg_lv1"
        justifyContent="center"
        alignItems="flex-start"
        width="100%"
        height="100%"
      >
        <VStack>
          <Heading
            backgroundColor="primary.bg_lv1"
            as="h1"
            textAlign="center"
            pb={10}
            paddingTop="3vh"
            size="md"
            color="text.main_text_lv1"
          >
            {t("QueryLogin.common.resultTitle")}
          </Heading>

          <Image
            src={queryLoginResultUrl}
            alt=""
            pb={10}
            paddingTop="10vh"
            mx="auto"
          />
          <Box
            lineHeight="2"
            border="1px"
            paddingLeft="40px"
            paddingRight="40px"
            borderRadius="20px"
            fontSize="lg"
            fontWeight="semibold"
            bg="white"
            borderColor="white"
            color="text.main_text_lv1"
          >
            {t("QueryLogin.common.resultBody")}
          </Box>
        </VStack>
      </Flex>
    </>
  );
}
