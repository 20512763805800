import { ReactElement, useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Button, Box, Text, Flex, useTheme } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { Layout } from "../../../../components/atoms/Layout";
import {
  useAssertEngineTypeFromPathParam,
  useGetAnalysisEngines,
} from "../../../../utils/selectAnalysisEngine";
import { outputStenScoreAtom } from "../../../../store";
import {
  latestStenScoreAtom,
  latestVitalityStringAtom,
  latestVitalityAtom,
  latestMentalActivityAtom,
  latestMentalActivityStringAtom,
  isGetMimosysDataAtom,
  latestMi1ResultReliabilityAtom,
  latestMentalActivityReliabilityAtom,
} from "../../../../stores/mimosysStore";
import { useAtom } from "jotai";
import { LargeRadialChart } from "../../../../components/atoms/ResultCommon/RadialChart";
import {
  latestMentalActivityTrendAtom,
  latestVitalityTrendAtom,
} from "../../../../components/atoms/ResultHome/HomeAtoms";
import { cellSizeAtom, useWindowSize } from "../../../../hooks/useWindowSize";

import { APP_TYPE, KOKORO_IMAGE_URL } from "../../../../environments";
import {
  MentalActivityReferenceMarker,
  StenReferenceMarker,
} from "../../../../components/atoms/ResultCommon/StenReferenceMarker";
import { setSVGFillColor } from "../../../../utils/svgHelper";
import { useModifiedTranslation } from "../../../../hooks/useModifiedTranslation";
import { PocResult } from "../../../../components/molecules/PocResult";

function MimosysChart(): ReactElement {
  useWindowSize();
  const [latestVitality] = useAtom(latestVitalityAtom);
  const [latestVitalityString] = useAtom(latestVitalityStringAtom);
  const [latestVitalityTrend] = useAtom(latestVitalityTrendAtom);
  const [latestMentalActivity] = useAtom(latestMentalActivityAtom);
  const [latestMentalActivityString] = useAtom(latestMentalActivityStringAtom);
  const [latestMentalActivityTrend] = useAtom(latestMentalActivityTrendAtom);
  const [latestMentalActivityReliability] = useAtom(
    latestMentalActivityReliabilityAtom
  );
  const mentalActivityReliability = latestMentalActivityReliability !== false;

  const [cellSize] = useAtom(cellSizeAtom);

  const height = Math.min(240, cellSize * 4);

  const t = useModifiedTranslation();

  const theme = useTheme();
  const vitalityColor = theme.colors.vitality["primary"];
  const mentalActivityColor = theme.colors.mentalActivity["primary"];

  return (
    <>
      <Flex p="6" w="full" maxW="100%">
        <Box
          bg="common.base"
          boxShadow="md"
          rounded="xl"
          paddingTop="3"
          width="100%"
        >
          {/* 元気圧 */}
          <LargeRadialChart
            progress={latestVitality}
            barColor={vitalityColor}
            score={latestVitalityString}
            title={t("Graph.common.vitality")}
            height={height * 1.2}
            trend={latestVitalityTrend}
          />
        </Box>
      </Flex>
      <Box p="6">
        {mentalActivityReliability || (
          <Box>
            <MentalActivityReferenceMarker />
          </Box>
        )}
        <Flex w="full" maxW="100%">
          <Box
            bg="common.base"
            boxShadow="md"
            rounded="xl"
            paddingTop="3"
            width="100%"
          >
            {/* 心の活量値 */}
            <LargeRadialChart
              progress={latestMentalActivity}
              barColor={mentalActivityColor}
              score={latestMentalActivityString}
              title={t("Graph.common.mentalActivity")}
              height={height * 1.2}
              trend={latestMentalActivityTrend}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
}

function Mi1Chart(): ReactElement {
  const [latestStenScore] = useAtom(latestStenScoreAtom) as number[];
  const [latestMi1ResultReliability] = useAtom(latestMi1ResultReliabilityAtom);
  const stenReliability = latestMi1ResultReliability !== false;
  const [cellSize] = useAtom(cellSizeAtom);

  const boxHeight = cellSize * 4;

  const t = useModifiedTranslation();
  const theme = useTheme();

  return (
    <>
      <Box>
        {stenReliability || (
          <Box>
            <StenReferenceMarker />
          </Box>
        )}
      </Box>

      <Box pb={10} paddingLeft="1%" width="98%">
        <ReactSVG
          src={KOKORO_IMAGE_URL[latestStenScore === null ? 0 : latestStenScore]}
          beforeInjection={(svg) => {
            const title = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "title"
            );
            title.textContent = "kokoro";
            svg.prepend(title);
            svg.setAttribute("width", "100%");
            svg.setAttribute("height", "100%");
            setSVGFillColor(
              svg,
              ".cls-theme1",
              theme.colors.primary["theme_lv1"]
            );
            setSVGFillColor(
              svg,
              ".cls-theme2",
              theme.colors.primary["theme_lv2"]
            );
            setSVGFillColor(
              svg,
              ".cls-theme3",
              theme.colors.primary["theme_lv3"]
            );
            setSVGFillColor(
              svg,
              ".cls-text1",
              theme.colors.result["meter_text_lv1"]
            );
            setSVGFillColor(
              svg,
              ".cls-text2",
              theme.colors.result["meter_text_lv2"]
            );
            setSVGFillColor(
              svg,
              ".cls-needle",
              theme.colors.result["meter_needle"]
            );
          }}
        />
      </Box>

      <Flex paddingBottom="6" w="full" maxW="100%">
        <Box
          bg="common.base"
          rounded="xl"
          p="0"
          width="100%"
          border="none"
          height={boxHeight}
          display="flex"
          justifyContent="space-around"
          alignItems="end"
          paddingTop="1px"
          paddingBottom={cellSize * 1.6 - 15}
        >
          <Box>
            {/* こころ指数 */}
            <Text marginTop="auto" fontSize={Math.max(24, cellSize * 0.6)}>
              {t("Graph.common.mi1Result")}
            </Text>
            {/* 個人最適化した10段階評価 */}
            <Text fontSize={Math.max(14, cellSize * 0.3)} fontWeight="400">
              {t("Graph.common.mi1ResultExplanation")}
            </Text>
          </Box>
          <Box marginLeft="40px">
            {/* こころ指数 */}
            <Text
              textAlign="center"
              marginTop="auto"
              fontSize={Math.max(24, cellSize * 0.6) * 1.5}
            >
              {latestStenScore}
            </Text>
            {/* 個人最適化した10段階評価 */}
            <Text
              textAlign="center"
              fontSize={Math.max(24, cellSize * 0.6) * 0.5}
            >
              [1-10]
            </Text>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

export function Result(): ReactElement {
  const t = useModifiedTranslation();
  const engineType = useAssertEngineTypeFromPathParam();
  const analysisEngines = useGetAnalysisEngines(engineType);
  const [recordedAt, setRecordedAt] = useState("");
  const [engineResultElements, setEngineResultElements] = useState<
    ReactElement[] | null
  >(null);

  const [recordedDate, recordedTime] = recordedAt.split(" ");
  const { fileId } = useParams();
  useWindowSize();

  const [isGetMimosysData] = useAtom(isGetMimosysDataAtom);

  useEffect(() => {
    if (!fileId) return;

    // 元気圧・心の活量値・こころ指数のメーター表示用に最終結果を（「いい調子」「普段通り」「疲れ気味」の判定を含む）初期化
    const renderingPromises = analysisEngines.map((engine, engineIndex) =>
      engine.renderResult(engineIndex, fileId, t)
    );
    Promise.all(renderingPromises).then((renderingResults) => {
      setEngineResultElements(renderingResults);
    });
    const analysisResultData = analysisEngines.map((engine) =>
      engine.getLatestRecordedAt(fileId)
    );
    Promise.all(analysisResultData).then((result) => {
      const recorded_at = new Date(result[result.length - 1]);
      setRecordedAt(recorded_at.toLocaleString("ja"));
    });
  }, [fileId, t, analysisEngines]);

  const [outputStenScore] = useAtom(outputStenScoreAtom);

  // NOTE: こころ指数画像のプリロード、もっと良い方法があるかも
  // FIXME: 解析中画面は、結果画面に遷移してから表示すべき
  const [loaded, setLoaded] = useState(!outputStenScore);
  const [latestStenScore] = useAtom(latestStenScoreAtom);
  const kokoroImageUrl = outputStenScore
    ? KOKORO_IMAGE_URL[latestStenScore === null ? 0 : latestStenScore]
    : null;
  useEffect(() => {
    if (kokoroImageUrl) {
      const img = new Image();
      img.src = kokoroImageUrl;
      img.onload = () => {
        setLoaded(true);
      };
    }
  }, [kokoroImageUrl]);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  if (!fileId) {
    return <Navigate to="/404" replace />;
  } else if (engineResultElements !== null && isGetMimosysData) {
    return loaded ? (
      <Layout
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Layout paddingX={0} paddingY={0}>
          <Layout.Title>{t("Result.analysis_results")}</Layout.Title>
          {APP_TYPE === "poc" && <PocResult />}
          {APP_TYPE !== "poc" && (
            <>
              <Flex
                fontSize="32px"
                justifyContent="center"
                alignItems="end"
                fontFamily="Oswald"
                fontWeight="500"
                marginBottom={6}
              >
                <Text textAlign="end" fontSize="32px">
                  {recordedDate}&nbsp;
                </Text>
                <Text textAlign="end" fontSize="24px" marginBottom="3px">
                  {recordedTime}
                </Text>
              </Flex>

              {outputStenScore && <Mi1Chart />}
              {!outputStenScore && <MimosysChart />}
            </>
          )}
        </Layout>
        <Button
          as={Link}
          to="../../../../home"
          size="lg"
          variant="btn_primary"
          width="100%"
          minHeight="64px"
        >
          HOME
        </Button>
      </Layout>
    ) : (
      <></>
    );
  } else {
    return <></>;
  }
}
