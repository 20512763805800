import SegmentedControl from "./SegmentedControl";
import { ReactElement, ReactNode, useEffect } from "react";
import { atom, useAtom } from "jotai";
import {
  Box,
  Collapse,
  Flex,
  VStack,
  HStack,
  Grid,
  IconButton,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  displayStringDateAtom,
  displayStringDateSmallAtom,
  handleTapDecrementAtom,
  handleTapIncrementAtom,
  resultBaseTimeAtom,
  resultTabSelectAtom,
  selectResultTabAtom,
} from "./ResultAtom";
import { MonthChart, WeekChart, YearChart } from "./ResultChart";
import { IOSSwitch } from "../CommonLayout/Switch";
import { useWindowSize } from "../../../hooks/useWindowSize";
import dayjs from "dayjs";
import { analysisStartedDateAtom } from "../../../stores/mimosysStore";
import { outputStenScoreAtom } from "../../../store";
import { useModifiedTranslation } from "../../../hooks/useModifiedTranslation";

/**
 * 結果画面の中身のコンポーネント
 * 3画面(すべて、元気圧、活量値)で使用
 */
const showMentalActivityAtom = atom(false);
const showVitalityAtom = atom(false);
const showMi1Atom = atom(false);

/*
 * ログイン後 解析結果グラフ画面
 */
export function ResultBody(): ReactElement {
  useWindowSize();
  // const classes = useStyles();
  const [, setSelectResultTab] = useAtom(selectResultTabAtom);
  const [resultTabSelect] = useAtom(resultTabSelectAtom);
  const t = useModifiedTranslation();
  // const tabValues = ["2週", "月", "年"];
  const tabValues = [
    t("Graph.common.twoWeeks"),
    t("Graph.common.month"),
    t("Graph.common.year"),
  ];

  return (
    <Grid
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box mt={2} />

      {/* 期間指定の選択タブ */}
      <Grid flexDirection="column" justifyContent="center">
        <SegmentedControl
          activeTextColor="login.login_button_bg"
          inActiveTextColor="primary.theme_lv1"
          inActiveColor="primary.theme_lv2"
          activeColor="primary.theme_lv1"
          value={resultTabSelect}
          onChanged={(value) => setSelectResultTab(value)}
          values={tabValues}
        />
      </Grid>
      {/* 期間指定の日程表示 */}
      <ShowDate />

      <Box mt={3} />
      {/* グラフ表示【左右ナビゲーション・トグル付き】 */}
      <ChartContent />
      <Box height="150px">&nbsp;</Box>
    </Grid>
  );
}

/*
 * 解析結果グラフ画面 表示期間のタブ表示 / グラフ表示 / トグル表示
 */
function WrapCard(props: { children: ReactNode; index: number }): ReactElement {
  const [baseDateTime] = useAtom(resultBaseTimeAtom);
  const [analysisStatedDate] = useAtom(analysisStartedDateAtom);
  const [selectValue] = useAtom(resultTabSelectAtom);
  const [, handleTapIncrement] = useAtom(handleTapIncrementAtom);
  const [, handleTapDecrement] = useAtom(handleTapDecrementAtom);
  const [showMentalActivity, setShowMentalActivity] = useAtom(
    showMentalActivityAtom
  );
  const [showVitality, setShowVitality] = useAtom(showVitalityAtom);
  const [showMi1, setShowMi1] = useAtom(showMi1Atom);
  const [outputStenScore] = useAtom(outputStenScoreAtom);
  const t = useModifiedTranslation();
  let diffGranularity: "week" | "month" | "year";
  switch (props.index) {
    case 1:
      diffGranularity = "month";
      break;
    case 2:
      diffGranularity = "year";
      break;
    case 0:
    default:
      diffGranularity = "week";
      break;
  }

  const now = dayjs();
  const canDecrement = baseDateTime.isAfter(
    analysisStatedDate,
    diffGranularity
  );
  const canIncrement = baseDateTime.isBefore(now, diffGranularity);

  return (
    <Collapse in={selectValue === props.index}>
      <Box
        boxShadow="md"
        sx={{
          ".analysis_arrow_left": {
            fontSize: "1.5rem",
            backgroundColor: "primary.bg_lv1",
            paddingLeft: "10px",
          },
          ".analysis_arrow_right": {
            fontSize: "1.5rem",
            backgroundColor: "primary.bg_lv1",
            paddingRight: "10px",
          },
        }}
      >
        <VStack align="center">
          <HStack align="center" justify="center" w="100%">
            <IconButton
              icon={<ChevronLeftIcon />}
              isDisabled={!canDecrement}
              onClick={handleTapDecrement}
              className="analysis_arrow_left"
              aria-label="Decrement"
            />
            <Box w="100%">{props.children}</Box>
            <IconButton
              icon={<ChevronRightIcon />}
              isDisabled={!canIncrement}
              onClick={handleTapIncrement}
              className="analysis_arrow_right"
              aria-label="Increment"
            />
          </HStack>
          <Flex
            justify="center"
            align="center"
            paddingLeft="5vw"
            paddingRight="5vw"
            w="100%"
          >
            {/* 解析結果グラフ画面の凡例名：こころ指数 */}
            {outputStenScore && (
              <DisplaySwitch
                title={t("Graph.common.mi1Result")}
                show={showMi1}
                activeColor="mi1Result.primary"
                backGroundColor="mi1Result.bg"
                setShow={(value) => setShowMi1(value)}
              />
            )}
            {/* 解析結果グラフ画面の凡例名：心の活量値 */}
            <DisplaySwitch
              title={t("Graph.common.mentalActivity")}
              show={showMentalActivity}
              activeColor="mentalActivity.primary"
              backGroundColor="mentalActivity.bg"
              setShow={(value) => setShowMentalActivity(value)}
            />
            {/* 解析結果グラフ画面の凡例名：元気圧 */}
            <DisplaySwitch
              title={t("Graph.common.vitality")}
              show={showVitality}
              activeColor="vitality.primary"
              backGroundColor="vitality.bg"
              setShow={(value) => setShowVitality(value)}
            />
          </Flex>
        </VStack>
      </Box>
    </Collapse>
  );
}

/*
 * グラフ表示【左右ナビゲーション・トグル付き】
 */
function ChartContent(): ReactElement {
  const [showMentalActivity, setShowMentalActivity] = useAtom(
    showMentalActivityAtom
  );
  const [showVitality] = useAtom(showVitalityAtom);
  const [showMi1, setShowMi1] = useAtom(showMi1Atom);

  const [outputStenScore] = useAtom(outputStenScoreAtom);

  useEffect(() => {
    if (outputStenScore) {
      // こころ指数契約ありのとき:こころ指数のみON
      setShowMi1(true);
    } else {
      // こころ指数契約なしのとき:心の活量値のみON
      setShowMentalActivity(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <Box w="100%" justifyContent="center" paddingBottom="15px">
      <WrapCard index={0}>
        <WeekChart
          showMentalActivity={showMentalActivity}
          showVitality={showVitality}
          showMi1={showMi1}
        />
      </WrapCard>
      <WrapCard index={1}>
        <MonthChart
          showMentalActivity={showMentalActivity}
          showVitality={showVitality}
          showMi1={showMi1}
        />
      </WrapCard>
      <WrapCard index={2}>
        <YearChart
          showMentalActivity={showMentalActivity}
          showVitality={showVitality}
          showMi1={showMi1}
        />
      </WrapCard>
    </Box>
  );
}

/*
 * レイアウトパーツ：トグル表示
 */
function DisplaySwitch(props: {
  title: string;
  show: boolean;
  activeColor: string;
  backGroundColor: string;
  setShow: (value: boolean) => void;
}): ReactElement {
  return (
    <Flex alignItems="center" flex="1" justifyContent="center">
      <Text fontSize="sm" paddingRight="8px" paddingTop="4px" fontWeight="500">
        {props.title}
      </Text>
      <IOSSwitch
        id={`switch-${props.title}`}
        activeColor={props.activeColor}
        backgroundColor={props.backGroundColor}
        checked={props.show}
        onChange={(e, v) => props.setShow(v)}
      />
    </Flex>
  );
}

/*
 * レイアウトパーツ：期間指定のタブ選択後の日付表示
 */
function ShowDate(props: { isSmall?: boolean }): ReactElement {
  const [displayStringDate] = useAtom(
    props.isSmall ? displayStringDateSmallAtom : displayStringDateAtom
  );
  return (
    <Box
      sx={{
        ".analysis_display_date": {
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "500",
          paddingBottom: "15px",
        },
      }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        className="analysis_display_date"
      >
        <Text
          display="inline-flex"
          fontSize="xl"
          fontFamily="Oswald"
          letterSpacing={0.5}
          as="div"
        >
          {displayStringDate[0]}
        </Text>
        {displayStringDate.length > 1 && (
          <>
            <Flex display="inline-flex" alignItems="flex-end">
              <Text
                fontSize="md"
                fontFamily="Oswald"
                as="div"
                ml={1}
                marginBottom="2px"
              >
                {displayStringDate[1]}
              </Text>
              <Text
                display="inline-flex"
                fontFamily="Oswald"
                fontSize="xl"
                ml={1}
                as="div"
              >
                -
              </Text>
              <Text
                ml={1}
                fontSize="xl"
                fontFamily="Oswald"
                letterSpacing={0.5}
                as="div"
              >
                {displayStringDate[2]}
              </Text>
              <Text
                fontSize="md"
                fontFamily="Oswald"
                as="div"
                ml={1}
                marginBottom="2px"
              >
                {displayStringDate[3]}
              </Text>
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
}
