import jwt_decode from "jwt-decode";

import { DecodedAccessToken } from "../store";

/*
 * クエリログイン用のアクセストークンのデコード
 */
export const decodeJwtHeader = (token: string): Record<string, unknown> => {
  try {
    return jwt_decode(token, { header: true });
  } catch (err) {
    return {};
  }
};

/*
 * 一般ユーザー用のアクセストークンのデコード
 */
export const decodeJwt = (token: string): DecodedAccessToken | null => {
  try {
    return jwt_decode(token);
  } catch (err) {
    return null;
  }
};
