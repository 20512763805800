import { ReactElement } from "react";
import { AxiosResponse } from "axios";
import { appAxios } from "../utils/appAxios";
import { ResultDiagramParam } from "../types";
import { sentryLog } from "../../sentry";
export abstract class BaseAnalysisEngine<T> {
  abstract _resultCaches: Record<string, T>;
  abstract _apiPath: string;

  async getResult(fileId: string): Promise<T | null> {
    if (this._resultCaches[fileId]) {
      return this._resultCaches[fileId];
    } else {
      try {
        const response = await appAxios.get(this._apiPath, {
          params: {
            file_id: fileId,
          },
        });
        this._resultCaches[fileId] = response.data;
        return response.data;
      } catch (e) {
        sentryLog(e);
        return null;
      }
    }
  }

  /**
   * @throws {Error}
   */
  /*
   * 音声解析
   */
  // 契約者アカウントによるクエリログインを考慮し、クエリストリングに「一般ユーザーID」（employee_id）を付与
  async analyze(
    fileId: string,
    detectVolume: number,
    employee_id: string
  ): Promise<AxiosResponse<T>> {
    const startTime = performance.now();
    const response = await appAxios.post(this._apiPath, {
      file_id: fileId,
      employee_id: employee_id,
      config: { voice_activity_detection: { detect_volume: detectVolume } },
    });
    const endTime = performance.now();
    console.info(
      "[WebDK_API] " +
        this._apiPath +
        " : " +
        (endTime - startTime).toFixed(3) +
        "ms"
    );
    this._resultCaches[fileId] = response.data;
    return response;
  }

  abstract renderResult(
    key: number,
    fileId: string,
    t: (key: string) => string
  ): Promise<ReactElement>;

  abstract getLatestRecordedAt(fileId: string): Promise<string>;

  static generateResultDiagramParams<P>(
    paramList: (P & { wav_filename: string })[],
    convertToRecord: (
      param: P & { wav_filename: string },
      recordId: string,
      phraseIndex: number
    ) => ResultDiagramParam[]
  ): ResultDiagramParam[][] {
    let phraseIndex = -1;
    let utteranceCount = -1;
    let prevFileName: null | string = null;
    return paramList.map((param) => {
      if (prevFileName !== param.wav_filename) {
        phraseIndex += 1;
        utteranceCount = 1;
      } else {
        utteranceCount += 1;
      }

      const phraseDisplayStr = (phraseIndex + 1).toString();
      const recordId = `${phraseDisplayStr}${
        utteranceCount === 1 ? "" : "-" + utteranceCount
      }`;

      prevFileName = param.wav_filename;
      return convertToRecord(param, recordId, phraseIndex);
    });
  }
}

export type AnalysisVoiceInfo = {
  phrase?: string;
  cog_voice_type?: "LONG_VOWEL_A" | "COG_ANSWER" | "REPEAT_PATAKA";
  wav_filename: string;
};

export type BaseAnalysisResult = {
  file_id: string;
  recorded_at: string;
  voice_profiles?: {
    recorded_at: string;
    app_version?: string;
    rec_type?: number;
    voices: AnalysisVoiceInfo[];
    model?: string;
    os_version_id?: string;
    upload_user_id?: string;
  };
  analyzed_at: string;
  engine_version: string;
};
