import { ReactElement, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import {
  Box,
  Button,
  chakra,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import questionnaires from "../../../assets/json/questionnaires.json";
import { useAtom } from "jotai";
import { questionnaireAtom, audioRecorder } from "../../../store";
import { Layout } from "../../../components/atoms/Layout";
import { SKIP_QUESTIONNAIRES } from "../../../environments";
import { useAssertEngineTypeFromPathParam } from "../../../utils/selectAnalysisEngine";
import { CompanySettingDataAtom } from "../../../store";
import { useModifiedTranslation } from "../../../hooks/useModifiedTranslation";

type QuestionnairesData = {
  name: string;
  questions: QuestionnairesQuestion[];
};

const QuestionnairesDataInitial = {
  name: "",
  questions: [],
};

type QuestionnairesQuestion = {
  type: string;
  name: string;
  questionKey: string;
  choiceKeys?: string[];
  placeholderKey?: string;
  initValue?: number;
  switchItems?: QuestionnairesSwitchData[];
};

type QuestionnairesSwitchData = {
  questionKey: string;
  switchInit: boolean;
};

function QuestionnaireTitle(props: { title: string }): ReactElement {
  return (
    <Text fontWeight="bold" fontSize="18px" color="text.main_text_lv1">
      <chakra.span
        w="100"
        p="md"
        fontWeight="bold"
        fontSize="2xl"
        color="primary.theme_lv1"
      >
        {"Q. "}
      </chakra.span>
      {props.title}
    </Text>
  );
}

function MakeRadioBlock(props: {
  name: string;
  title: string;
  value: string;
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <Box as="label">
      <Box
        bg="primary.bg_lv1"
        height="4.5em"
        border="4px solid"
        borderColor="white"
        borderRadius="10px"
        _hover={{
          border: "2px solid",
          borderColor: "primary.theme_lv1",
        }}
      >
        <Radio
          value={props.value}
          marginTop="1.25em"
          marginLeft="1em"
          backgroundColor="white"
          borderColor="#808080"
          _focus={{}}
          _checked={{
            border: " 4px solid white",
            boxSizing: "border-box",
            boxShadow: "0 0 0 2px #808080",
            backgroundColor: "primary.theme_lv1",
          }}
          {...props.register(props.name, {})}
        >
          <Box color="text.main_text_lv1">{props.value}</Box>
        </Radio>
      </Box>
    </Box>
  );
}

function QuestionnaireWithRadio(props: {
  name: string;
  title: string;
  choices: string[];
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <>
      <QuestionnaireTitle title={props.title} />
      <RadioGroup w="100%" defaultValue="1" color="black">
        {props.choices.map((value, index) => (
          <MakeRadioBlock
            key={index}
            name={props.name}
            value={value}
            title={props.title}
            register={props.register}
          />
        ))}
      </RadioGroup>
    </>
  );
}

function QuestionnaireWithSelect(props: {
  name: string;
  title: string;
  choices: string[];
  placeholder: string;
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <>
      <QuestionnaireTitle title={props.title} />
      <Select
        placeholder={props.placeholder}
        _hover={{
          border: "2px solid",
          borderColor: "primary.theme_lv1",
        }}
        _focus={{
          border: "2px solid",
          borderColor: "primary.theme_lv1",
        }}
        {...props.register(props.name, {})}
      >
        {props.choices.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </Select>
    </>
  );
}

function MakeCheckboxBlock(props: {
  name: string;
  item: string;
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <>
      <Flex>
        <Checkbox
          value={props.item}
          iconColor="primary.theme_lv1"
          colorScheme="white"
          {...props.register(props.name, {})}
          _after={{
            colorScheme: "primary.theme_lv1",
          }}
          _hover={{
            borderColor: "primary.theme_lv1",
          }}
        >
          {props.item}
        </Checkbox>
      </Flex>
    </>
  );
}

function QuestionnaireWithCheckbox(props: {
  name: string;
  title: string;
  choices: string[];
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <>
      <QuestionnaireTitle title={props.title} />
      <CheckboxGroup colorScheme="red" defaultValue={[]}>
        <Stack spacing={["5px"]} direction={["column"]}>
          {props.choices.map((value, index) => (
            <MakeCheckboxBlock
              key={index}
              name={props.name}
              item={value}
              register={props.register}
            />
          ))}
        </Stack>
      </CheckboxGroup>
    </>
  );
}

function QuestionnaireWithNumberInput(props: {
  name: string;
  title: string;
  initValue: number;
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <>
      <QuestionnaireTitle title={props.title} />
      <NumberInput defaultValue={props.initValue} min={0} max={100}>
        <NumberInputField
          _hover={{
            border: "2px solid",
            borderColor: "primary.theme_lv1",
          }}
          _focus={{
            border: "2px solid",
            borderColor: "primary.theme_lv1",
          }}
          {...props.register(props.name, {})}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </>
  );
}

function MakeSwitchBlock(props: {
  name: string;
  value: QuestionnairesSwitchData;
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  const t = useModifiedTranslation();
  const question = t(props.value.questionKey);
  return (
    <>
      <FormLabel mb="0">{question}</FormLabel>
      <FormControl>
        <Flex width="10%">
          <Switch
            value={question}
            variant="with-shadow"
            defaultChecked={props.value.switchInit}
            colorScheme="red"
            boxShadow="none"
            {...props.register(props.name, {})}
          />
        </Flex>
      </FormControl>
    </>
  );
}

function QuestionnaireWithSwitch(props: {
  name: string;
  title: string;
  switchItems: QuestionnairesSwitchData[];
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <>
      <QuestionnaireTitle title={props.title} />
      <FormControl display="flex" alignItems="left" flexDirection="column">
        {props.switchItems.map((value: QuestionnairesSwitchData, index) => (
          <MakeSwitchBlock
            name={props.name}
            key={index}
            value={value}
            register={props.register}
          />
        ))}
      </FormControl>
    </>
  );
}

function QuestionnaireWithInput(props: {
  name: string;
  title: string;
  placeholder: string;
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <>
      <QuestionnaireTitle title={props.title} />
      <Input
        placeholder={props.placeholder}
        _hover={{
          border: "2px solid",
          borderColor: "primary.theme_lv1",
        }}
        _focus={{
          border: "2px solid",
          borderColor: "primary.theme_lv1",
        }}
        {...props.register(props.name, {})}
      />
    </>
  );
}

function QuestionnaireWithTextarea(props: {
  name: string;
  title: string;
  placeholder: string;
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  return (
    <>
      <QuestionnaireTitle title={props.title} />
      <Textarea
        placeholder={props.placeholder}
        _hover={{
          border: "2px solid",
          borderColor: "primary.theme_lv1",
        }}
        _focus={{
          border: "2px solid",
          borderColor: "primary.theme_lv1",
        }}
        {...props.register(props.name, {})}
      />
    </>
  );
}

function QuestionnaireItem(props: {
  data: QuestionnairesQuestion;
  register: UseFormRegister<Record<string, string>>;
}): ReactElement {
  const t = useModifiedTranslation();

  const question = t(props.data.questionKey);
  const choices = props.data.choiceKeys?.map((key) => t(key));
  const placeholderKey = props.data.placeholderKey;
  const initValue = props.data.initValue;
  const switchItems = props.data.switchItems;

  if (props.data.type === "radio" && choices !== undefined) {
    return (
      <QuestionnaireWithRadio
        name={props.data.name}
        title={question}
        choices={choices}
        register={props.register}
      />
    );
  } else if (
    props.data.type === "select" &&
    choices !== undefined &&
    placeholderKey !== undefined
  ) {
    return (
      <QuestionnaireWithSelect
        name={props.data.name}
        title={question}
        choices={choices}
        placeholder={t(placeholderKey)}
        register={props.register}
      />
    );
  } else if (props.data.type === "checkbox" && choices !== undefined) {
    return (
      <QuestionnaireWithCheckbox
        name={props.data.name}
        title={question}
        choices={choices}
        register={props.register}
      />
    );
  } else if (props.data.type === "numberInput" && initValue !== undefined) {
    return (
      <QuestionnaireWithNumberInput
        name={props.data.name}
        title={question}
        initValue={initValue}
        register={props.register}
      />
    );
  } else if (props.data.type === "switch" && switchItems !== undefined) {
    return (
      <QuestionnaireWithSwitch
        name={props.data.name}
        title={question}
        switchItems={switchItems}
        register={props.register}
      />
    );
  } else if (props.data.type === "textarea" && placeholderKey) {
    return (
      <QuestionnaireWithTextarea
        name={props.data.name}
        title={question}
        placeholder={t(placeholderKey)}
        register={props.register}
      />
    );
  } else if (props.data.type === "input" && placeholderKey) {
    return (
      <QuestionnaireWithInput
        name={props.data.name}
        title={question}
        placeholder={t(placeholderKey)}
        register={props.register}
      />
    );
  } else {
    return <></>;
  }
}

function Questionnaires(): ReactElement {
  const t = useModifiedTranslation();
  const [, setQuestionnaire] = useAtom(questionnaireAtom);
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<Record<string, string>>();
  const navigateTo = useNavigate();
  const engineType = useAssertEngineTypeFromPathParam();
  // const questionnairesData: QuestionnairesData = questionnaires[engineType];
  const questionnairesData: QuestionnairesData =
    engineType === "Mi1"
      ? QuestionnairesDataInitial
      : questionnaires[engineType];

  const is_transfer_recording = useRef(false);

  // mimosys_company_settings（契約者のMIMOSYS設定）のグローバル状態管理用
  const [companySettingData] = useAtom(CompanySettingDataAtom);

  // 前準備として録音マイクの取得を行っておく
  useEffect(() => {
    audioRecorder.init().then();
    return () => {
      if (!is_transfer_recording.current) {
        audioRecorder.destroy().then();
      }
    };
  }, []);

  const onSubmit: SubmitHandler<Record<string, string>> = (data) => {
    if (isDirty) {
      setQuestionnaire({
        state: "responded",
        name: questionnairesData.name,
        answers: data,
      });
    } else {
      setQuestionnaire({
        state: "skipped",
        name: questionnairesData.name,
        answers: {},
      });
    }
    is_transfer_recording.current = true;
    if (
      companySettingData.rec_types &&
      companySettingData.rec_types.length > 0
    ) {
      navigateTo("../select-rectype");
    } else {
      navigateTo("../recording");
    }
  };

  return (
    <Layout>
      <Layout.Title bg="primary.theme_lv1" color="white">
        {t("Questionnaires.title")}
      </Layout.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Flex flexDirection="column" rowGap="25px">
            {questionnairesData.questions.map((data) => (
              <QuestionnaireItem
                key={data.name}
                data={data}
                register={register}
              />
            ))}
          </Flex>
        </Box>
        <Button
          marginTop="25px"
          type="submit"
          size="lg"
          variant="btn_primary"
          width="full"
        >
          {t("Questionnaires.answer")}
        </Button>
      </form>
    </Layout>
  );
}

export function ProtectedQuestionnaires(): ReactElement {
  if (SKIP_QUESTIONNAIRES) {
    return <Navigate to="../recording" replace />;
  } else {
    return <Questionnaires />;
  }
}
