import { BaseAnalysisEngine, BaseAnalysisResult } from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { ResultDashboard } from "../components/molecules/ResultDashboard";
import {
  ResultDashboardData,
  ResultDiagramIndex,
  ResultMainParam,
  ResultSubParam,
  ResultSupplementaryInfo,
} from "../types";
import { EmotionType } from "../stores/mimosysStore";

type Emotion = {
  ang: number;
  joy: number;
  clm: number;
  srw: number;
  exc: number;
  wav_filename: string;
  start_time: number;
  end_time: number;
};

export type AnalysisResult = BaseAnalysisResult & {
  utterances: number;
  duration: number;
  num_vitality_data: number;
  vitality: number;
  mental_activity: number;
  mimosys_sub_parameters: {
    vitality_raw: number;
    mental_activity_raw: number;
    mental_activity_variable: number;
    vivacity: number;
    relaxation: number;
    emotions: Emotion[];
  };
  mi1_result?: number;
  mi1_result_reliability?: boolean;
};

const DEFAULT_DASHBOARD_MAIN_PARAM: ResultMainParam = {
  title: "Result.MIMOSYS_vitality",
  border: "solid 3px primary.theme_lv1",
  value: undefined,
};

const SUB_PARAMS_TITLE_LIST = [
  "Result.MIMOSYS_vivacity",
  "Result.MIMOSYS_relaxation",
];

const SUPPLEMENTARY_INFO_TITLE_LIST = [
  "Result.MIMOSYS_files",
  "Result.MIMOSYS_utterances",
];

export function getEmotionAverage(emotions: EmotionType[]): EmotionType {
  const totalJoy = emotions.reduce((sum, emotion) => sum + emotion.joy, 0);
  const totalAng = emotions.reduce((sum, emotion) => sum + emotion.ang, 0);
  const totalSrw = emotions.reduce((sum, emotion) => sum + emotion.srw, 0);
  const totalClm = emotions.reduce((sum, emotion) => sum + emotion.clm, 0);
  const totalExc = emotions.reduce((sum, emotion) => sum + emotion.exc, 0);
  const averageJoy = parseFloat((totalJoy / emotions.length).toFixed(0));
  const averageAng = parseFloat((totalAng / emotions.length).toFixed(0));
  const averageSrw = parseFloat((totalSrw / emotions.length).toFixed(0));
  const averageClm = parseFloat((totalClm / emotions.length).toFixed(0));
  const averageExc = parseFloat((totalExc / emotions.length).toFixed(0));
  return {
    joy: averageJoy,
    ang: averageAng,
    srw: averageSrw,
    clm: averageClm,
    exc: averageExc,
  };
}

export class MimosysEngine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};

  // MIMOSYS解析結果取得（/analysis/mimosys）は Deprecated
  // _apiPath = "/analysis/mimosys";

  // Mi-1解析結果取得（/analysis/mi1）
  //   レスポンスに「こころ指数」（mi1_result）（mi1_result_reliability）が追加されている。
  _apiPath = "/analysis/mi1";

  private static dashboardDiagramIndex(
    t: (key: string) => string
  ): ResultDiagramIndex[] {
    return [
      { width: "15%", title: "No" },
      { width: "20%", title: t("Result.table_phrase") },
      { width: "15%", title: t("Result.MIMOSYS_emotion_Joy") },
      { width: "15%", title: t("Result.MIMOSYS_emotion_Ang") },
      { width: "15%", title: t("Result.MIMOSYS_emotion_Srw") },
      { width: "15%", title: t("Result.MIMOSYS_emotion_Clm") },
      { width: "15%", title: t("Result.MIMOSYS_emotion_Exc") },
    ];
  }

  private static errorDashboardData(
    t: (key: string) => string
  ): ResultDashboardData {
    return {
      mainParam: DEFAULT_DASHBOARD_MAIN_PARAM,
      subParams: [
        {
          title: SUB_PARAMS_TITLE_LIST[0],
          value: undefined,
        },
        {
          title: SUB_PARAMS_TITLE_LIST[1],
          value: undefined,
        },
      ],
      supplementaryInfo: [
        {
          title: SUPPLEMENTARY_INFO_TITLE_LIST[0],
          value: undefined,
        },
        {
          title: SUPPLEMENTARY_INFO_TITLE_LIST[1],
          value: undefined,
        },
      ],
      diagramIndex: this.dashboardDiagramIndex(t),
      diagramParams: [],
    };
  }

  private static convertToDashboardData(
    result: AnalysisResult,
    t: (key: string) => string
  ): ResultDashboardData {
    const phrases = result.voice_profiles?.voices.map((voice) =>
      voice.phrase ? voice.phrase : "Error"
    );

    const diagramParams = BaseAnalysisEngine.generateResultDiagramParams(
      result.mimosys_sub_parameters.emotions,
      (param, recordId, phraseIndex) => [
        { width: "15%", value: recordId },
        { width: "20%", value: phrases ? phrases[phraseIndex] : "" },
        { width: "15%", value: param.joy },
        { width: "15%", value: param.ang },
        { width: "15%", value: param.srw },
        { width: "15%", value: param.clm },
        { width: "15%", value: param.exc },
      ]
    );

    const mainParam: ResultMainParam = {
      ...DEFAULT_DASHBOARD_MAIN_PARAM,
      value: Math.floor(result.vitality * 100),
    };

    const subParams: ResultSubParam[] = [
      {
        title: SUB_PARAMS_TITLE_LIST[0],
        value: Math.floor(result.mimosys_sub_parameters.vivacity * 100),
      },
      {
        title: SUB_PARAMS_TITLE_LIST[1],
        value: Math.floor(result.mimosys_sub_parameters.relaxation * 100),
      },
    ];

    const supplementaryInfo: ResultSupplementaryInfo[] = [
      {
        title: SUPPLEMENTARY_INFO_TITLE_LIST[0],
        value: result.voice_profiles?.voices.length,
      },
      {
        title: SUPPLEMENTARY_INFO_TITLE_LIST[1],
        value: result.utterances,
      },
    ];

    return {
      mainParam,
      subParams,
      supplementaryInfo,
      diagramIndex: MimosysEngine.dashboardDiagramIndex(t),
      diagramParams,
    };
  }

  async renderResult(
    key: number,
    fileId: string,
    t: (key: string) => string
  ): Promise<ReactElement> {
    const result = await this.getResult(fileId);
    const dashboardData = result
      ? MimosysEngine.convertToDashboardData(result, t)
      : MimosysEngine.errorDashboardData(t);
    return (
      <ResultDashboard
        key={key}
        title={t("Result.MIMOSYS_analysis_result")}
        result={dashboardData}
      />
    );
  }

  async getLatestRecordedAt(fileId: string): Promise<string> {
    const result = await this.getResult(fileId);
    return result ? result.recorded_at : "";
  }
}
