import { RecorderBuffer } from "./audioRecorderBuffer";
import {
  RecorderNodeType,
  ScriptWrapper,
  WebAudioWrapper,
  WorkletWrapper,
} from "./webAudioWrapper";
import UAParser from "ua-parser-js";

const VOLUME_INTERVAL_MILLI_SEC = 200; // DETECT_FRAME_MSECの倍数にする事
const DETECT_FRAME_MILLI_SEC = 10; // 発話検知確認の1フレーム長(msec)
const DEFAULT_DETECT_LENGTH_MILLI_SEC = 170; //サーバー側は150だがより条件を厳しくするために170とする
const SILENT_LENGTH_MILLI_SEC = 300; // 発話終了とみなす時間(msec)
const DEFAULT_ANALYZE_LENGTH_MILLI_SEC = 0; // 音声解析に必要な発話時間(msec)

type RecorderState =
  | "UNINITIALIZED"
  | "INITIALIZING"
  | "INITIALIZED"
  | "PREPARING"
  | "RECORDING"
  | "STOPPING"
  | "STOPPED"
  | "QUEUED_DESTROYING"
  | "DESTROYING"
  | "DESTROYED";

export class AudioRecorder {
  private _state: RecorderState = "UNINITIALIZED";
  private _recorderBuffer: RecorderBuffer = new RecorderBuffer();
  private _audioWrapper: WebAudioWrapper | null = null;
  private _updatingIntervalId: number | null = null;

  get samplingRate(): number | undefined {
    return this._audioWrapper?.samplingRate;
  }

  get audioWrapperType(): RecorderNodeType | undefined {
    return this._audioWrapper?.getType();
  }

  constructor(workletURL: string) {
    WorkletWrapper.WORKLET_URL = workletURL;
  }

  private static countLastConsecutiveTrues(list: boolean[]): number {
    let count = 0;
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i]) count++;
      else break;
    }
    return count;
  }

  private static calcPower(
    input: Float32Array,
    threshVolume?: number,
    lengthMsec?: number,
    currentOverThresholdTime?: number,
    detectLengthMSec: number = DEFAULT_DETECT_LENGTH_MILLI_SEC,
    useMaxVolume?: boolean
  ): [number, number, boolean, number] {
    let sum = 0;
    let maxPower = 0;
    let overThresholdTime = 0;
    let overThresholdTimeSum = currentOverThresholdTime
      ? currentOverThresholdTime
      : 0;
    const overThresholdList = [];
    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i]));
      const abs_s = Math.abs(s);
      sum += abs_s;
      maxPower = abs_s < maxPower ? maxPower : abs_s;
    }
    if (lengthMsec && threshVolume) {
      // ボリュームゲージの反応が過敏になる為、発話中とみなした後は判定基準を緩和する
      if (
        currentOverThresholdTime &&
        currentOverThresholdTime >= detectLengthMSec
      ) {
        overThresholdTime = overThresholdTimeSum + lengthMsec;
        overThresholdTimeSum = overThresholdTime;
      } else {
        const checkVolumeMsecSpan = lengthMsec / DETECT_FRAME_MILLI_SEC;
        const checkVolumeSpan = input.length / checkVolumeMsecSpan;
        for (let i = 0; i < input.length; i += checkVolumeSpan) {
          let s = input.slice(i, i + checkVolumeSpan);
          s = s.map((v) => Math.max(-1, Math.min(1, v)));
          s = s.map((v) => Math.abs(v));
          let tgtVolume = 0;
          if (!useMaxVolume) {
            // 平均音量を計算
            const spanAverage = s.reduce((sum, v) => sum + v, 0) / s.length;
            tgtVolume = spanAverage * Math.pow(2, 15);
          } else {
            // 最大音量を計算
            const spanMax = Math.max(...s);
            tgtVolume = spanMax * 12800;
          }
          overThresholdList.push(tgtVolume > threshVolume);
        }
        // 全て発話検知していた場合
        if (overThresholdList.every((v) => v)) {
          overThresholdTime = overThresholdTimeSum + lengthMsec;
          overThresholdTimeSum = overThresholdTime;
        } else {
          const lastTrueCount =
            this.countLastConsecutiveTrues(overThresholdList);
          // 途中～最後まで発話検知していた場合
          if (overThresholdList[overThresholdList.length - 1]) {
            overThresholdTime = lastTrueCount * DETECT_FRAME_MILLI_SEC;
            overThresholdTimeSum = overThresholdTime;
          } else {
            // 途中だけ発話検知していた場合
            let maxConsecutiveTrues = 0;
            let currentCount = 0;
            overThresholdList.forEach((value) => {
              if (value) {
                currentCount++;
                if (currentCount > maxConsecutiveTrues) {
                  maxConsecutiveTrues = currentCount;
                }
              } else {
                currentCount = 0;
              }
            });
            overThresholdTime = maxConsecutiveTrues * DETECT_FRAME_MILLI_SEC;
            overThresholdTimeSum = 0;
          }
        }
      }
    }
    const overThresholdTimeFlag = overThresholdTime >= detectLengthMSec;
    const averagePower = input.length !== 0 ? sum / input.length : 0;
    return [
      averagePower,
      maxPower,
      overThresholdTimeFlag,
      overThresholdTimeSum,
    ];
  }

  static detectVoice(
    s: Float32Array,
    sr: number,
    threshVolume: number,
    detectLengthMSec: number = DEFAULT_DETECT_LENGTH_MILLI_SEC
  ): boolean {
    // 1フレームのインデックス長
    const frameSize = Math.floor((sr * DETECT_FRAME_MILLI_SEC) / 1000);

    // 音声と判定するための音声の閾値越えが継続しているフレーム数
    const detectFrameLength = Math.floor(
      detectLengthMSec / DETECT_FRAME_MILLI_SEC
    );

    // 音量閾値を超えているかを示すフレーム毎のリスト
    const frameVolumeOverList: boolean[] = [];

    for (let i = 0; i < Math.floor(s.length / frameSize) - 1; ++i) {
      // フレームを取得
      const frame = s.slice(i * frameSize, (i + 1) * frameSize);
      // フレームの最大音量を取得
      const absFrame = frame.map((v) => Math.abs(v));
      // const maxValue = absFrame.reduce(function (a, b) {
      //   return Math.max(a, b);
      // }, -Infinity);
      // const maxVolume = Math.floor(maxValue * 12800);
      const averageVolume =
        (absFrame.reduce((a, b) => a + b, 0) / Math.max(absFrame.length, 1)) *
        2 ** 15;

      // フレームが閾値を超えているかをリストに追加
      frameVolumeOverList.push(averageVolume > threshVolume);

      // 過去フレームが連続して閾値を超えていれば発話とする
      if (frameVolumeOverList.length >= detectFrameLength) {
        const overListOfDetectLength = frameVolumeOverList.slice(
          -detectFrameLength
        );
        if (overListOfDetectLength.every((v) => v)) {
          return true;
        }
      }
    }
    return false;
  }

  static detectVoiceV2(
    s: Float32Array,
    sr: number,
    threshVolume: number,
    detectLengthMSec: number = DEFAULT_DETECT_LENGTH_MILLI_SEC,
    analyzeLengthMSec: number = DEFAULT_ANALYZE_LENGTH_MILLI_SEC,
    analyzeMode = false
  ): [boolean, number[], number[][]] {
    let detectResult = false;

    // 1フレームのインデックス長
    const frameSize = Math.floor((sr * DETECT_FRAME_MILLI_SEC) / 1000);

    // 音声と判定するための音声の閾値越えが継続しているフレーム数
    const detectFrameLength = Math.floor(
      detectLengthMSec / DETECT_FRAME_MILLI_SEC
    );

    // 音声解析に必要な発話区域のフレーム数
    const minAnalyzeFrameLength = Math.floor(
      analyzeLengthMSec / DETECT_FRAME_MILLI_SEC
    );

    // 発話終了とするフレーム数
    const silentFrameLength = Math.floor(
      SILENT_LENGTH_MILLI_SEC / DETECT_FRAME_MILLI_SEC
    );

    // フレーム毎の最大音量値のリスト
    const maxVolumeList: number[] = [];

    // 発話検知区間
    let voiceDetectionSegment: number[] = [];

    // 発話検知区間のリスト
    const voiceDetectionSegmentList: number[][] = [];

    // 音量閾値を超えているかを示すフレーム毎のリスト
    // 0: 発話無し 1: 発話検知 2: 発話中 3: 発話中無音 4: 発話終了
    const frameDetectVoiceStatusList: number[] = [];

    let voiceDetectCount = 0;
    let latestDetectVoiceFrame = 0;

    for (let i = 0; i < Math.floor(s.length / frameSize) - 1; ++i) {
      // フレームを取得
      const frame = s.slice(i * frameSize, (i + 1) * frameSize);
      const absFrame = frame.map((v) => Math.abs(v));
      // フレームの最大音量を取得
      const maxValue = absFrame.reduce(function (a, b) {
        return Math.max(a, b);
      }, -Infinity);
      maxVolumeList.push(maxValue);
      const calcMaxValue = Math.floor(maxValue * 12800);

      // フレームが閾値を超えているかをリストに追加
      let detectVoiceStatus = 0;
      if (calcMaxValue > threshVolume) {
        detectVoiceStatus = 1;
        latestDetectVoiceFrame = i;
      }
      const listLength = frameDetectVoiceStatusList.length;
      const lastVoiceStatus =
        listLength > 1
          ? frameDetectVoiceStatusList[frameDetectVoiceStatusList.length - 1]
          : 0;

      // 発話検知が規定時間連続したら発話中
      if (
        detectVoiceStatus === 1 &&
        listLength >= detectFrameLength &&
        frameDetectVoiceStatusList
          .slice(-detectFrameLength)
          .every((value) => value === 1)
      ) {
        detectVoiceStatus = 2;
        const startTime = i - detectFrameLength;
        voiceDetectionSegment = [startTime];
      }

      // 発話中に音声を検知したら発話中を継続
      // 発話中無音で発話を検知したら発話中に戻す
      if (
        (lastVoiceStatus === 2 || lastVoiceStatus === 3) &&
        detectVoiceStatus === 1
      ) {
        detectVoiceStatus = 2;
      }

      // 発話中に音声を検知出来なかったら発話中無音
      if (
        listLength > 1 &&
        (lastVoiceStatus === 2 || lastVoiceStatus === 3) &&
        detectVoiceStatus === 0
      ) {
        detectVoiceStatus = 3;
      }

      // 発話中無音が規定時間連続したら発話終了
      if (
        detectVoiceStatus === 3 &&
        listLength >= silentFrameLength &&
        frameDetectVoiceStatusList
          .slice(-silentFrameLength)
          .every((value) => value === 3)
      ) {
        detectVoiceStatus = 4;
        const endTime = i - silentFrameLength;
        voiceDetectionSegment.push(endTime);
        voiceDetectionSegmentList.push(voiceDetectionSegment);
        voiceDetectionSegment = [];
      }

      frameDetectVoiceStatusList.push(detectVoiceStatus);

      switch (detectVoiceStatus) {
        case 0:
          voiceDetectCount = 0;
          break;
        case 1:
          voiceDetectCount++;
          break;
        case 2:
          voiceDetectCount++;
          if (voiceDetectCount >= minAnalyzeFrameLength) {
            detectResult = true;
          }
          break;
        case 3:
          voiceDetectCount++;
          break;
        case 4:
          voiceDetectCount = 0;
          break;
      }
      // analyzeModeでなければ発話検知した時点で終了
      if (detectResult && !analyzeMode) {
        return [detectResult, maxVolumeList, voiceDetectionSegmentList];
      }
    }
    const lastDetectVoiceStatus =
      frameDetectVoiceStatusList[frameDetectVoiceStatusList.length - 1];
    // 発話中に録音終了したら強制的に発話終了（最後に発話検知した所まで）
    if (lastDetectVoiceStatus === 2 || lastDetectVoiceStatus === 3) {
      voiceDetectionSegment.push(latestDetectVoiceFrame);
      voiceDetectionSegmentList.push(voiceDetectionSegment);
    }
    return [detectResult, maxVolumeList, voiceDetectionSegmentList];
  }

  private clearBuffer(): void {
    this._recorderBuffer.clear();
  }

  private getPower(
    clearBuffer?: boolean,
    threshVolume?: number,
    overThresholdTime?: number,
    lengthMsec?: number,
    detectLengthMsec?: number,
    useMaxVolume?: boolean
  ): [number | null, number | null, boolean | null, number | null] {
    const data = this.getRecordedData();
    if (clearBuffer) {
      this.clearBuffer();
    }
    return data
      ? AudioRecorder.calcPower(
          data,
          threshVolume,
          lengthMsec,
          overThresholdTime,
          detectLengthMsec,
          useMaxVolume
        )
      : [null, null, null, null];
  }

  private getRecordedData(): Float32Array | null {
    return this._recorderBuffer.getData();
  }

  private getAllRecordedData(): Float32Array | null {
    return this._recorderBuffer.getAllData();
  }

  private async waitUntilState(state: RecorderState): Promise<boolean> {
    if (this._state === state) return true;
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (this._state === state) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 50);
    });
  }

  private _createWebAudioWrapper(): WebAudioWrapper {
    try {
      const uaParserResult = UAParser(window.navigator.userAgent);
      UAParser.BROWSER;
      // iOSの場合バージョン
      if (uaParserResult.os.name === "iOS") {
        const version_list = uaParserResult.os.version?.split(".");
        if (version_list && version_list.length >= 2) {
          const major_version = parseInt(version_list[0]);
          const minor_version = parseInt(version_list[1]);

          // iOS 16.3以下の場合はScriptProcessorを使用する
          if (
            major_version < 16 ||
            (major_version == 16 && minor_version <= 3)
          ) {
            return new ScriptWrapper(this._recorderBuffer);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return new WorkletWrapper(this._recorderBuffer);
  }

  /**
   * @throws {DOMException}
   */
  async init(): Promise<void> {
    if (this._state === "INITIALIZING" || this._state === "INITIALIZED") return;
    if (this._state === "QUEUED_DESTROYING" || this._state === "DESTROYING") {
      await this.waitUntilState("DESTROYED");
    }
    if (this._state === "RECORDING") {
      await this.stopRecording();
    }
    this._state = "INITIALIZING";

    try {
      this._audioWrapper = this._createWebAudioWrapper();
      await this._audioWrapper.prepareRecording();
    } catch (e) {
      if (this._audioWrapper) {
        await this._audioWrapper.close();
        this._audioWrapper = null;
        this._state = "UNINITIALIZED";
        throw e;
      }
    }

    this._state = "INITIALIZED";
  }

  /**
   * @throws {Error}
   */
  async startRecording(
    updatingPowerCallback?: (
      averagePower: number,
      maxPower: number,
      detectVolumeFlag: boolean
    ) => void,
    clearBufferOnUpdate?: boolean,
    threshVolume?: number,
    detectLengthMsec?: number,
    useMaxVolume?: boolean
  ): Promise<void> {
    if (
      this._state === "QUEUED_DESTROYING" ||
      this._state === "DESTROYING" ||
      this._state === "DESTROYED"
    ) {
      return;
    }
    // MEMO
    // 停止ボタン押下後の録音ボタン復帰動作を速くするために、
    // 停止動作が完了していない状況で録音ボタンを押せるようにしているので、
    // 録音不可能であれば処理を飛ばす
    if (
      this._state === "STOPPING" ||
      (this._state === "STOPPED" && !this._audioWrapper?.canStart())
    ) {
      return;
    }

    if (this._state === "INITIALIZING") {
      await this.waitUntilState("INITIALIZED");
    }
    if (this._state === "RECORDING" || this._state === "PREPARING") {
      await this.stopRecording();
    }

    if (!this._audioWrapper)
      throw new Error("AudioRecorder is not initialized");

    this._state = "PREPARING";
    this.clearBuffer();
    this._audioWrapper.startRecording();

    if (updatingPowerCallback) {
      let currentOverThresholdTime = 0;
      this._updatingIntervalId = window.setInterval(() => {
        const [averagePower, maxPower, detectVolumeFlag, detectVolumeTime] =
          this.getPower(
            clearBufferOnUpdate,
            threshVolume,
            currentOverThresholdTime,
            VOLUME_INTERVAL_MILLI_SEC,
            detectLengthMsec,
            useMaxVolume
          );
        if (
          averagePower !== null &&
          maxPower !== null &&
          detectVolumeFlag !== null &&
          detectVolumeTime !== null
        ) {
          if (detectVolumeFlag) {
            currentOverThresholdTime = detectVolumeTime;
          }
          updatingPowerCallback(averagePower, maxPower, detectVolumeFlag);
        }
      }, VOLUME_INTERVAL_MILLI_SEC);
    }

    this._state = "RECORDING";
  }

  async stopRecording(): Promise<Float32Array | null> {
    if (this._state !== "PREPARING" && this._state !== "RECORDING") return null;
    if (this._state === "PREPARING") {
      await this.waitUntilState("RECORDING");
    }

    this._state = "STOPPING";

    // 末尾が切れないよう待機
    return new Promise<Float32Array | null>((resolve) => {
      setTimeout(() => {
        const recordedData = this.getAllRecordedData();
        if (this._updatingIntervalId !== null) {
          clearInterval(this._updatingIntervalId);
          this._updatingIntervalId = null;
        }

        this._audioWrapper?.stopRecording();

        this._state = "STOPPED";
        this.clearBuffer();
        resolve(recordedData);
      }, 650);
    });
  }

  async destroy(): Promise<void> {
    if (this._state === "DESTROYING" || this._state === "DESTROYED") return;
    if (this._state === "INITIALIZING" || this._state === "QUEUED_DESTROYING") {
      this._state = "QUEUED_DESTROYING";
      await this.waitUntilState("INITIALIZED");
    }
    if (this._state === "RECORDING") {
      if (this._updatingIntervalId !== null) {
        clearInterval(this._updatingIntervalId);
        this._updatingIntervalId = null;
      }
      await this._audioWrapper?.stopRecording();
    }
    this.clearBuffer();

    this._state = "DESTROYING";

    if (this._audioWrapper) {
      await this._audioWrapper.close();
      this._audioWrapper = null;
    }
    this._state = "DESTROYED";
  }
}
