import { encodeWav } from "./wav";
import SoxrModule, { SoxrType } from "../wasm/soxr_resample";
import soxrWasmUrl from "../wasm/soxr_resample.wasm?url";

let soxrWasmInstance: SoxrType | null = null;

export function convertInt16ToFloat32(audioData: Int16Array): Float32Array {
  const floatAudioData = new Float32Array(audioData.length);
  for (let i = 0; i < audioData.length; i++) {
    floatAudioData[i] = audioData[i] / 32768;
  }
  return floatAudioData;
}

export async function getSoxrWasm(): Promise<SoxrType> {
  if (soxrWasmInstance === null) {
    const soxr = await SoxrModule({
      locateFile: () => soxrWasmUrl,
    });
    soxrWasmInstance = soxr as SoxrType;
  }
  return soxrWasmInstance;
}

export async function getSoxrVersion(): Promise<string> {
  const soxrWasm = await getSoxrWasm();
  return soxrWasm.getVersion();
}

export async function resampleAudio(
  recordedData: Float32Array,
  inputRate: number,
  outputRate: number
): Promise<Int16Array> {
  const soxrWasm = await getSoxrWasm();

  const wavBase64 = (await encodeWav(recordedData, inputRate, true)) as string;
  const modifiedBase64 = await soxrWasm.resample(wavBase64, outputRate);
  const modifiedWavData = new Uint8Array(
    atob(modifiedBase64)
      .split("")
      .map((char) => char.charCodeAt(0))
  );

  const headerOffset = 44;
  const audioData = new Int16Array(modifiedWavData.buffer, headerOffset);

  return audioData;
}
