export const theme_dark = {
  common: {
    base: "#1E1E1E",
    calibration_description_bg: "#3333",
  },
  text: {
    main_text_lv1: "#888888", // 背景色の上の文字色、文字全般
    sub_text: "#F2F2F2", // チャートの軸数値、非アクティブなセレクトボックス背景色
  },
  result: {
    meter_needle: "#888888", // 解析結果の針
    meter_text_lv1: "#FFFFFF", // 解析結果メーターメータの文字色(1,2,8,9)
    meter_text_lv2: "#CCCCCC", // 解析結果メーターメータの文字色(3,4,5,6,7,8)
  },
};
