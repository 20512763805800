import { ReactElement } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Th,
  Tr,
} from "@chakra-ui/react";
import { ResultDiagramParam, ResultDiagramIndex } from "../../types";

export function ResultDiagram(props: {
  diagramParams: ResultDiagramParam[][];
  diagramIndex: ResultDiagramIndex[];
}): ReactElement {
  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <Table size="sm">
            <Thead>
              <Tr>
                {props.diagramIndex &&
                  props.diagramIndex.map((value, index) => (
                    <Th key={index} width={value.width} p={1}>
                      {value.title}
                    </Th>
                  ))}
              </Tr>
            </Thead>
          </Table>
        </AccordionButton>
        <AccordionPanel>
          <TableContainer whiteSpace="unset">
            <Table size="sm">
              <Tbody>
                {props.diagramParams &&
                  props.diagramParams.map((value, index) => (
                    <Tr key={index}>
                      {value &&
                        value.map((value2, index2) => (
                          <Td key={index2} width={value2.width} p={1}>
                            {value2.value}
                          </Td>
                        ))}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
