import { ReactElement } from "react";
import { Box, Flex, Grid, useBreakpointValue } from "@chakra-ui/react";
import { theme_list, useTheme as useThemeProvider } from "./ThemeProvider";
import { useAtom } from "jotai";
import { themeColorAtom } from "../../store";
import { themeType } from "../../types";
import { ReactSVG } from "react-svg";
import themeColorImage from "../../assets/images/base/theme_color.svg";
import { setSVGFillColor } from "../../utils/svgHelper";

interface ColorLayoutButtonProps {
  themeName: themeType;
}

function ColorLayoutButton({
  themeName,
}: ColorLayoutButtonProps): ReactElement {
  const [, setTheme] = useThemeProvider();
  const [currentThemeName, setThemeColorAtom] = useAtom(themeColorAtom);
  const handleThemeChange = (): void => {
    setTheme(themeName);
    setThemeColorAtom(themeName);
  };

  return (
    <Box
      as="button"
      onClick={() => handleThemeChange()}
      padding={4}
      borderRadius="lg"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <ReactSVG
        src={themeColorImage}
        beforeInjection={(svg) => {
          svg.setAttribute("width", "100%");
          setSVGFillColor(
            svg,
            ".theme_lv1",
            theme_list[themeName].colors.primary["theme_lv1"]
          );
          setSVGFillColor(
            svg,
            ".theme_lv2",
            theme_list[themeName].colors.primary["theme_lv2"]
          );
          setSVGFillColor(
            svg,
            ".theme_lv3",
            theme_list[themeName].colors.primary["theme_lv3"]
          );
          setSVGFillColor(
            svg,
            ".bg_lv1",
            theme_list[themeName].colors.primary["bg_lv1"]
          );
          setSVGFillColor(
            svg,
            ".accent",
            theme_list[themeName].colors.primary["accent"]
          );
          if (currentThemeName === themeName) {
            setSVGFillColor(svg, ".select", "red");
          }
        }}
      />
    </Box>
  );
}

export function ThemeColorSelect(): ReactElement {
  const gridWidth = useBreakpointValue({ base: "100%", md: "500px" });
  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Grid templateColumns="repeat(2, 1fr)" width={gridWidth}>
        <ColorLayoutButton themeName="sakura_light" />
        <ColorLayoutButton themeName="sakura_dark" />
        <ColorLayoutButton themeName="pink_light" />
        <ColorLayoutButton themeName="pink_dark" />
        <ColorLayoutButton themeName="yellow_orange_light" />
        <ColorLayoutButton themeName="yellow_orange_dark" />
        <ColorLayoutButton themeName="yellow_light" />
        <ColorLayoutButton themeName="yellow_dark" />
        <ColorLayoutButton themeName="yellow_green_light" />
        <ColorLayoutButton themeName="yellow_green_dark" />
        <ColorLayoutButton themeName="green_light" />
        <ColorLayoutButton themeName="green_dark" />
        <ColorLayoutButton themeName="blue_light" />
        <ColorLayoutButton themeName="blue_dark" />
        <ColorLayoutButton themeName="bluesky_light" />
        <ColorLayoutButton themeName="bluesky_dark" />
        <ColorLayoutButton themeName="blue_gray_light" />
        <ColorLayoutButton themeName="blue_gray_dark" />
        <ColorLayoutButton themeName="brown_light" />
        <ColorLayoutButton themeName="brown_dark" />
      </Grid>
    </Flex>
  );
}
