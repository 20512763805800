import { ReactElement, useEffect, useRef, useState } from "react";
import { encodeWav } from "../../utils/wav";
import { Button } from "@chakra-ui/react";
import { IoPlayCircleOutline } from "react-icons/io5";

function getAudioSegments(
  audioData: Int16Array,
  segmentList: number[][],
  sampleRate: number
): Int16Array[] {
  return segmentList.map(([start, end]) => {
    const startIndex = Math.floor((start * 10 * sampleRate) / 1000);
    const endIndex = Math.ceil((end * 10 * sampleRate) / 1000);
    return audioData.slice(startIndex, endIndex);
  });
}

// 指定区間の音声を切り出して合成
function concatenateSegments(
  segments: Int16Array[],
  sampleRate: number
): Int16Array {
  if (segments.length === 0) {
    return new Int16Array(0);
  }
  const silence = new Int16Array(sampleRate); // 1秒間の無音
  const totalLength =
    segments.reduce(
      (sum, segment) => sum + segment.length + silence.length,
      0
    ) - silence.length;

  const concatenated = new Int16Array(totalLength);

  let offset = 0;
  segments.forEach((segment, index) => {
    concatenated.set(segment, offset);
    offset += segment.length;
    if (index < segments.length - 1) {
      concatenated.set(silence, offset);
      offset += silence.length;
    }
  });

  return concatenated;
}

export function AudioPlayer({
  audioData,
  segmentList,
  sampleRate,
  mode = "full",
  onButtonClick,
  buttonLabel = "Play",
  isPlaying,
  onPlayStateChange,
  onTimeUpdate,
}: {
  audioData: Int16Array;
  segmentList: number[][];
  sampleRate: number;
  mode?: "full" | "segments";
  onButtonClick: () => void;
  buttonLabel?: string;
  isPlaying: boolean;
  onPlayStateChange: (playing: boolean) => void;
  onTimeUpdate: (time: number) => void;
}): ReactElement {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [, setIsPlaying] = useState(isPlaying);

  useEffect(() => {
    const generateAudioUrl = async (): Promise<void> => {
      let audioBlob;
      if (mode === "full") {
        audioBlob = encodeWav(audioData, sampleRate) as Blob;
      } else if (segmentList.length === 0) {
        // segmentListが空の場合、0秒の音声を生成
        const emptyAudio = new Int16Array(0);
        audioBlob = encodeWav(emptyAudio, sampleRate) as Blob;
      } else {
        const segments = getAudioSegments(audioData, segmentList, sampleRate);
        const concatenatedAudio = concatenateSegments(segments, sampleRate);
        audioBlob = encodeWav(concatenatedAudio, sampleRate) as Blob;
      }
      setAudioUrl(URL.createObjectURL(audioBlob));
    };
    generateAudioUrl();
  }, [audioData, segmentList, sampleRate, mode]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleTimeUpdate = (): void => {
        onTimeUpdate(audio.currentTime);
      };
      audio.addEventListener("timeupdate", handleTimeUpdate);
      return () => {
        audio.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [onTimeUpdate]);

  const handlePlay = (): void => {
    onButtonClick();
    setIsPlaying(true);
    onPlayStateChange(true);
    const audio = audioRef.current;
    if (audio) {
      audio.volume = 1.0; // 音量を最大に設定
      audio.play();
    }
  };

  return (
    <div>
      <Button
        leftIcon={<IoPlayCircleOutline size="25px" />}
        variant={isPlaying ? "btn_primary_non_active" : "btn_primary"}
        w="180px"
        minH="30px"
        onClick={handlePlay}
        isDisabled={isPlaying}
      >
        {buttonLabel}
      </Button>
      <audio
        ref={audioRef}
        src={audioUrl}
        onEnded={() => {
          setIsPlaying(false);
          onPlayStateChange(false);
        }}
      />
    </div>
  );
}
