import { ReactElement } from "react";
import { Box, Button, Image, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import NOT_FOUND_iMAGE_URL from "../assets/images/base/404notfound.png";
import { useTranslation } from "react-i18next";

export function NotFound(): ReactElement {
  const { i18n } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <VStack>
        <Image
          src={NOT_FOUND_iMAGE_URL}
          alt="not found"
          maxW="100%"
          maxH="100%"
        />
        <Button
          as={Link}
          to={`/${i18n.resolvedLanguage}/home`}
          variant="btn_primary"
          // px={8}
          width="60%"
          fontSize="22"
        >
          Home
        </Button>
      </VStack>
    </Box>
  );
}
