import { ReactElement, useEffect, useState, FC } from "react";
import { Tab, Tabs, TabList, useTheme, Box } from "@chakra-ui/react";
import { Layout } from "../components/atoms/Layout";
import { ThemeColorSelect } from "../components/molecules/ThemeColorSelect";
import { VisualizerSelect } from "../components/molecules/VisualizerSelect";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";

export function ColorLayout(): ReactElement {
  const t = useModifiedTranslation();

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  const [selectMode, setSelectMode] = useState("color");

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  type SelectTabProps = {
    label: string;
    onClick: () => void;
  };

  const SelectTab: FC<SelectTabProps> = ({ label, onClick }) => (
    <Tab
      fontWeight="bold"
      fontSize={14}
      color="common_text.gray"
      margin={1.5}
      borderRadius="full"
      _active={{ backgroundColor: "white" }}
      sx={{
        "@media (hover: hover)": {
          "&:hover": { backgroundColor: "white" },
        },
      }}
      _selected={{
        color: "common_text.dark",
        bgColor: "white",
        margin: 1.5,
        borderRadius: "full",
      }}
      onClick={onClick}
    >
      {label}
    </Tab>
  );

  return (
    <Layout height="100%" display="flex" flexDirection="column">
      <Box>
        <Layout.Title showBackButton fontSize="24px">
          {t("Setting.itemTitleColorLayout")}
        </Layout.Title>
        <Tabs
          variant="unstyled"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <TabList
            bgColor="login.login_button_bg"
            borderRadius="full"
            justifyContent="center"
          >
            <SelectTab
              label={t("SelectColorLayout.color")}
              onClick={() => setSelectMode("color")}
            />
            <SelectTab
              label={t("SelectColorLayout.layout")}
              onClick={() => setSelectMode("layout")}
            />
          </TabList>
        </Tabs>
      </Box>
      <Box overflowY="auto" mt={4} paddingRight={1}>
        {selectMode === "color" && <ThemeColorSelect />}
        {selectMode === "layout" && <VisualizerSelect />}
      </Box>
    </Layout>
  );
}
