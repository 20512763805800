import { Button, Box, Text, Icon } from "@chakra-ui/react";
import { ReactElement } from "react";
import { IoMic, IoStop } from "react-icons/io5";
import { RecordingState } from "../../types";
import { useModifiedTranslation } from "../../hooks/useModifiedTranslation";

export function RecordingButton(props: {
  recordingState: RecordingState;
  isRerecord?: boolean;
  onClick: () => void;
}): ReactElement {
  const t = useModifiedTranslation();
  const height = "80px";

  switch (props.recordingState) {
    case "INITIALIZING":
    case "PREPARING":
    case "IDLE":
      return (
        <Button
          variant="btn_primary"
          width="80%"
          minW="250px"
          height={height}
          margin="0 auto"
          isLoading={props.recordingState !== "IDLE"}
          onClick={props.onClick}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            position="relative"
          >
            <Icon
              as={IoMic}
              boxSize={8}
              position="absolute"
              left="50%"
              transform="translateX(-110px)"
            />
            <Text fontSize="xl">
              {!props.isRerecord
                ? t("Recording.startRecording")
                : t("Recording.startRerecording")}
            </Text>
          </Box>
        </Button>
      );
    case "STOPPING":
    case "RECORDING":
      return (
        <Button
          variant="btn_primary"
          width="80%"
          minW="250px"
          height={height}
          margin="0 auto"
          isLoading={props.recordingState === "STOPPING"}
          onClick={props.onClick}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            position="relative"
          >
            <Icon
              as={IoStop}
              boxSize={8}
              position="absolute"
              left="50%"
              transform="translateX(-110px)"
            />
            <Text fontSize="xl">
              {!props.isRerecord
                ? t("Recording.stopRecording")
                : t("Recording.stopRerecording")}
            </Text>
          </Box>
        </Button>
      );
    case "COMPLETED":
      return <Box height={height} />;
  }
}
