import { useState, useEffect, ReactElement, useRef } from "react";
import axios from "axios";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useModifiedTranslation } from "../../hooks/useModifiedTranslation";

export const VersionCheckModal = (): ReactElement => {
  const t = useModifiedTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dialogCancelRef = useRef(null);
  const currentBuildId = __BUILD_ID__;

  const checkBuildId = async (): Promise<void> => {
    try {
      // キャッシュを無視してbuild_id.jsonを取得
      const buildIdResponse = await axios.get("/build_id.json", {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      const newBuildId = buildIdResponse.data["buildId"];
      if (currentBuildId && currentBuildId !== newBuildId) {
        setIsOpenDialog(true);
      }
    } catch (error) {
      console.error("Version Check Error", error);
    }
  };

  useEffect(() => {
    checkBuildId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = (): void => {
    window.location.reload();
  };

  return (
    <AlertDialog
      isCentered
      isOpen={isOpenDialog}
      leastDestructiveRef={dialogCancelRef}
      onClose={() => setIsOpenDialog(false)}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent backgroundColor="common.base">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("VersionUpDialog.Header")}
          </AlertDialogHeader>
          <AlertDialogBody my={5} fontSize="lg" fontWeight="normal">
            {t("VersionUpDialog.Body")}
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="center">
            <Button variant="btn_primary" onClick={handleUpdate} width="250px">
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
