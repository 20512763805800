import { theme_dark } from "./common_dark";

export const theme_bluesky_dark = {
  ...theme_dark,
  primary: {
    theme_lv1: "#00AAE7", // テーマカラー1
    theme_lv2: "#86CCF1", // テーマカラー2
    theme_lv3: "#DCEFFB", // テーマカラー3
    accent: "#0068B7", // アクセントカラー（ボリュームゲージの最大、ボリューム閾値）
    bg_lv1: "#23282F", // 背景色1
  },
};
