import { RecordedData } from "../types";
import dayjs from "dayjs";
import { encodeWav } from "./wav";
import { APP_NAME } from "../environments";
import { EmployeeInfoType } from "../store";

export function generateVoiceFileData(
  allRecordedData: RecordedData[],
  recType: number,
  employeeInfo: EmployeeInfoType
): FormData {
  const formData = new FormData();
  const now = dayjs().format("YYYY.MM.DD.HH.mm.ss");
  for (
    let phraseIndex = 0;
    phraseIndex < allRecordedData.length;
    ++phraseIndex
  ) {
    const recorded = allRecordedData[phraseIndex];
    if (!recorded.isSkipped) {
      const wav = encodeWav(recorded.data, recorded.samplingRate) as Blob;
      const phrase = phraseIndex.toString().padStart(2, "0");
      formData.append("wav_files", wav, `${phrase}_${now}.wav`);
      if (recorded.co1VoiceType) {
        formData.append("cog_voice_types", recorded.co1VoiceType);
      }
      if (recorded.dysphagiaVoiceTypes) {
        formData.append("dysphagia_voice_types", recorded.dysphagiaVoiceTypes);
      }
      if (recorded.phrase) {
        formData.append("phrases", recorded.phrase);
      }
    }
  }

  // 契約者アカウントによるクエリログインを考慮し、クエリストリングに「一般ユーザーID」（employee_id）を付与
  formData.append("employee_id", employeeInfo.employee_id);

  // 録音種別の選択がある場合は引数に「rec_type」を追加
  if (recType > 0) {
    formData.append("rec_type", recType.toString());
  }

  formData.append(
    "app_version",
    `${APP_NAME.replaceAll(" ", "_")}-${__GIT_DESCRIBE_REVISION__}`
  );
  return formData;
}
