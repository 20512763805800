import { useAtom } from "jotai";
import {
  CompanySettingDataAtom,
  employeeContractAtom,
  EmployeeContractType,
  employeeInfoAtom,
  EmployeeInfoType,
  SplashImageInfoType,
  splashImageInfoInitial,
  splashImageInfoAtom,
  loginImageInfoAtom,
  minimumUtterancesFromApiAtom,
  phrasesFromApiAtom,
  RawPhraseInfo,
  LoginImageInfoType,
  LanguageLabelsType,
  LanguageLabelsAtom,
} from "../store";
import { useCallback, useRef } from "react";
import { appAxios } from "./appAxios";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";

type SettingInfo = (employeeId: string) => void;

/*
 * アプリ設定一括取得（/analysis/app/settings）と状態保持 共通処理
 */
export function useSetSetting(): SettingInfo {
  const setting = useRef(0);
  const [, setEmployeeInfo] = useAtom(employeeInfoAtom);
  const [, setEmployeeContract] = useAtom(employeeContractAtom);
  const [, setSplashImageInfo] = useAtom(splashImageInfoAtom);
  // ログイン画面に表示する画像のURLをlocalStorageに保持管理用
  const [, setLoginImageInfo] = useAtom(loginImageInfoAtom);
  const [, setLanguageLabels] = useAtom(LanguageLabelsAtom);

  const t = useModifiedTranslation();
  // phrases（読み上げ文配列）のグローバル状態管理用
  const [, setPhrasesFromApi] = useAtom(phrasesFromApiAtom);
  // minimum_utterances（元気圧の計算に必要な最少発話数）のグローバル状態管理用
  const [, setMinimumUtterancesFromApi] = useAtom(minimumUtterancesFromApiAtom);
  // mimosys_company_settings（契約者のMIMOSYS設定）のグローバル状態管理用
  const [, setCompanySettingData] = useAtom(CompanySettingDataAtom);

  return useCallback(
    async (employeeId) => {
      if (setting.current === 0) {
        setting.current++;
        // console.log("in useSetSetting");
        // Splash画像パスの初期化
        setSplashImageInfo(splashImageInfoInitial);

        // アプリ設定一括取得APIにて取得
        try {
          const settingResponse = await appAxios.get("/analysis/app/settings", {
            params: { employee_id: employeeId },
          });

          // console.log("login settingResponse.data:" + JSON.stringify(settingResponse.data,null,'\t'));

          const employeeInfo: EmployeeInfoType = {
            employee_id: settingResponse.data.employee_profile.employee_id,
            company_id: settingResponse.data.employee_profile.company_id,
            employee_name: settingResponse.data.employee_profile.employee_name,
            password_flag: settingResponse.data.employee_profile.password_flag,
            delete_flag: settingResponse.data.employee_profile.delete_flag,
            locked: settingResponse.data.employee_profile.locked,
            // アプリ設定一括取得APIでは、下記に設定するものが見つけられない。
            // nickname: "",
            // level: 0,
            // icon_color: "ffffff",
            // id: "",
          };

          const employeeContract: EmployeeContractType = {
            privacy_policy: settingResponse.data.privacy_policy,
            terms_of_service: settingResponse.data.terms_of_service,
          };

          const splashImageInfo: SplashImageInfoType = {
            splash_path: settingResponse.data.logo.splash_path,
            splash_no: settingResponse.data.logo.splash_no ?? null,
          };

          const loginImageInfo: LoginImageInfoType = {
            login_path: settingResponse.data.logo.login_path,
            login_no: settingResponse.data.logo.login_no ?? null,
          };

          const languageLabels: LanguageLabelsType =
            settingResponse.data.app_labels;

          setEmployeeInfo(employeeInfo);
          setEmployeeContract(employeeContract);
          setSplashImageInfo(splashImageInfo);

          // ログイン画面に表示する画像のURLをlocalStorageに保持
          setLoginImageInfo(JSON.stringify(loginImageInfo));
          setLanguageLabels(languageLabels);

          // minimum_utterances（元気圧の計算に必要な最少発話数）をグローバル状態管理に保持
          setMinimumUtterancesFromApi(
            settingResponse.data.mimosys_company_settings.minimum_utterances
          );

          /*
           * phrases（読み上げ文配列）をグローバル状態管理に保持、フォーマットはJson管理の型と合わせる
           */
          const phraseFromApiAllData: RawPhraseInfo[] = [];
          const phraseFromApiLength = settingResponse.data.phrases.length;

          settingResponse.data.phrases.map((phrase: string, index: number) => {
            phraseFromApiAllData.push({
              titleKey:
                t("PJ.FixedPhrase.Title") +
                " " +
                (index + 1) +
                "/" +
                phraseFromApiLength,
              phraseKey: phrase,
              helperTextKey: t("PJ.FixedPhrase.HelperText"),
            });
          });
          setPhrasesFromApi(phraseFromApiAllData);

          // mimosys_company_settings（契約者のMIMOSYS設定）のグローバル状態管理に保持
          setCompanySettingData(settingResponse.data.mimosys_company_settings);
          // return true;
        } catch {
          console.log("not get settings.");
          // return false;
        }
      }
    },
    [
      setEmployeeInfo,
      setEmployeeContract,
      setSplashImageInfo,
      setLoginImageInfo,
      setLanguageLabels,
      setPhrasesFromApi,
      setMinimumUtterancesFromApi,
      setCompanySettingData,
      t,
    ]
  );
}
