import { forwardRef } from "react";
import { PhraseText, SoundVisualizerRef } from "../../types";
import { Box, Center, Text, useTheme } from "@chakra-ui/react";
import { BarSoundVisualizerV2 } from "../atoms/BarSoundVisualizerV2";
import { CALIBRATION_MIN_LOG_POWER } from "../../constants";
import { decibelToVolume } from "../../utils/calcVolume";

type PhraseVisualizerProps = {
  currentPhrase: PhraseText;
  showThreshold?: boolean;
  isRecording?: boolean;
};
export const PhraseBarSoundVisualizer = forwardRef<
  SoundVisualizerRef,
  PhraseVisualizerProps
>((props, ref) => {
  const theme = useTheme();
  const underThreshold = decibelToVolume(CALIBRATION_MIN_LOG_POWER);
  return (
    <Box
      position="relative"
      minHeight={{
        base: "320px",
        sm: "490px",
      }}
      width="100%"
      mt={5}
    >
      <BarSoundVisualizerV2
        ref={ref}
        upperThreshold={0.81}
        underThreshold={underThreshold}
        maxValue={1}
        height="40px"
        width="100%"
        showThreshold={props.showThreshold}
        isRecording={props.isRecording}
      />
      <Center
        position="absolute"
        top={0}
        w="full"
        h="full"
        flexDirection="column"
        gap={2}
        mt={2}
      >
        <Text
          maxWidth="100%"
          fontSize={{
            base: "2xl",
            sm: "4xl",
          }}
          textAlign="center"
          whiteSpace="pre-wrap"
          color="primary.theme_lv1"
          fontWeight={900}
          lineHeight={1.2}
          mt={5}
          width="100%"
          height={{
            base: "200px",
            sm: "350px",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderWidth="2px"
          borderStyle="solid"
          borderColor={theme.colors.primary["theme_lv1"]}
          bg={theme.colors.common["base"]}
        >
          {props.currentPhrase.phrase}
        </Text>
        <Text
          maxWidth="300px"
          textAlign="center"
          whiteSpace="pre-wrap"
          fontSize={{
            base: "xl",
            sm: "3xl",
          }}
          mt={5}
        >
          {props.currentPhrase.helperText || ""}
        </Text>
      </Center>
    </Box>
  );
});
PhraseBarSoundVisualizer.displayName = "PhraseVisualizer";
