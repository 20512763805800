import { atom } from "jotai";

/**
 * グローバルで使う状態
 * アプリ設定
 */

export type AppSettingType = {
  sd_chart: number;
  reminder_interval: string;
  reminder_interval_flag: boolean;
  daily_reminder_time: string;
  daily_reminder_time_flag: boolean;
};

export const AppSettingInitial: AppSettingType = {
  sd_chart: 1.5,
  reminder_interval: "0",
  daily_reminder_time: "00:00",
  reminder_interval_flag: false,
  daily_reminder_time_flag: false,
};

export const AppSettingData = atom<AppSettingType>(AppSettingInitial);
