import { ReactElement, useCallback, useEffect } from "react";
import { Box, Button, Flex, useTheme } from "@chakra-ui/react";
import { Layout } from "../atoms/Layout";

import { PasswordInput } from "../atoms/AppInputs";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { employeeInfoAtom, passwordChangeStatusAtom } from "../../store";

import { useNavigate } from "react-router-dom";
import { appAxios } from "../../utils/appAxios";
import { useToastMessage } from "../../hooks/useToastMessage";

import { ChangeSelectionUserPassword } from "../../utils/db";
import { useModifiedTranslation } from "../../hooks/useModifiedTranslation";

const passwordErrorAtom = atomWithReset(false);
const showDialogAtom = atomWithReset(false);
const contentAtom = atomWithReset("");
const sessionErrorAtom = atomWithReset(false);

export const changePasswordPoint = "/account/password";
type passwordInfo = (password: string, employeeId: string) => void;

function useSetPasswordChange(): passwordInfo {
  const resetContent = useResetAtom(contentAtom);
  const resetSessionError = useResetAtom(sessionErrorAtom);
  const resetPasswordError = useResetAtom(passwordErrorAtom);
  const [showDialog] = useAtom(showDialogAtom);
  const navigate = useNavigate();
  const [, setPasswordChangeStatus] = useAtom(passwordChangeStatusAtom);
  const toastMessage = useToastMessage();
  const { i18n } = useTranslation();
  const [employeeInfo, setEmployeeInfo] = useAtom(employeeInfoAtom);

  return useCallback(
    async (password, employeeId) => {
      try {
        /*
         * 初回ログイン時のパスワード変更 パスワード変更(/account/password)
         */
        await appAxios.put(changePasswordPoint, {
          user_id: employeeId,
          new_password: password,
        });
        setPasswordChangeStatus(true);
        resetPasswordError();
        resetSessionError();
        resetContent();
        if (!showDialog) {
          if (!employeeInfo.password_flag) {
            employeeInfo.password_flag = true;
            setEmployeeInfo(employeeInfo);
            // 初回ログイン時はホームへ遷移
            navigate("../home", { replace: true });
          } else {
            // 初回ログインでない場合は設定画面へ遷移
            ChangeSelectionUserPassword(employeeId, password);
            navigate(`/${i18n.resolvedLanguage}/` + "setting");
          }
        }
      } catch (e) {
        console.error("Login Error!", e);
        toastMessage(e);
      }
    },
    [
      navigate,
      resetContent,
      resetPasswordError,
      resetSessionError,
      setPasswordChangeStatus,
      showDialog,
      toastMessage,
      i18n,
      employeeInfo,
      setEmployeeInfo,
    ]
  );
}

type PasswordFormData = {
  password: string;
  conformPassword: string;
};

export function PasswordChange(): ReactElement {
  const [, setPasswordError] = useAtom(passwordErrorAtom);
  const [content, setContent] = useAtom(contentAtom);
  const [employeeInfo] = useAtom(employeeInfoAtom);
  const setPasswordChange = useSetPasswordChange();

  // 初回ログイン時のパスワード変更画面ではブラウザバック禁止
  useEffect(() => {
    if (!employeeInfo.password_flag) {
      window.history.pushState(null, "", window.location.href);
    }
  }, [employeeInfo]);

  useEffect(() => {
    setContent("");
  }, [setContent]);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<PasswordFormData>();
  const t = useModifiedTranslation();

  const onSubmit: SubmitHandler<PasswordFormData> = async (data) => {
    if (data.password.length < 4 || data.conformPassword.length < 4) {
      // バリデーション（パスワードが3文字以下）
      setPasswordError(true);
      const error01 = t("PasswordReset.NewPassword.error01");
      setContent(error01);
    } else if (data.password === data.conformPassword) {
      // バリデーション通過、パスワード変更の実施
      setPasswordChange(data.password, employeeInfo.employee_id);
    } else {
      // バリデーション（パスワードが再入力値と不一致）
      setPasswordError(true);
      const error02 = t("PasswordReset.NewPassword.error02");
      setContent(error02);
    }
  };

  return (
    <>
      <Layout>
        <Box
          sx={{
            ".input_field01": {
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* 戻るボタン(初回ログイン時は使用不可) */}
          <Layout.Title
            showBackButton={employeeInfo.password_flag}
            fontSize="24px"
          >
            {t("PasswordReset.NewPassword.title")}
          </Layout.Title>
          {content !== "" && (
            <Box color="primary.theme_lv1" marginLeft={10} marginBottom={2}>
              {content}
            </Box>
          )}
          <Flex className="input_field01" p={{ base: 5, md: 10 }}>
            <PasswordInput
              input={{
                ...register("password", { required: true }),
                placeholder: t("PasswordReset.NewPassword.input"),
              }}
            />
          </Flex>
          <Flex p={{ base: 5, md: 10 }}>
            <PasswordInput
              input={{
                ...register("conformPassword", { required: true }),
                placeholder: t("PasswordReset.NewPassword.confirm"),
              }}
            />
          </Flex>
          <Button
            type="submit"
            variant="btn_primary"
            w="full"
            size="lg"
            mb={10}
            isLoading={isSubmitting}
          >
            {t("PasswordChange.change")}
          </Button>
        </Box>
      </Layout>
    </>
  );
}
