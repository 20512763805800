export function encodeWav(
  samples: Float32Array | Int16Array,
  sampleRate: number,
  returnBase64 = false
): Blob | string {
  const buffer = new ArrayBuffer(44 + samples.length * 2);
  const view = new DataView(buffer);

  const writeString = function (
    view: DataView,
    offset: number,
    string: string
  ): void {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const floatTo16BitPCM = function (
    output: DataView,
    offset: number,
    input: Float32Array | Int16Array
  ): void {
    for (let i = 0; i < input.length; i++, offset += 2) {
      if (input instanceof Float32Array) {
        const s = Math.max(-1, Math.min(1, input[i]));
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
      } else {
        output.setInt16(offset, input[i], true);
      }
    }
  };

  writeString(view, 0, "RIFF"); // RIFFヘッダ
  view.setUint32(4, 36 + samples.length * 2, true); // これ以降のファイルサイズ
  writeString(view, 8, "WAVE"); // WAVEヘッダ
  writeString(view, 12, "fmt "); // fmtチャンク
  view.setUint32(16, 16, true); // fmtチャンクのバイト数
  view.setUint16(20, 1, true); // フォーマットID
  view.setUint16(22, 1, true); // チャンネル数
  view.setUint32(24, sampleRate, true); // サンプリングレート
  view.setUint32(28, sampleRate * 2, true); // データ速度
  view.setUint16(32, 2, true); // ブロックサイズ
  view.setUint16(34, 16, true); // サンプルあたりのビット数
  writeString(view, 36, "data"); // dataチャンク
  view.setUint32(40, samples.length * 2, true); // 波形データのバイト数
  floatTo16BitPCM(view, 44, samples); // 波形データ

  if (!returnBase64) {
    return new Blob([view], { type: "audio/wav" });
  } else {
    const uint8Array = new Uint8Array(buffer);
    let binaryString = "";
    for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i]);
    }
    const base64String = btoa(binaryString);
    return base64String;
  }
}
